import { KOSTabs } from "@foodology-co/alejandria";
import CountertopsOutlinedIcon from "@mui/icons-material/CountertopsOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { Box } from "@mui/material";
import SearchOnTopBar from "app/components/common/SearchOnTopBar";
import PageBox from "app/components/PageBox";
import PageHeader from "app/components/PageHeader/PageHeader";
import PurchaseTable from "app/components/PurchaseRecord";
import { ReceiptReceivedOrders } from "app/components/Receipts/ReceivedOrders";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import {
  commons,
  kitchenInventoryManagement,
  receiptType,
} from "app/i18n/types";
import { getPurchaseForKitchen } from "app/store/slices/purchase/purchase.slice";
import { AmplitudeEvent, logEvent } from "core/common/utils/analytics";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface PurchaseOrderReceiptProps {}

const PurchaseOrderReceipt: FunctionComponent<
  PurchaseOrderReceiptProps
> = () => {
  const navigator = useNavigator();
  const { kitchenId } = navigator.params();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const orders = useAppSelector((state) => state.purchase.orders);
  const status = useAppSelector((state) => state.purchase.status);
  const [search, setSearch] = useState<string>("");
  const [tab, setTab] = useState<number>(-1);

  const getReceipts = () => {
    if (kitchenId) {
      dispatch(getPurchaseForKitchen(kitchenId));
    }
  };

  useEffect(() => {
    getReceipts();
  }, [dispatch, kitchenId]);

  return (
    <PageBox>
      <SearchOnTopBar onSearch={setSearch} />
      <PageHeader
        title={t(kitchenInventoryManagement.RECEPTION_INVENTORY_TITLE)}
        subtitle={t(kitchenInventoryManagement.RECEPTION_INVENTORY_DESCRIPTION)}
        rightArea={[
          {
            children: t(commons.UPDATE),
            variant: "outlined",
            startIcon: <RestartAltOutlinedIcon />,
            onClick: getReceipts,
            show: tab !== 1,
          },
        ]}
      />

      <Box sx={{ my: 2 }}>
        <KOSTabs
          tabs={[
            {
              children: {
                text: t(receiptType.PENDING),
                startIcon: <CountertopsOutlinedIcon />,
              },
            },
            {
              children: {
                text: t(receiptType.RECEIVED),
                startIcon: <ListAltOutlinedIcon />,
              },
            },
          ]}
          onChange={setTab}
        />
      </Box>
      <Box>
        {tab === 0 && (
          <PurchaseTable
            items={orders}
            filter={search}
            onClick={(_, order) => {
              logEvent(AmplitudeEvent.OpenReceiptOrder, {
                kitchenId: kitchenId,
                receiptNo: order.receiptNo,
                purchaseOrderId: order.sourceId,
              });
              const url = `/receipt-order/${kitchenId}/${
                order.type === "TRANSFER_ORDER" && order.origin === "KIS"
                  ? "transfer"
                  : "purchase"
              }/${order.receiptNo}/${order.origin}`;
              navigator.toByLocationType(url);
            }}
            loading={status === "loading"}
          />
        )}
        {tab === 1 && kitchenId && (
          <ReceiptReceivedOrders kitchenId={kitchenId} filter={search} />
        )}
      </Box>
    </PageBox>
  );
};

export default PurchaseOrderReceipt;
