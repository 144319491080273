import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import KitchenSelector from "app/components/Kitchen/Selector";
import PageBox from "app/components/PageBox";
import PageHeader from "app/components/PageHeader";
import TransferOrdersTable from "app/components/TransferOrders/TransferOrdersTable";
import SearchOnTopBar from "app/components/common/SearchOnTopBar";
import { transferModule, transferOrderModule } from "app/i18n/types";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataFilterTransferOrder } from "utils/general";

interface Props {}

const TransferOrderPage: FunctionComponent<Props> = () => {
  const { t } = useTranslation();

  const [search, setSearch] = useState<string>("");
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [selectedCity, setSelectedCity] = useState<string>("");
  const [selectedKitchen, setSelectedKitchen] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedStatus, setSelectedStatus] = useState<DataFilterTransferOrder>(
    DataFilterTransferOrder.ALL
  );

  const handleStatusInputChange = (_: unknown, value: any) => {
    setSelectedStatus(value);
  };

  useEffect(() => {
    setSearch("");
    setSelectedStatus(DataFilterTransferOrder.ALL);
  }, [selectedKitchen]);

  return (
    <PageBox>
      <PageHeader
        title={t(transferOrderModule.TITLE)}
        subtitle={t(transferOrderModule.DESCRIPTION)}
      />

      <Paper sx={{ p: 2, width: "100%", mb: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {t(transferOrderModule.FILTER_ORDERS)}
        </Typography>
        <Grid container spacing={2}>
          <Grid item sm={9}>
            <KitchenSelector
              selected={{
                country: selectedCountry,
                city: selectedCity,
                kitchen: selectedKitchen,
              }}
              onChange={{
                country: (value) => setSelectedCountry(value?.code ?? ""),
                city: (value) => setSelectedCity(value?.code ?? ""),
                kitchen: (value) => setSelectedKitchen(value?.kitchenId ?? ""),
              }}
              extra={{ showHash: true, disabled: loading }}
            />
          </Grid>
          <Grid item sm={3}>
            <Autocomplete
              defaultValue={DataFilterTransferOrder.ALL}
              value={selectedStatus}
              options={[
                DataFilterTransferOrder.ALL,
                DataFilterTransferOrder.IN_PROCESS,
                DataFilterTransferOrder.PACKED,
                DataFilterTransferOrder.IN_TRANSIT,
                DataFilterTransferOrder.RECEIVED,
                DataFilterTransferOrder.PARTIAL_RECEIVED,
                DataFilterTransferOrder.RETURNED,
                DataFilterTransferOrder.RELEASED,
              ]}
              onChange={handleStatusInputChange}
              getOptionLabel={(option) => t(`status.${option}`)}
              renderInput={(params) => (
                <TextField {...params} label={t(transferModule.STATUS)} />
              )}
            />
          </Grid>
        </Grid>
      </Paper>

      <SearchOnTopBar onSearch={setSearch} clean={search} />

      <TransferOrdersTable
        kitchenId={selectedKitchen}
        search={search}
        status={selectedStatus}
        onLoading={setLoading}
      />
    </PageBox>
  );
};

export default TransferOrderPage;
