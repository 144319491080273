import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ClearIcon from "@mui/icons-material/Clear";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CommonDialog from "app/components/common/CommonDialog";
import ListHeader from "app/components/ListHeader";
import PageBox from "app/components/PageBox";
import PageHeader from "app/components/PageHeader/PageHeader";
import PhysicalCountFilter from "app/components/PhysicalCount/Filter";
import PhysicalCountGroupTable, {
  GroupTableRecordSkeleton,
} from "app/components/PhysicalCount/GroupTable";
import BarCodeScanner, {
  OnActionResponse,
} from "app/components/Scanner/BarCode";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import {
  commons,
  physicalCountModule,
  productionScannedError,
} from "app/i18n/types";
import { clearState, setSearch } from "app/store/slices/counting";
import {
  addItemCount,
  addScannedItemCount,
  finishCounting,
  finishItemCounting,
  getProductsForKitchen,
  setProductCount,
} from "app/store/slices/counting/thunks";
import { getCountingHistory } from "app/store/slices/countingHistory/thunks";
import appConfig from "config/app";
import { User } from "core/account/entities/User";
import { UserHTTPRepository } from "core/account/repositories/http/user";
import { AmplitudeEvent, logEvent } from "core/common/utils/analytics";
import { Group } from "core/physicalCount/entities/Group";
import { Product } from "core/physicalCount/entities/Product";
import { ProductCountResultDTO } from "core/physicalCount/interfaces/counting/canSetProductCount";
import { ProductionScannedtResponse } from "core/productions/entities/Productions";
import React, {
  FocusEvent,
  FunctionComponent,
  useCallback,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

interface InventoryManagerProps {}

const InventoryManager: FunctionComponent<InventoryManagerProps> = () => {
  const navigator = useNavigator();
  const { kitchenId, countingId } = navigator.params();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const groups = useAppSelector((state) => state.counting.groups);
  const status = useAppSelector((state) => state.counting.status);
  const countingStatus = useAppSelector(
    (state) => state.counting.countingStatus
  );
  const groupFilter = useAppSelector((state) => state.counting.filters.group);
  const searchFilter = useAppSelector((state) => state.counting.filters.search);

  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const user = useAppSelector((state) => state.session.user.data);
  const httpRepository = new UserHTTPRepository(appConfig);

  const [open, setOpen] = useState<Array<string>>(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("openGroups")?.split(",") || [];
  });
  const [openFinishedDialog, setOpenFinishedDialog] = useState<boolean>(false);
  const [openSumModalSku, setOpenSumModalSku] = useState<string | null>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [item, setItem] = useState<Product>();
  const [originalQuantity, setOriginalQuantity] = useState<number>();
  const [latestEditor, setLatestEditor] = useState<User>();
  const [filterIdle, setFilterIdle] = React.useState<boolean>(false);

  const handleIdleFilterChange = useCallback((isIdleFiltered: boolean) => {
    setFilterIdle(isIdleFiltered);
  }, []);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const openGroups = searchParams.get("openGroups")?.split(",") || [];
    setOpen(openGroups);
  }, []);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (open.length > 0) {
      searchParams.set("openGroups", open.join(","));
    } else {
      searchParams.delete("openGroups");
    }
    window.history.replaceState({}, "", `?${searchParams.toString()}`);
  }, [open]);

  const toggleOpen = useCallback((id: string) => {
    setOpen((prevOpen) =>
      prevOpen.includes(id)
        ? prevOpen.filter((el) => el !== id)
        : [...prevOpen, id]
    );
  }, []);

  const onChange = async (
    event: FocusEvent<HTMLInputElement>,
    product: Product
  ) => {
    if (event.target.value !== "" && event.target.value !== undefined) {
      const value: number = Number(event.target.value);
      if (value >= 0 && countingStatus && product.quantity !== value) {
        const updatedProduct = { ...product, quantity: value };
        setItem(updatedProduct);
        updateProduct(false, updatedProduct);
      }
    }
  };

  const updateProduct = (overwrite: boolean, product: Product) => {
    dispatch(
      setProductCount({
        countingId: Number(countingId),
        sku: product.SKU,
        unit: product.unitShortName,
        quantity: product.quantity,
        comment: "",
        overwrite: overwrite,
      })
    ).then(async (result) => {
      try {
        const productResponse = result.payload as ProductCountResultDTO;
        setOriginalQuantity(productResponse.quantity);
        if (productResponse?.userId && productResponse?.userId !== user?._id) {
          const fetchedUser = await httpRepository.getUserById(
            productResponse.userId
          );
          setLatestEditor(fetchedUser);
          setOpenDialog(true);
          setItem(product);
        }
      } catch (error) {
        console.error("Error fetching user or setting editor:", error);
      }
    });
  };

  const finishCount = async () => {
    if (countingId) {
      dispatch(finishCounting(countingId));
    }
  };

  const onCountIncrement = (item: Product, increment: number) => {
    if (countingId) {
      dispatch(
        addItemCount({
          countingId: countingId,
          sku: item.SKU,
          unit: item.unitShortName,
          quantity: increment,
          comments: "",
          status: item.status,
        })
      );
    }
  };

  React.useEffect(() => {
    if (kitchenId && countingId) {
      dispatch(getProductsForKitchen({ kitchenId, countingId }));
    }
  }, [dispatch, kitchenId, countingId]);

  React.useEffect(() => {
    if (countingStatus === "FINISHED") {
      setOpenFinishedDialog(true);
    }
  }, [countingStatus]);

  const goToHistory = () => {
    dispatch(clearState());
    if (kitchenId) {
      dispatch(getCountingHistory({ kitchenId }));
    }

    navigator.toByLocationType(`/physical-count/${kitchenId}`);
  };

  const onFinishItemCounting = (sku: string) => {
    dispatch(finishItemCounting({ countingId: Number(countingId), sku }));
  };

  const getSearchResult = useCallback((): Array<Product> => {
    if (searchFilter !== null) {
      const searchResult =
        groups?.reduce<Array<Product>>(
          (acc, group) => acc.concat(group.groupItems),
          []
        ) ?? [];
      return searchResult;
    }

    return [];
  }, [groups, searchFilter]);

  const openGroupByItemSku = (response: ProductionScannedtResponse): string => {
    const { sku, quantity } = response;
    let product: Product | undefined;
    const group = groups?.find((group) => {
      product = group.groupItems.find((groupItem) => groupItem.SKU === sku);
      return !!product;
    });

    if (group && !open.includes(group.groupName)) {
      toggleOpen(group.groupName);
    }

    if (product) {
      const { name, unitName } = product;
      return `${t(
        commons.SCANNED
      )} ${name} ${quantity} ${unitName.toLowerCase()}`;
    }
    return "";
  };

  const onActionScan = async (code: string): Promise<OnActionResponse> => {
    setOpenSumModalSku(null);

    if (!countingId) {
      return {
        success: false,
        message: productionScannedError.SELECT_THE_COUNTING,
      };
    }

    const response = await dispatch(
      addScannedItemCount({
        countingId: countingId,
        productionLabelId: code,
      })
    );

    const res = response.payload as ProductionScannedtResponse;

    if (!res.ok) {
      return { success: false, message: res.error };
    }

    const message = openGroupByItemSku(res);
    setOpenSumModalSku(res.sku);

    return { success: true, message };
  };

  return (
    <PageBox>
      <CommonDialog
        open={openDialog}
        title={t(physicalCountModule.COUNTING_CONFLICT)}
        message={t(physicalCountModule.PRODUCT_EDIT_WARNING, {
          firstName: latestEditor?.profile.firstName,
          lastName: latestEditor?.profile.lastName,
          quantity: originalQuantity,
          SKU: item?.SKU,
          name: item?.name,
        })}
        icon={<InfoOutlinedIcon color="warning" />}
        confirmButtonLabel={t(physicalCountModule.REPLACE_VALUE)}
        confirmButtonColor="warning"
        handleConfirm={() => {
          if (item) {
            updateProduct(true, item);
            setOpenDialog(false);
          } else {
            console.error("Item is undefined!");
          }
        }}
        handleClose={() => setOpenDialog(false)}
        extraButton={{
          label: t(physicalCountModule.RELOAD_PAGE),
          onClick: () => window.location.reload(),
          disabled: false,
        }}
      />

      <PageHeader
        title={t(physicalCountModule.TITLE)}
        subtitle={t(physicalCountModule.SUBTITLE)}
        rightArea={[
          {
            onClick: () => setOpenConfirmDialog(true),
            children: t(
              countingStatus === "FINISHED" ? commons.FINISHED : commons.FINISH
            ),
            disabled:
              status === "loading" ||
              groups?.some((group: Group) =>
                group.groupItems.some((product) => product.quantity === null)
              ) ||
              countingStatus === "FINISHED",
            variant: "contained",
            endIcon:
              countingStatus === "IN_PROCESS" ? (
                <ChevronRightIcon />
              ) : undefined,
          },
        ]}
      />

      <Box
        flexGrow={1}
        py={2}
        display="flex"
        gap={2}
        flexDirection={{
          xs: "column",
          md: "row",
        }}
      >
        <Box>
          <PhysicalCountFilter onIdleFilterChange={handleIdleFilterChange} />
        </Box>
      </Box>

      <Box
        flexGrow={1}
        order={{
          xs: 2,
          md: 3,
        }}
        flexBasis="100%"
        height={{ md: "calc(100% - 110px)" }}
      >
        {status === "loading" && <GroupTableRecordSkeleton />}

        {searchFilter !== null && (
          <Box mb={1}>
            <ListHeader
              label={t(commons.SEARCH_RESULT)}
              count={
                groups
                  ?.map((group) =>
                    group.groupItems.filter(
                      (row) =>
                        row.name
                          .toLowerCase()
                          .includes(searchFilter?.toLocaleLowerCase() ?? "") ||
                        row.SKU.includes(searchFilter)
                    )
                  )
                  .reduce(
                    (currentCount, row) => currentCount + row.length,
                    0
                  ) ?? 0
              }
              onClick={() => {}}
              sticky
            />

            <Box
              mt={1}
              mb={{
                xs: 0,
                md: 1,
              }}
              px={1}
              display="flex"
              gap={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography>
                {t(commons.RESULTS_FOR)}: <strong>{searchFilter}</strong>
              </Typography>

              <IconButton
                size="small"
                onClick={() => dispatch(setSearch(null))}
              >
                <ClearIcon />
              </IconButton>
            </Box>

            <PhysicalCountGroupTable
              items={getSearchResult()}
              onChange={onChange}
              onCountIncrement={onCountIncrement}
              onFinishItemCounting={onFinishItemCounting}
              openSumModalSku={openSumModalSku}
              onCloseSumModal={() => setOpenSumModalSku(null)}
            />
          </Box>
        )}

        {searchFilter === null && (
          <>
            {status !== "loading" &&
              groups !== null &&
              groups
                ?.filter((group) => groupFilter.includes(group.groupName))
                .map((group) => (
                  <Box key={group.groupName} mb={1}>
                    <ListHeader
                      label={t(group.groupName)}
                      count={group.groupItems.length}
                      percent={group.completed}
                      onClick={() => toggleOpen(group.groupName)}
                      open={open.includes(group.groupName)}
                      sticky
                    />

                    {open.includes(group.groupName) && (
                      <Box px={0.25}>
                        <PhysicalCountGroupTable
                          items={
                            filterIdle
                              ? group.groupItems.filter(
                                  (item) => item.status === "IDLE"
                                )
                              : group.groupItems
                          }
                          onChange={onChange}
                          onCountIncrement={onCountIncrement}
                          onFinishItemCounting={onFinishItemCounting}
                          openSumModalSku={openSumModalSku}
                          onCloseSumModal={() => setOpenSumModalSku(null)}
                        />
                      </Box>
                    )}
                  </Box>
                ))}
          </>
        )}
      </Box>

      <Dialog
        open={openFinishedDialog}
        onClose={() => {
          setOpenFinishedDialog(false);
          goToHistory();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="primary" variant="h6">
          {t(physicalCountModule.COUNTING_FINISHED_TITLE)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(physicalCountModule.COUNTING_FINISHED_SUBTITLE)}
          </DialogContentText>

          <Box textAlign="center">
            <TaskAltIcon sx={{ fontSize: 80 }} color="success" />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              setOpenFinishedDialog(false);
              goToHistory();
            }}
            fullWidth
            autoFocus
          >
            {t(commons.ACCEPT)}
          </Button>
        </DialogActions>
      </Dialog>

      <CommonDialog
        open={openConfirmDialog}
        title={t(physicalCountModule.CONFIRM_FINISH_COUNTING_TITLE)}
        message={t(physicalCountModule.CONFIRM_FINISH_COUNTING_MESSAGE)}
        icon={
          <InfoOutlinedIcon
            sx={{
              height: "80px",
              width: "80px",
              paddingRight: "5px",
              color: "error.main",
            }}
          />
        }
        showCancelButton={true}
        handleConfirm={() => {
          logEvent(AmplitudeEvent.FinishCounting, {
            kitchenId: kitchenId,
            countingId: countingId,
          });
          setOpenConfirmDialog(false);
          finishCount();
        }}
        handleClose={() => setOpenConfirmDialog(false)}
      />

      <BarCodeScanner onAction={onActionScan} />
    </PageBox>
  );
};

export default InventoryManager;
