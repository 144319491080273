import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSPageSize,
  KOSRowData,
  KOSSelectedResult,
} from "@foodology-co/alejandria";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import PrintIcon from "@mui/icons-material/Print";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useNavigator } from "app/hooks/useNavigator";
import { commons, transferModule } from "app/i18n/types";
import { getMadeTransferOrdersByLocationId } from "app/store/slices/transfer/thunks";
import {
  MadeTransferOrderResponse,
  PaginatedMadeTransferResponse,
} from "core/transfer/entities/TransferOrder";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { compareSearchText } from "utils/general";
import PrintReportTemplate from "../../pages/Transfers/printReport";
import SearchOnTopBar from "../common/SearchOnTopBar";

interface Props {
  selectedItems: KOSSelectedResult[];
  setSelectedItems: (selectedItems: KOSSelectedResult[]) => void;
}

const TransferOrdersMadeTable: React.FC<Props> = ({
  selectedItems,
  setSelectedItems,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigator = useNavigator();
  const { kitchenId } = navigator.params();

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<KOSPageSize>(10);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [transferOrders, setTransferOrders] = useState<
    MadeTransferOrderResponse[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (kitchenId) {
      getData();
    }
  }, [kitchenId, page, rowsPerPage]);

  const getData = () => {
    if (kitchenId) {
      setLoading(true);
      dispatch(
        getMadeTransferOrdersByLocationId({
          locationId: kitchenId,
          params: { page: page, rows: rowsPerPage },
        })
      )
        .then((response) => {
          const resp = response.payload as PaginatedMadeTransferResponse;
          setTotalItems(resp.totalRows);
          setTransferOrders(resp.items);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const [filteredTransferOrders, setFilteredTransferOrders] = useState<
    MadeTransferOrderResponse[]
  >([]);

  const [transferOrderToPrint, setTransferOrderToPrint] =
    useState<MadeTransferOrderResponse>();
  const [searchTerm, setSearchTerm] = useState<string>("");

  const componentRef = React.useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setTransferOrderToPrint(undefined);
    },
  });

  const searchFilter = (transferOrder: MadeTransferOrderResponse) => {
    if (!searchTerm) return true;
    return (
      compareSearchText(transferOrder.destinationKitchenName, searchTerm) ||
      compareSearchText(transferOrder.receiptOrderId, searchTerm) ||
      compareSearchText(transferOrder.externalId, searchTerm)
    );
  };

  useEffect(() => {
    setFilteredTransferOrders(transferOrders.filter((el) => searchFilter(el)));
  }, [transferOrders, searchTerm]);

  const getHeader = (): KOSBaseTableHeader[] => [
    {
      label: t(transferModule.CREATED_AT),
      field: "createdAt",
      type: "datetime_utc",
    },
    {
      label: t(transferModule.DESTINATION),
      field: "destinationKitchenName",
    },
    {
      label: t(transferModule.STATUS),
      align: "center",
      component: (item: KOSRowData) => (
        <Chip
          icon={
            item.status.includes("RECEIVED") ? (
              <CheckCircleOutlineOutlinedIcon />
            ) : (
              <CircleOutlinedIcon />
            )
          }
          label={t(`status.${item.status}`)}
          color={item.status.includes("RECEIVED") ? "success" : "info"}
        />
      ),
    },
    {
      label: t(transferModule.ORDER_NO),
      field: "externalId",
      align: "center",
    },
    {
      label: t(transferModule.USER_CREATE),
      field: "userId",
    },
    {
      label: t(transferModule.USER_RECIBE),
      field: "receivedBy",
    },
    {
      label: t(transferModule.USER_RECIBE),
      field: "receivedAt",
      type: "datetime_utc",
    },
    {
      label: t(commons.ACTIONS),
      align: "center",
      component: (item: KOSRowData) => {
        const isPrintingOrder = item.id === transferOrderToPrint?.id;
        return (
          <Button
            onClick={() => {
              setTransferOrderToPrint(item as MadeTransferOrderResponse);
            }}
            disabled={!!transferOrderToPrint}
          >
            {isPrintingOrder ? <CircularProgress size={24} /> : <PrintIcon />}
          </Button>
        );
      },
    },
  ];

  return (
    <Box pb={2}>
      <SearchOnTopBar onSearch={setSearchTerm} />

      {transferOrderToPrint && kitchenId && (
        <PrintReportTemplate
          ref={componentRef}
          originKitchenId={kitchenId}
          transferId={transferOrderToPrint.id}
          handlePrint={handlePrint}
        />
      )}

      <KOSBaseTable
        columns={getHeader()}
        rows={{ data: filteredTransferOrders, total: totalItems, loading }}
        pagination={{
          enable: true,
          page: {
            size: rowsPerPage,
            number: page,
            onSizeChange: setRowsPerPage,
            onNumberChange: setPage,
          },
        }}
        selectable={{
          canSelectAll: true,
          resultHeader: [
            "createdAt",
            "destinationKitchenName",
            "status",
            "externalId",
            "userId",
            "receivedBy",
            "receivedBy",
            "receivedAt",
          ],
          items: selectedItems,
          onChange: setSelectedItems,
        }}
      />
    </Box>
  );
};

export default React.memo(TransferOrdersMadeTable);
