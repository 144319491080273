import { KOSPageBox } from "@foodology-co/alejandria";
import WidgetTorre from "../WidgetTorre";

interface PageBoxProps {
  showWidgetTicketTorre?: boolean;
  children: React.ReactNode;
}

const PageBox = (props: PageBoxProps) => {
  const { showWidgetTicketTorre, children } = props;
  return (
    <KOSPageBox
      extraComponent={<>{showWidgetTicketTorre && <WidgetTorre />}</>}
    >
      {children}
    </KOSPageBox>
  );
};

export default PageBox;
