import StoreMallDirectoryOutlinedIcon from "@mui/icons-material/StoreMallDirectoryOutlined";
import { Button, Grid } from "@mui/material";
import KitchenSelector from "app/components/Kitchen/Selector";
import { useAllCities } from "app/hooks/useAllCities";
import { useAllKitchens } from "app/hooks/useAllKitchens";
import { physicalCountSchedule } from "app/i18n/types";
import { KitchenSlice } from "core/kitchens/entities/Kitchen";
import {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { PhysicalCountScheduleLocation } from "utils/general";
import LocationStepTable from "./Table";

interface LocationStepProps {
  locations: PhysicalCountScheduleLocation[];
  setLocations: (locations: PhysicalCountScheduleLocation[]) => void;
  size?: "small" | "medium";
}

const LocationStep: FunctionComponent<LocationStepProps> = (props) => {
  const { locations, setLocations, size } = props;

  const { t } = useTranslation();

  const useCity = useAllCities();
  const useKitchen = useAllKitchens();

  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<KitchenSlice | null>(
    null
  );

  const citiesByCountry = useCity.byCountry(selectedCountry ?? "");

  useEffect(() => {
    setSelectedCity(null);
    setSelectedLocation(null);
  }, [selectedCountry]);

  useEffect(() => {
    setSelectedLocation(null);
  }, [selectedCity]);

  const getNewElement = (): PhysicalCountScheduleLocation => {
    return {
      country: selectedCountry ?? "",
      city: selectedCity ?? "",
      kitchenName: selectedLocation?.name ?? "ALL",
      kitchenId: selectedLocation?.kitchenId ?? "",
      kitchens: [],
    };
  };

  const doNotAdd = (
    locationsToAdd: PhysicalCountScheduleLocation[],
    newEl: PhysicalCountScheduleLocation
  ): boolean => {
    const countriesInTable = locationsToAdd.map((el) => el.country);
    return !!locationsToAdd.length && !countriesInTable.includes(newEl.country);
  };

  const foundElement = (
    locationsToAdd: PhysicalCountScheduleLocation[],
    newEl: PhysicalCountScheduleLocation
  ): PhysicalCountScheduleLocation | undefined => {
    const found = locationsToAdd.find(
      (el) =>
        el.country === newEl.country &&
        el.city === newEl.city &&
        el.kitchenId === newEl.kitchenId
    );
    return found;
  };

  const selectedCityValidate = useCallback(
    (newElement: PhysicalCountScheduleLocation) => {
      if (selectedCity !== "ALL") return;
      citiesByCountry.forEach((city) => {
        const kitchensTmp = useKitchen.byCity(city.code);
        newElement.kitchens = [...newElement.kitchens, ...kitchensTmp];
      });
    },
    [selectedCity, citiesByCountry, useKitchen]
  );

  const selectedLocationValidate = useCallback(
    (newElement: PhysicalCountScheduleLocation) => {
      if (!selectedLocation) return;
      if (selectedLocation.kitchenId !== "ALL") {
        newElement.kitchens = [selectedLocation];
        return;
      }
      const locationsOptionsTmp = useKitchen.byCity(selectedCity ?? "");
      newElement.kitchens = [...locationsOptionsTmp];
    },
    [useKitchen]
  );

  const handleAddLocation = () => {
    const locationsToAdd = [...locations];
    const newElement = getNewElement();
    if (doNotAdd(locationsToAdd, newElement)) {
      return;
    }
    const found = foundElement(locationsToAdd, newElement);
    if (found) {
      return;
    }
    selectedCityValidate(newElement);
    selectedLocationValidate(newElement);
    locationsToAdd.push(newElement);
    setLocations(locationsToAdd);
  };

  const removeDate = (index: number) => {
    const locationsToRm = [...locations];
    locationsToRm.splice(index, 1);
    setLocations(locationsToRm);
  };

  let disableButtonAdd = true;
  if (selectedCity === "ALL") {
    disableButtonAdd = false;
  } else {
    disableButtonAdd = !selectedLocation;
  }

  return (
    <Grid container spacing={2}>
      <Grid item sm={10} xs={12}>
        <KitchenSelector
          selected={{
            country: selectedCountry ?? undefined,
            city: selectedCity ?? undefined,
            kitchen: selectedLocation?.kitchenId ?? undefined,
          }}
          onChange={{
            country: (value) => setSelectedCountry(value?.code ?? ""),
            city: (value) => setSelectedCity(value?.code ?? ""),
            kitchen: (value) =>
              setSelectedLocation(value ? (value as KitchenSlice) : null),
          }}
          extra={{ allCityOrKitchens: true }}
        />
      </Grid>
      <Grid item sm={2} xs={12}>
        <Button
          onClick={handleAddLocation}
          variant="outlined"
          size={size}
          fullWidth
          sx={{ height: "100%" }}
          disabled={disableButtonAdd}
          startIcon={<StoreMallDirectoryOutlinedIcon />}
        >
          {t(physicalCountSchedule.ADD_LOCATION)}
        </Button>
      </Grid>
      <Grid item sm={12} xs={12}>
        <LocationStepTable
          locations={locations}
          removeDate={removeDate}
          size={size}
        />
      </Grid>
    </Grid>
  );
};

export default memo(LocationStep);
