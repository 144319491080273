import { setKitchens } from "app/store/slices/global";
import { Kitchen, KitchenSlice, Status } from "core/kitchens/entities/Kitchen";
import {
  getAllKitchens,
  getKitchen,
} from "core/kitchens/repository/kitchenRepo";
import { useCallback, useState } from "react";
import { useAppDispatch } from "./useAppDispatch";
import { useAppSelector } from "./useAppSelector";

export interface UseAllKitchens {
  get: () => void;
  byKitchenId: (kitchenId: string) => KitchenSlice | undefined;
  byCity: (code: string) => KitchenSlice[];
  list: KitchenSlice[];
  findOne: (id: string) => void;
  findOneData: Kitchen | undefined;
  byCountry: (code: string) => KitchenSlice[];
}

export const useAllKitchens = (): UseAllKitchens => {
  const kitchens = useAppSelector((state) => state.global.kitchens);
  const dispatch = useAppDispatch();
  let loading = false;

  const [findOneItem, setFindOneItem] = useState<Kitchen>();

  let findOneLoading = false;

  const findOne = (id: string) => {
    if (findOneLoading) return;
    findOneLoading = true;

    getKitchen(id)
      .then((response) => {
        response && setFindOneItem(response);
      })
      .catch(() => {
        setFindOneItem(undefined);
      })
      .finally(() => {
        findOneLoading = false;
      });
  };

  const getAll = () => {
    if (["loading"].includes(kitchens.status)) return;
    if (loading) return;
    loading = true;

    dispatch(setKitchens({ ...kitchens, status: "loading" }));

    getAllKitchens()
      .then((response) => {
        dispatch(
          setKitchens({
            ...kitchens,
            status: "succeeded",
            data: response
              .filter((el) => el.status === Status.ACTIVE)
              .map(
                (kitchen) =>
                  ({
                    id: kitchen.id,
                    kitchenId: kitchen.kitchenId,
                    name: kitchen.name,
                    locationCode: kitchen.locationCode,
                    city: kitchen.city.code,
                    address: kitchen.address,
                    country: kitchen.country,
                    type: kitchen.type,
                  } as KitchenSlice)
              ),
          })
        );
      })
      .catch((error) => {
        dispatch(setKitchens({ ...kitchens, status: "failed", error }));
      })
      .finally(() => {
        loading = false;
      });
  };

  const getByKitchenId = useCallback(
    (kitchenId: string) => {
      return kitchens.data.find((kitchen) => kitchen.kitchenId === kitchenId);
    },
    [kitchens]
  );

  const getByCountry = useCallback(
    (code: string) => {
      return kitchens.data.filter((kitchen) => {
        if (!code) return true;
        return kitchen.country === code;
      });
    },
    [kitchens]
  );

  const getByCity = useCallback(
    (code: string) => {
      return kitchens.data.filter((kitchen) => {
        if (!code) return true;
        return kitchen.city === code;
      });
    },
    [kitchens]
  );

  return {
    get: getAll,
    byKitchenId: getByKitchenId,
    list: kitchens.data,
    findOne,
    findOneData: findOneItem,
    byCountry: getByCountry,
    byCity: getByCity,
  };
};
