import { createAsyncThunk } from "@reduxjs/toolkit";
import appConfig from "config/app";
import { TheoreticalInventoryHTTPRepository } from "core/theoricalInventory/repositories/TheoreticalInventory";
import { ExportInventoryUseCase } from "core/theoricalInventory/useCases/exportInventoryUseCase";
import { GetByKitchenAndSkusUseCase } from "core/theoricalInventory/useCases/getByKitchenAndSkusUseCase";

import { GetByKitchenUseCase } from "core/theoricalInventory/useCases/getByKitchenUseCase";
import { GetComparisonByCityUseCase } from "core/theoricalInventory/useCases/getComparisonByCityUseCase";
import { GetComparisonByKitchenUseCase } from "core/theoricalInventory/useCases/getComparisonByKitchenUseCase";
import { GetTheoreticalInventoryMovementsResumeUseCase } from "core/theoricalInventory/useCases/getTheoreticalInventoryMovementsResume";

export const getTheoreticalInventoryByKitchen = createAsyncThunk(
  "theoreticalInventory/byKitchen",
  async (payload: { kitchenId: string }) => {
    const httpRepository = new TheoreticalInventoryHTTPRepository(appConfig);

    const getByKitchenUseCase = new GetByKitchenUseCase(httpRepository);

    const theoreticalInventory = await getByKitchenUseCase.execute(
      payload.kitchenId
    );
    return theoreticalInventory;
  }
);

export const theoreticalInventoryByKitchenAndSkus = createAsyncThunk(
  "theoreticalInventory/byKitchen",
  async (payload: { kitchenId: string; skus: string[] }) => {
    const httpRepository = new TheoreticalInventoryHTTPRepository(appConfig);

    const getByKitchenAndSkusUseCase = new GetByKitchenAndSkusUseCase(
      httpRepository
    );

    const theoreticalInventory = await getByKitchenAndSkusUseCase.execute(
      payload.kitchenId,
      payload.skus
    );
    return theoreticalInventory;
  }
);

export const getTheoreticalInventoryComaprisonByKitchen = createAsyncThunk(
  "theoreticalInventory/comparisonByKitchen",
  async (payload: { kitchenId: string }) => {
    const httpRepository = new TheoreticalInventoryHTTPRepository(appConfig);

    const getByKitchenUseCase = new GetComparisonByKitchenUseCase(
      httpRepository
    );

    const theoreticalInventory = await getByKitchenUseCase.execute(
      payload.kitchenId
    );
    return theoreticalInventory;
  }
);

export const getTheoreticalInventoryComaprisonByCity = createAsyncThunk(
  "theoreticalInventory/comparisonByCity",
  async (payload: { cityCode: string }) => {
    const httpRepository = new TheoreticalInventoryHTTPRepository(appConfig);

    const getByCityUseCase = new GetComparisonByCityUseCase(httpRepository);

    const theoreticalInventory = await getByCityUseCase.execute(
      payload.cityCode
    );
    return theoreticalInventory;
  }
);

export const getTheoreticalInventoryMovementsResume = createAsyncThunk(
  "theoreticalInventory/inventoryMovementsResume",
  async (payload: { kitchenId: string; sku: string }) => {
    const httpRepository = new TheoreticalInventoryHTTPRepository(appConfig);

    const getInventoryMovementsResumeUseCase =
      new GetTheoreticalInventoryMovementsResumeUseCase(httpRepository);

    const inventoryMovementsResume =
      await getInventoryMovementsResumeUseCase.execute(
        payload.kitchenId,
        payload.sku
      );

    return inventoryMovementsResume;
  }
);

export const exportInventoryByCountry = createAsyncThunk(
  "theoreticalInventory/export",
  async (payload: { country: string }) => {
    const httpRepository = new TheoreticalInventoryHTTPRepository(appConfig);

    const exportInventoryUseCase = new ExportInventoryUseCase(httpRepository);

    const theoreticalInventory = await exportInventoryUseCase.execute(
      payload.country
    );

    return theoreticalInventory;
  }
);
