import { KOSEmptyState } from "@foodology-co/alejandria";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { clearSuppliesByLocationState } from "app/store/slices/supplies";
import {
  getInactiveSuppliesByLocation,
  getSuppliesByCountry,
  setSupliesStateByKitchens,
} from "app/store/slices/supplies/thunks";
import { UpdateSupplyStatusPayload } from "core/supplies/entities/Supply";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import KitchenSelector from "../Kitchen/Selector";
import ByKitchenTable from "./ByKitchenTable";

interface ByKitchenTabProps {}

const ByKitchenTab: FunctionComponent<ByKitchenTabProps> = () => {
  const dispatch = useAppDispatch();

  const [selectedCountry, setSelectedCountry] = useState<string>();
  const [selectedCity, setSelectedCity] = useState<string>();
  const [selectedLocation, setSelectedLocation] = useState<string>();

  const [searchText, setSearchText] = useState<string | null>(null);

  const suppliesRequest = useAppSelector(
    (state) => state.supplies.suppliesByCountry
  );
  const inactiveSuppliesRequest = useAppSelector(
    (state) => state.supplies.inactiveSuppliesByLocation
  );
  const setInactiveSupplies = useAppSelector(
    (state) => state.supplies.setSupliesStateByKitchens
  );

  const getSupplies = useCallback(() => {
    if (!selectedCountry) return;
    dispatch(getSuppliesByCountry(selectedCountry));
  }, [selectedCountry]);

  const getInactiveByLocation = useCallback(
    (locationId: string) => {
      if (selectedLocation !== null) {
        dispatch(getInactiveSuppliesByLocation(locationId));
      }
    },
    [selectedLocation]
  );

  const updateSuppliesActiveStatus = useCallback(
    (
      kitchenIds: Array<string>,
      suppliesSkus: Array<string>,
      status: boolean
    ) => {
      const payload: Array<UpdateSupplyStatusPayload> = kitchenIds
        .map((kitchenId) =>
          suppliesSkus.map((sku) => ({
            sku,
            kitchenId,
            state: status,
            comments: "",
          }))
        )
        .flat();

      dispatch(setSupliesStateByKitchens(payload));
    },
    []
  );

  useEffect(() => {
    getSupplies();
  }, [getSupplies, selectedCountry]);

  useEffect(() => {
    if (!selectedLocation) {
      dispatch(clearSuppliesByLocationState());
      return;
    }
    getInactiveByLocation(selectedLocation);
  }, [selectedLocation]);

  useEffect(() => {
    if (!selectedLocation) return;
    dispatch(getInactiveSuppliesByLocation(selectedLocation));
  }, [selectedLocation]);

  return (
    <Box>
      <Paper elevation={0} sx={{ px: 1.5, py: 2, mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item md={8} xs={12}>
            <KitchenSelector
              selected={{
                country: selectedCountry,
                city: selectedCity,
                kitchen: selectedLocation,
              }}
              onChange={{
                country: (value) => setSelectedCountry(value?.code),
                city: (value) => setSelectedCity(value?.code),
                kitchen: (value) => setSelectedLocation(value?.kitchenId),
              }}
              extra={{ size: "small", showHash: true }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              placeholder="Buscar insumo"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              size="small"
              disabled={
                suppliesRequest.supplies === null ||
                suppliesRequest.supplies.length === 0
              }
              fullWidth
              autoComplete="off"
            />
          </Grid>
        </Grid>
      </Paper>

      {selectedLocation === null && (
        <KOSEmptyState
          icon={InfoOutlinedIcon}
          instruction="Seleccione un pais, ciudad y cocina para ver el listado de insumos"
          message="No hay cocina seleccionada"
        />
      )}

      {selectedLocation !== null && (
        <>
          <ByKitchenTable
            supplies={
              inactiveSuppliesRequest.status === "succeeded"
                ? suppliesRequest?.supplies ?? []
                : []
            }
            searchText={searchText}
            inactiveSupplies={inactiveSuppliesRequest?.inactiveSupplies ?? []}
            loading={
              suppliesRequest.status === "loading" ||
              inactiveSuppliesRequest.status === "loading" ||
              setInactiveSupplies.status === "loading"
            }
            onToggleInactive={(skus, checked) =>
              updateSuppliesActiveStatus(
                selectedLocation ? [selectedLocation] : [],
                skus,
                checked
              )
            }
          />
        </>
      )}
    </Box>
  );
};

export default ByKitchenTab;
