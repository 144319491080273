import { KOSEnvironment, KOSTopbar } from "@foodology-co/alejandria";
import { HermesNotification } from "@foodology-co/alejandria/dist/components/molecules/Notification";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import useNotifications from "app/hooks/useNotifications";
import { setSearchOnTopBar } from "app/store/slices/global";
import { toggleMenu } from "app/store/slices/menu";
import appConfig from "config/app";
import { getUserLogOut } from "core/account/repositories/http/user";

const TopBar = () => {
  const dispatch = useAppDispatch();
  const menuOpen = useAppSelector((state) => state.menu.open);
  const user = useAppSelector((state) => state.session.user.data);
  const session = useAppSelector((state) => state.session.data);
  const title = useAppSelector((state) => state.global.title);
  const canSearchOnTopBar = useAppSelector(
    (state) => state.global.canSearchOnTopBar
  );

  const {
    notifications,
    legologyHermesInstance,
    refreshNotifications,
    verifyNotificationPerms,
  } = useNotifications();

  const toggle = () => {
    dispatch(toggleMenu());
  };

  const handleClickNotificationButton = (isNotificationsOff: boolean) => {
    refreshNotifications();
  };

  const handleClickNotification = (notification?: HermesNotification) => {
    verifyNotificationPerms();
  };

  const onSearch = (textParam: string) =>
    dispatch(setSearchOnTopBar(textParam));
  const searchText = useAppSelector((state) => state.global.textSearchOnTopBar);

  return (
    <KOSTopbar
      title={title ?? ""}
      hamburgerMenu={{
        open: menuOpen,
        onClick: toggle,
      }}
      user={{
        name: user?.profile?.name ?? "",
        avatarUrl: user?.profileImage,
        companyId: session?.companyId ?? "fdgy",
      }}
      environment={appConfig.env as KOSEnvironment}
      accountMenu={{
        helpCenterLink:
          "https://foodology.notion.site/Centro-de-ayuda-para-cocinas-f49c4322877e428f907652f9b3d2783c",
        logOut: {
          redirect: `${appConfig.kitchenDisplay.url}/login`,
          onClick: getUserLogOut,
        },
      }}
      search={{
        hide: !canSearchOnTopBar,
        value: searchText,
        onChange: onSearch,
      }}
      alerts={{
        noPerms: !legologyHermesInstance.accessGranted,
        notifications: notifications as HermesNotification[],
        onClick: handleClickNotificationButton,
        onNotificationClick: handleClickNotification,
      }}
    />
  );
};

export default TopBar;
