import { KOSTabs } from "@foodology-co/alejandria";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import KitchenSelector from "app/components/Kitchen/Selector";
import PageBox from "app/components/PageBox";
import PageHeader from "app/components/PageHeader";
import VendorSupplyBulkLoad from "app/components/Vendor/Supply/BulkLoad";
import { commons, vendor } from "app/i18n/types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BySuppliesView from "./views/BySupplies";
import ByVendorsView from "./views/ByVendor";

const VendorPage = () => {
  const { t } = useTranslation();

  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [createVendor, setCreateVendor] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [tab, setTab] = useState<number>(-1);

  const onChangeTab = (index: number) => {
    setCreateVendor(false);
    setSelectedCountry(null);
    setSelectedCity(null);
    setTab(index);
  };

  return (
    <PageBox>
      <PageHeader
        title={vendor.VENDOR_SUPPLY_MODULE_TITLE}
        subtitle={vendor.VENDOR_SUPPLY_MODULE_DESCRIPTION}
        centerArea={
          <>
            {![2].includes(tab) && (
              <Box
                sx={{ display: "flex", flexDirection: "row-reverse", gap: 1 }}
              >
                <Box sx={{ width: tab !== 1 ? 184 : 440 }}>
                  <KitchenSelector
                    selected={{
                      country: selectedCountry ?? undefined,
                      city: selectedCity ?? undefined,
                    }}
                    onChange={{
                      country: (value) =>
                        setSelectedCountry(value?.code ?? null),
                      city: (value) => setSelectedCity(value?.code ?? null),
                    }}
                    hide={{
                      city: tab !== 1,
                      kitchen: true,
                    }}
                    extra={{ showHash: true, size: "small" }}
                  />
                </Box>
                {[0].includes(tab) && (
                  <Button
                    variant="outlined"
                    onClick={() => setCreateVendor(true)}
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                    disabled={!selectedCountry}
                  >
                    {t(commons.ADD)} {t(commons.SUPPLIER)}
                  </Button>
                )}
              </Box>
            )}
          </>
        }
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <KOSTabs
            tabs={[
              {
                children: {
                  text: t(vendor.VIEW_BY_VENDORS),
                  startIcon: <CalendarMonthOutlinedIcon />,
                },
              },
              {
                children: {
                  text: t(vendor.VIEW_BY_SUPPLIES),
                  startIcon: <FactCheckOutlinedIcon />,
                },
              },
              {
                children: {
                  text: t(commons.BULK_LOAD),
                  startIcon: <ArchiveOutlinedIcon />,
                },
              },
            ]}
            loading={loading}
            onChange={onChangeTab}
          />
        </Grid>
        <Grid item xs={12}>
          {tab === 0 && (
            <ByVendorsView
              selectedCountry={selectedCountry ?? ""}
              openCreate={createVendor}
              onCloseCreate={() => setCreateVendor(false)}
              onLoading={setLoading}
            />
          )}
          {tab === 1 && (
            <BySuppliesView
              selectedCity={selectedCity ?? ""}
              onLoading={setLoading}
            />
          )}
          {tab === 2 && <VendorSupplyBulkLoad onLoading={setLoading} />}
        </Grid>
      </Grid>
    </PageBox>
  );
};

export default VendorPage;
