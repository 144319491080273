import { KOSPageHeader, KOSPageHeaderButton } from "@foodology-co/alejandria";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import { commons } from "app/i18n/types";
import { clearTitle, setTitle } from "app/store/slices/global";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUnmount } from "react-use";
import { getCurrentWeekNumber } from "utils/general";
import { ObjectParams } from "utils/http";
import { InventoryModule, getNavs } from "utils/module";
import { TypeOfRole } from "utils/role";

interface Props {
  title: string;
  subtitle?: string;
  centerArea?: React.ReactElement;
  rightArea?: KOSPageHeaderButton[];
  isFullDialog?: boolean;
}

const PageHeader = (props: Props) => {
  const { title, subtitle, centerArea, rightArea, isFullDialog } = props;
  const dispatch = useAppDispatch();
  const navigator = useNavigator();
  const pathname = navigator.location();
  const params = navigator.params();
  const { t } = useTranslation();
  const selectedKitchen = useAppSelector(
    (state) => state.global.selectedKitchen
  );

  const user = useAppSelector((state) => state.session.user.data);
  const role = user?.role as TypeOfRole;
  const [navs, setNavs] = useState<InventoryModule[]>([]);

  useEffect(() => {
    const labelsTmp: string[] = [];

    if (selectedKitchen) {
      labelsTmp.push(selectedKitchen.name.toUpperCase());
    }

    if (title) labelsTmp.push(t(title));

    const weekPrefix = t(commons.WEEK).toUpperCase().slice(0, 1);
    const weekNumber = getCurrentWeekNumber();
    labelsTmp.push(`${weekPrefix}${weekNumber}`);

    dispatch(setTitle(labelsTmp.join(" - ")));
    if (!isFullDialog) {
      setNavs(
        getNavs([], role, pathname, params as ObjectParams).map((el) => {
          el["moduleName"] = t(el["moduleName"]);
          return el;
        })
      );
    }
  }, [selectedKitchen, title]);

  useUnmount(() => {
    if (isFullDialog) return;
    dispatch(clearTitle());
  });

  const goTo = (link?: string) => {
    if (link) {
      navigator.toByLocationType(link);
    }
  };

  return (
    <KOSPageHeader
      head={{
        title: t(title),
        subtitle: t(subtitle ?? ""),
        centerArea,
        rightArea,
      }}
      nav={{
        options: navs,
        redirectTo: goTo,
      }}
    />
  );
};

export default PageHeader;
