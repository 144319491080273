import { HistoryOutlined } from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CommentDrawerWithFloatButton from "app/components/CommentArea/DrawerWithFloatButton";
import PageBox from "app/components/PageBox";
import ProductionLabelDialog from "app/components/Productions/Label/Dialog/Purchase";
import PurchaseOrdersLinesSkeleton from "app/components/PurchaseLinesTable/PurchaseOrdersLinesSkeleton";
import UpdateReasonAutocomplete from "app/components/PurchaseLinesTable/UpdateReasonAutocomplete";
import UpdateRecivedQuantityTransferTextField from "app/components/PurchaseLinesTable/UpdateRecivedQuantityTransferTextField";
import BoxTitleTable from "app/components/PurchaseRecord/BoxTitleTable";
import BarCodeScanner, {
  OnActionResponse,
} from "app/components/Scanner/BarCode";
import TransferOrderLineResume from "app/components/TransferOrders/Line/Resume";
import TransferPackingDeleteLabels from "app/components/Transfers/TransferPackingDeleteLabels";
import SearchOnTopBar from "app/components/common/SearchOnTopBar";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useNavigator } from "app/hooks/useNavigator";
import { commons, productionScannedError } from "app/i18n/types";
import { setTitle } from "app/store/slices/global";
import {
  existsLabelLogReceipt,
  scannedItemReceipt,
  setLinesWhenScanned,
} from "app/store/slices/purchase/lines.slice";
import { getTransferOrderById } from "app/store/slices/transfer/thunks";
import { onInputKeyDown } from "app/utils/input";
import { AttachmentFile } from "core/attachment/entity";
import { getReceiptAttachments } from "core/attachment/repository/http";
import { CommentTypeEnum } from "core/comment/entity";
import {
  ProductionLabel,
  ProductionLabelToShow,
  ProductionScannedtResponse,
} from "core/productions/entities/Productions";
import { AttachmentsDetailResponse } from "core/purchaseOrders/entities/ReceiptOrder";
import {
  TransferLine,
  TransferResponse,
} from "core/transfer/entities/TransferOrder";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { useMount } from "react-use";
import { compareSearchText } from "utils/general";
import { iconButtonStyle } from "utils/generalUI";
import { getLabelFromResponse } from "utils/scanner";
import FinishTransferOrderReceipt from "./FinishTransferOrderReceipt";
import TransferSuppliesPrintTemplate from "./ReceiptTransferPrintTemplate";

interface Props {}

const TransferOrderReceipt: FunctionComponent<Props> = () => {
  const { t } = useTranslation();
  const navigator = useNavigator();
  const { kitchenId, orderId } = navigator.params();

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [transferOrder, setTransferOrder] = useState<TransferResponse>();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [linesFiltered, setLinesFiltered] = useState<TransferLine[]>([]);
  const [productionLabelToShow, setProductionLabelToShow] =
    useState<ProductionLabelToShow>();
  const [openDeleteLabels, setOpenDeleteLabels] = useState<boolean>(false);
  const [deleteLabels, setDeleteLabels] = useState<ProductionLabel[]>([]);
  const [attachments, setAttachments] = useState<AttachmentFile[]>([]);

  useMount(() => {
    getAttachments();
    getTransferOrder();
  });

  const getAttachments = async () => {
    if (orderId) {
      const response = await getReceiptAttachments(
        orderId,
        "KIS",
        "TRANSFER_ORDER",
        kitchenId ?? ""
      );
      setAttachments(response);
    }
  };

  const componentRef = React.useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getTransferOrder = async () => {
    if (orderId) {
      setLoading(true);
      const transferOrderResponse = await dispatch(
        getTransferOrderById({ transferId: orderId })
      );
      const transferOrder = transferOrderResponse.payload as TransferResponse;
      setTransferOrder({
        ...transferOrder,
        lines: transferOrder.lines.filter((item) => item.sendQuantity > 0),
      });
      if (transferOrder) {
        setLoading(false);
      }
    }
  };

  const updateTransferOrderLine = useCallback(
    (lineId: number, quantityToReceive: number, returnReason?: string) => {
      const transfer = { ...transferOrder };
      if (transfer?.lines) {
        const lines = transfer.lines;
        const index = lines.findIndex((item) => item.id === lineId);
        lines[index].status = "IN_PROCESS";
        lines[index].quantityToReceive = quantityToReceive;
        lines[index].returnReason = returnReason;
        transfer.lines = lines;
        setTransferOrder(transfer as TransferResponse);
      }
    },
    [transferOrder]
  );

  const filterLines = (line: TransferLine): boolean => {
    if (!searchQuery) return true;
    return (
      compareSearchText(line.sku, searchQuery) ||
      compareSearchText(line.name, searchQuery)
    );
  };

  useEffect(() => {
    dispatch(setTitle(t(commons.PURCHASE_ORDER)));
  }, [dispatch, t]);

  useEffect(() => {
    if (transferOrder) {
      setLinesFiltered(transferOrder.lines?.filter(filterLines) ?? []);
    }
  }, [transferOrder, searchQuery]);

  const getSuccessMessage = (response: ProductionScannedtResponse): string => {
    const { quantity, unit, productName } = response;

    return `${t(
      commons.RECEIVED
    )} ${productName} ${quantity} ${unit.toLowerCase()}`;
  };

  const onActionScanFetch = async (orderId: string, code: string) => {
    if (!openDeleteLabels) {
      return await dispatch(
        scannedItemReceipt({
          orderId,
          productionLabelId: code,
        })
      );
    }
    return await dispatch(
      existsLabelLogReceipt({
        orderId,
        originId: kitchenId ?? "",
        productionLabelId: code,
      })
    );
  };

  const onActionScan = async (code: string): Promise<OnActionResponse> => {
    if (!orderId || !kitchenId) {
      return {
        success: false,
        message: productionScannedError.SELECT_ORDER_AND_KITCHEN,
      };
    }
    const response = await onActionScanFetch(orderId, code);
    const res = response.payload as ProductionScannedtResponse;
    if (!res.ok) {
      return { success: false, message: res.error };
    }
    if (!openDeleteLabels) {
      dispatch(setLinesWhenScanned(res));
    } else {
      const label = getLabelFromResponse(res);
      const labelFound = deleteLabels.find((el) => el.id === label.id);
      if (!labelFound) {
        setDeleteLabels([...deleteLabels, label]);
      }
    }
    const message = getSuccessMessage(res);
    return { success: true, message };
  };

  const refreshProductionLabelToShow = () => {
    if (productionLabelToShow) {
      transferOrder?.lines?.forEach((line) => {
        if (line.id === productionLabelToShow?.lineId) {
          setProductionLabelToShow({
            lineId: line.id,
            labels: line.labels,
          });
        }
      });
    }
  };

  useEffect(() => {
    refreshProductionLabelToShow();
  }, [transferOrder]);

  useEffect(() => {
    setDeleteLabels([]);
  }, [openDeleteLabels]);

  const setDetailAttachments = (response: AttachmentsDetailResponse | null) => {
    if (response) {
      setAttachments(response.attachments);
    }
  };

  const transferWithLabels = !!transferOrder?.lines?.reduce(
    (sum, line) => sum + line.labels.length,
    0
  );

  return (
    <PageBox>
      {transferOrder && kitchenId && (
        <>
          <FinishTransferOrderReceipt
            transferOrder={transferOrder}
            setOpenDeleteLabels={setOpenDeleteLabels}
            kitchenId={kitchenId}
            initialAttachments={attachments}
            setNewAttachments={setDetailAttachments}
            handlePrint={handlePrint}
          ></FinishTransferOrderReceipt>

          <CommentDrawerWithFloatButton
            sourceId={transferOrder.toString()}
            kitchenId={kitchenId}
            type={CommentTypeEnum.TRANSFER_ORDER}
          />
        </>
      )}

      <SearchOnTopBar onSearch={setSearchQuery} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TransferOrderLineResume
            loading={loading}
            transferOrder={transferOrder}
          />
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="inventory" stickyHeader>
              <TableHead>
                <TableRow>
                  {transferWithLabels && (
                    <BoxTitleTable data={t(commons.PRINT_LABELS)} />
                  )}
                  <BoxTitleTable data={t(commons.SKU)} />
                  <BoxTitleTable data={t(commons.PRODUCT_NAME)} />
                  <BoxTitleTable data={t(commons.REQUESTED)} />
                  <BoxTitleTable data={t(commons.RECEIVED)} />
                  <BoxTitleTable data={t(commons.UNIT)} />
                  <BoxTitleTable data={t(commons.REASON)} />
                </TableRow>
              </TableHead>

              {loading && <PurchaseOrdersLinesSkeleton />}

              {loading === false && (
                <Box component={TableBody} onKeyDown={onInputKeyDown}>
                  {linesFiltered.map((row) => (
                    <TableRow key={row.id}>
                      {transferWithLabels && (
                        <TableCell align="left">
                          <IconButton
                            onClick={() => {
                              setProductionLabelToShow({
                                lineId: row.id,
                                labels: row.labels,
                              });
                            }}
                            sx={iconButtonStyle}
                            disabled={!row.labels.length}
                          >
                            <HistoryOutlined
                              color={
                                !row.labels.length ? "disabled" : "primary"
                              }
                            />
                          </IconButton>
                        </TableCell>
                      )}
                      <TableCell align="left">
                        <Typography>{row.id}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Box display="flex" alignItems="center" gap={2}>
                          <Typography>{row.name}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="left">{row.sendQuantity}</TableCell>
                      <TableCell align="left">
                        <UpdateRecivedQuantityTransferTextField
                          transferLineId={row.id}
                          receivedQuantity={row.quantityToReceive ?? 0}
                          status={row.status}
                          sendQuantity={row.sendQuantity}
                          updateLineStatus={updateTransferOrderLine}
                          returnReason={row.returnReason}
                          isReturn={transferOrder?.type === "RETURN"}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Typography fontWeight={700}>
                          {row.measureUnit}
                        </Typography>
                      </TableCell>
                      {transferOrder?.type === "RETURN" && (
                        <TableCell align="center">
                          {t(`missingReason.${row.returnReason}`)}
                        </TableCell>
                      )}
                      <TableCell align="center">
                        {row.status === "IN_PROCESS" &&
                          row.quantityToReceive !== row.sendQuantity &&
                          transferOrder?.type !== "RETURN" && (
                            <UpdateReasonAutocomplete
                              transferOrderLineId={row.id}
                              reason={row.returnReason}
                              quantityToReceive={row.quantityToReceive ?? 0}
                              updateLineStatus={updateTransferOrderLine}
                            />
                          )}
                      </TableCell>
                    </TableRow>
                  ))}
                </Box>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <BarCodeScanner onAction={onActionScan} />
      <ProductionLabelDialog
        open={!!productionLabelToShow}
        onClose={() => setProductionLabelToShow(undefined)}
        labels={productionLabelToShow?.labels ?? []}
        showName
      />
      <TransferPackingDeleteLabels
        open={openDeleteLabels}
        deleteLabels={deleteLabels}
        originId={kitchenId ?? ""}
        onClose={() => setOpenDeleteLabels(false)}
        isReceipt
      />
      {transferOrder && (
        <Box sx={{ display: "none" }}>
          <TransferSuppliesPrintTemplate
            transfer={transferOrder}
            ref={componentRef}
          />
        </Box>
      )}
    </PageBox>
  );
};

export default TransferOrderReceipt;
