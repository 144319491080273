import { UseCase } from "core/common/interfaces/useCase";
import { CanGetSuppliesByCountry } from "core/supplies/interfaces/supplies/canGetSuppliesByCountry";

export class GetSuppliesByCountryUseCase implements UseCase {
  constructor(private readonly repository: CanGetSuppliesByCountry) {}

  public execute(country: string) {
    return this.repository.getSuppliesByCountry(country);
  }
}
