import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import GreenSwitch from "app/components/GreenSwitch";
import { useAllCities } from "app/hooks/useAllCities";
import { City } from "core/city/entities/City";
import { KitchenSlice } from "core/kitchens/entities/Kitchen";
import { Supply, SupplyInactiveKitchen } from "core/supplies/entities/Supply";
import { FunctionComponent, useEffect, useMemo, useState } from "react";

interface SupplyInfoDrawerProps {
  open: boolean;
  onClose: () => void;
  supply: Supply | null;
  kitchens: Array<KitchenSlice> | null;
  selectedCountry: string;
  onSwitchChange: (
    sku: string,
    kitchenId: string,
    switchState: boolean
  ) => void;
  loading: boolean;
  inactiveKitchens: Array<SupplyInactiveKitchen> | null;
  getInactiveKitchens: () => void;
}

const SupplyInfoDrawer: FunctionComponent<SupplyInfoDrawerProps> = (props) => {
  const {
    open,
    onClose,
    supply,
    kitchens,
    onSwitchChange,
    loading,
    inactiveKitchens,
    selectedCountry,
    getInactiveKitchens,
  } = props;

  const useCIty = useAllCities();
  const citiesByCountry = useCIty.byCountry(selectedCountry);

  const [selectedCity, setSelectedCity] = useState<City>();

  const citiesOptions = useMemo(() => {
    return [...citiesByCountry];
  }, [citiesByCountry]);

  const kitchensOptions = useMemo(() => {
    if (!selectedCity) return [];

    return (
      kitchens
        ?.filter((kitchen) => [selectedCity?.code].includes(kitchen.city))
        .sort((a, _b) => (a.type === "PRODUCTION_CENTER" ? -1 : 1)) ?? []
    );
  }, [selectedCity]);

  useEffect(() => {
    setSelectedCity(undefined);
  }, [supply]);

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box width={360}>
        <Box
          bgcolor="#F1EFEF"
          mt={7}
          display="flex"
          gap={2}
          alignItems="center"
          px={2}
          py={1.5}
        >
          <IconButton
            aria-label="delete"
            onClick={() => onClose()}
            sx={{ bgcolor: "white" }}
            size="small"
          >
            <CloseOutlinedIcon />
          </IconButton>

          <ListOutlinedIcon />

          <Typography variant="h6">Estatus del Insumo</Typography>
        </Box>

        <Box mt={3} px={4}>
          <Typography variant="h5">{supply?.name}</Typography>
        </Box>

        <Box mt={3} px={4}>
          <Autocomplete
            id={`supplies-info-city-autocomplete`}
            options={citiesOptions}
            value={selectedCity ?? null}
            onChange={(_: any, newValue: City | null) => {
              setSelectedCity(newValue ?? undefined);
              getInactiveKitchens();
            }}
            limitTags={3}
            sx={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Ciudad" />}
            disabled={loading}
          />
        </Box>

        {supply !== null && inactiveKitchens !== null && (
          <Box mt={3} px={2}>
            <Typography variant="h6" ml={2} gutterBottom>
              Estatus en cocinas:
            </Typography>

            {selectedCity === null && (
              <Typography ml={2} color="text.secondary">
                Seleccione una ciudad
              </Typography>
            )}

            <List>
              {kitchensOptions?.map((kitchen) => (
                <ListItem key={kitchen.kitchenId}>
                  <ListItemText>
                    <Typography variant="h6" textTransform="capitalize">
                      {kitchen.name.toLocaleLowerCase()}
                    </Typography>
                  </ListItemText>

                  <GreenSwitch
                    edge="end"
                    inputProps={{
                      "aria-labelledby": `switch-list-label-${kitchen.kitchenId}`,
                    }}
                    defaultChecked={
                      !inactiveKitchens.some(
                        (inactiveKitchen: SupplyInactiveKitchen) =>
                          kitchen.kitchenId === inactiveKitchen.kitchenId
                      )
                    }
                    onChange={(_, checked: boolean) => {
                      onSwitchChange(supply.sku, kitchen.kitchenId, checked);
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default SupplyInfoDrawer;
