import { KOSPageHeaderButton } from "@foodology-co/alejandria";
import { Chip } from "@mui/material";
import { commentArea } from "app/i18n/types";
import { TFunction } from "react-i18next";

export const commentButtonHeader = (
  t: TFunction,
  messagesCount: number,
  show: boolean,
  openDrawer: () => void
): KOSPageHeaderButton => {
  return {
    variant: "outlined",
    color: "info",
    startIcon: (
      <>
        {!!messagesCount && (
          <Chip label={messagesCount} color="primary" size="small" />
        )}
      </>
    ),
    children: t(commentArea.TITLE),
    onClick: openDrawer,
    show,
  };
};
