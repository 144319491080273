import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { physicalCountModule } from "app/i18n/types";
import { useTranslation } from "react-i18next";

interface KitchensAutocompleteProps<T> {
  options: Array<T>;
  value: Array<T>;
  label: string;
  onChange: (value: Array<T>) => void;
  disabled?: boolean;
  multiple?: boolean;
}

const KitchensAutocomplete = <T extends unknown>(
  props: KitchensAutocompleteProps<T>
) => {
  const { t } = useTranslation();
  const { options, value, label, onChange, disabled } = props;

  return (
    <Autocomplete
      id={`supplies-update-${label}-autocomplete`}
      options={options}
      getOptionLabel={(option: T) => (option as any)?.name ?? option}
      renderOption={(renderOptionsProps, option, { selected }) => (
        <li
          {...renderOptionsProps}
          onClick={
            (option as any)?.name === t(physicalCountModule.SELECT_ALL) ||
            option === t(physicalCountModule.SELECT_ALL)
              ? () =>
                  value.length === options.length
                    ? onChange([])
                    : onChange(options)
              : renderOptionsProps.onClick
          }
        >
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {(option as any)?.name ?? option}
        </li>
      )}
      value={value}
      onChange={(_: any, newValue: Array<T> | null) => onChange(newValue ?? [])}
      limitTags={1}
      sx={{ width: "100%" }}
      renderInput={(params) => <TextField {...params} label={label} />}
      disabled={disabled || options.length === 0}
      multiple
    />
  );
};

export default KitchensAutocomplete;
