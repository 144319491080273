import { User } from "core/account/entities/User";
import { KitchenSlice } from "core/kitchens/entities/Kitchen";

interface FilterKitchensByUser {
  kitchens: Array<KitchenSlice>;
  country?: string;
}

export const filterKitchensByUser = (
  user: User,
  kitchens: Array<KitchenSlice>
): FilterKitchensByUser => {
  const kitchenId = user.profile?.kitchen?.kitchenId;
  if (!kitchenId) {
    return { kitchens };
  }
  const country = kitchens.find(
    (kitchen) => kitchen.kitchenId === kitchenId
  )?.country;

  const kitchensFiltered = kitchens.filter(
    (kitchen) => kitchen.country === country
  );

  return { kitchens: kitchensFiltered, country };
};
