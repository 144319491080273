import { WarningAmber } from "@mui/icons-material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import Box from "@mui/material/Box";
import AuthorizationTable from "app/components/DailyAdjustments/Authorization/Table";
import FinishedProductDrawer from "app/components/DailyAdjustments/FinishedProductDrawer";
import DocumentsViewer from "app/components/DocumentViewer";
import PageBox from "app/components/PageBox";
import PageHeader from "app/components/PageHeader/PageHeader";
import CommonDialog, {
  CommonDialogContent,
} from "app/components/common/CommonDialog";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import {
  commons,
  dailyAdjustmentsModule,
  kitchenInventoryManagement,
} from "app/i18n/types";
import { validateAdjustments } from "app/store/slices/adjustments/thunks";
import {
  archiveAdjustments,
  authorizeAdjustments,
  getAdjustmentsByKitchenForReview,
} from "app/store/slices/dailyAdjustments/thunks";
import dayjs, { localizeDayjs } from "app/utils/dayjs";
import {
  AdjustmentQuantities,
  AuthorizationOption,
  authorizationOptions,
} from "config/dailyAdjustment";
import {
  EntryType,
  ManualAdjustment,
} from "core/adjustments/entities/ManualAdjustment";
import {
  AdjustmentRecord,
  AuthorizeAdjustmentsPayload,
} from "core/dailyAdjustments/entities/Adjustment";
import { Product } from "core/dailyAdjustments/entities/Catalog";
import { OriginType } from "core/purchaseOrders/entities/PurchaseOrder";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMount } from "react-use";
import { refinedAdjustments } from "utils/general";
import { getFinishedProductsByCountryAndSkus } from "utils/ratatouille";
import AuthorizationTabs from "../../components/DailyAdjustments/Authorization/Tabs";

interface Props {}

const AuthorizationPage: FunctionComponent<Props> = () => {
  const { t, i18n } = useTranslation();
  const navigator = useNavigator();
  const { kitchenId } = navigator.params();
  const dispatch = useAppDispatch();

  const adjustmentsForReviewFromStore = useAppSelector(
    (state) => state.dailyAdjustments.forReview
  );
  const adjustmentsForValidationFromStore = useAppSelector(
    (state) => state.dailyAdjustments.forValidation
  );
  const adjustmentsAuthorizationRequestStatus = useAppSelector(
    (state) => state.dailyAdjustments.authorization.status
  );
  const kitchens = useAppSelector((state) => state.global.kitchens.data);

  const [adjustments, setAdjustments] = useState<AdjustmentRecord[]>([]);
  const [adjustmentsForReview, setAdjustmentsForReview] = useState<
    AdjustmentRecord[]
  >([]);
  const [adjustmentsForValidation, setAdjustmentsForValidation] = useState<
    AdjustmentRecord[]
  >([]);
  const [selected, setSelected] = useState<number[]>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [quantities, setQuantities] = useState<AdjustmentQuantities | null>(
    null
  );
  const [selectedOption, setSelectedOption] = useState<AuthorizationOption>(
    authorizationOptions[0]
  );
  const [modal, setModal] = useState<CommonDialogContent>();
  const isForValidate = selectedOption.filterFor === "FOR_VALIDATION";
  const [adjustment, setAdjustment] = useState<AdjustmentRecord | null>(null);
  const [openDocumentList, setOpenDocumentList] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [finishedProducts, setFinishedProducts] = useState<Product[]>([]);
  const [selectedFinishedProduct, setSelectedFinishedProduct] =
    useState<Product>();
  const [selectedAdjustment, setSelectedAdjustment] =
    useState<AdjustmentRecord | null>();

  useEffect(() => {
    setAdjustmentsForReview(
      refinedAdjustments(adjustmentsForReviewFromStore.data)
    );
  }, [adjustmentsForReviewFromStore.data]);

  useEffect(() => {
    setAdjustmentsForValidation(
      refinedAdjustments(adjustmentsForValidationFromStore.data)
    );
  }, [adjustmentsForValidationFromStore.data]);

  useEffect(() => {
    setAdjustments(
      isForValidate
        ? adjustmentsForValidation
        : adjustmentsForReview?.filter((adjustment) =>
            selectedOption.filterFor !== "NOTHING"
              ? adjustment.typeLoss === selectedOption.type
              : true
          )
    );
    setSelected([]);
  }, [adjustmentsForReview, adjustmentsForValidation, selectedOption]);

  useEffect(() => {
    if (kitchenId === undefined) {
      return;
    }
    const finishedProductsSkus: string[] = [];
    const kitchen = kitchens.find((kitchen) => kitchen.kitchenId === kitchenId);

    adjustments.forEach((adjustment) => {
      if (adjustment.isFinishedProduct) {
        finishedProductsSkus.push(adjustment.sku);
      }
    });

    if (finishedProductsSkus.length > 0 && kitchen) {
      getFinishedProductsByCountryAndSkus(
        kitchen.country,
        finishedProductsSkus
      ).then((res) => {
        setFinishedProducts(res);
      });
    }
  }, [kitchenId, adjustments]);

  const getAdjustments = useCallback(() => {
    setSelected([]);
    if (kitchenId === undefined) {
      return;
    }
    dispatch(
      getAdjustmentsByKitchenForReview({
        kitchenId,
      })
    );
  }, [kitchenId]);

  const toggleAll = useCallback(() => {
    if (
      selected.length ===
      adjustments.filter((adjusment) => !adjusment.isFinishedProduct)?.length
    ) {
      setSelected([]);
      return;
    }

    if (adjustments === null) {
      return;
    }

    setSelected(
      adjustments
        .filter((adjusment) => !adjusment.isFinishedProduct)
        .map((adjustment) => adjustment.id)
    );
  }, [adjustments, selected]);

  const toggle = useCallback(
    (adjustmentId?: number) => {
      if (!adjustmentId) {
        toggleAll();
        return;
      }
      if (selected.includes(adjustmentId)) {
        setSelected((prevState) =>
          prevState.filter((prevSelected) => prevSelected !== adjustmentId)
        );

        return;
      }

      setSelected((prevState) => [...prevState, adjustmentId]);
    },
    [selected]
  );

  const setQuantity = useCallback((adjustmentId: number, quantity: number) => {
    setQuantities((prevState) => ({ ...prevState, [adjustmentId]: quantity }));
  }, []);

  const saveAuthorize = async () => {
    if (quantities === null) {
      return;
    }
    const adjustmentsQuantitiesArray: AuthorizeAdjustmentsPayload[] =
      Object.keys(quantities)
        .filter((key) => selected.includes(Number(key)))
        .map((key) => {
          const adjustmentId = Number(key);
          const adjustment = adjustments?.find(
            (item) => item.id === adjustmentId
          );
          const quantity = quantities[adjustmentId];
          return {
            id: adjustmentId,
            quantity,
            kitchenId: kitchenId ?? "",
            sku: adjustment?.sku ?? "",
            type: adjustment?.typeLoss ?? "",
            documentDate: formatDate(adjustment?.createdAt),
            documentCode: "",
            entryType: EntryType.NEGATIVE,
          } as AuthorizeAdjustmentsPayload;
        });

    await dispatch(
      authorizeAdjustments({
        adjustments: adjustmentsQuantitiesArray,
      })
    );
  };

  const saveValidation = async () => {
    if (quantities === null) {
      return;
    }
    const manualAdjustments: ManualAdjustment[] = Object.keys(quantities)
      .filter((key) => selected.includes(Number(key)))
      .map((key) => {
        const adjustmentId = Number(key);
        const adjustment = adjustments?.find(
          (item) => item.id === adjustmentId
        );
        const quantityTmp = quantities[adjustmentId];
        const quantity = isForValidate
          ? adjustment?.quantity ?? 0
          : quantityTmp;
        const quantityDiff = quantity - quantityTmp;
        const now = new Date();
        return {
          id: adjustmentId,
          quantity: Math.abs(quantityDiff),
          quantityAfterValidation: quantityTmp,
          sku: adjustment?.sku ?? "",
          kitchenId: kitchenId ?? "",
          entryType: quantityDiff < 0 ? EntryType.NEGATIVE : EntryType.POSITIVE,
          name: adjustment?.name,
          unit: adjustment?.unit,
          documentCode: `VAL-${adjustmentId}`,
          key: now.toTimeString(),
        } as ManualAdjustment;
      });

    await dispatch(
      validateAdjustments({
        adjustments: manualAdjustments,
      })
    );
  };

  const authorizeMovements = useCallback(async () => {
    if (quantities === null) {
      return;
    }

    setOpenDialog(false);

    if (isForValidate) {
      await saveValidation();
    } else {
      await saveAuthorize();
    }

    getAdjustments();
    setSelected([]);
  }, [quantities, selected]);

  const formatDate = (date?: Date) => {
    if (date) {
      return dayjs(date).format("YYYY-MM-DD");
    }
    return "";
  };

  const archiveMovements = useCallback(() => {
    if (quantities === null) {
      return;
    }

    setOpenDialog(false);

    dispatch(
      archiveAdjustments({
        adjustments: selected,
      })
    );

    setSelected([]);
  }, [quantities, selected]);

  useEffect(() => {
    localizeDayjs(i18n.language);
  }, [i18n.language]);

  const handleOpenProductDrawer = (adjustment: AdjustmentRecord) => {
    const finishedProduct = finishedProducts.find(
      (product) => product.sku === adjustment.sku
    );
    if (finishedProduct) {
      setSelectedAdjustment(adjustment);
      setSelectedFinishedProduct(finishedProduct);
      setOpenDrawer(true);
    }
  };
  useEffect(() => {
    if (adjustments === null) {
      return;
    }

    const adjustmentQuantities: AdjustmentQuantities = {};

    adjustments.forEach((adjustment) => {
      adjustmentQuantities[adjustment.id] = adjustment.quantity;
    });

    setQuantities(adjustmentQuantities);
  }, [adjustments]);

  useMount(() => {
    getAdjustments();
  });

  return (
    <PageBox>
      <PageHeader
        title={t(kitchenInventoryManagement.AUTHORIZATION_INVENTORY_TITLE)}
        subtitle={t(
          kitchenInventoryManagement.AUTHORIZATION_INVENTORY_DESCRIPTION
        )}
        rightArea={[
          {
            children: t(commons.UPDATE),
            variant: "contained",
            size: "large",
            startIcon: <FactCheckOutlinedIcon />,
            color: "inherit",
            sx: { bgcolor: "white" },
            onClick: getAdjustments,
            disabled: adjustmentsForReviewFromStore.status === "loading",
          },
          {
            children: t(isForValidate ? commons.VALIDATE : commons.AUTHORIZE),
            variant: "contained",
            size: "large",
            startIcon: <FactCheckOutlinedIcon />,
            onClick: () => {
              let title = t(dailyAdjustmentsModule.MOVEMENTS_AUTHORIZATION);
              let message = t(
                selected.length === 1
                  ? dailyAdjustmentsModule.AUTHORIZE_CONFIRM_MESSAGE
                  : dailyAdjustmentsModule.AUTHORIZE_CONFIRM_MESSAGE_PLURAL,
                {
                  supplyQuantity: selected.length,
                }
              );
              if (isForValidate) {
                title = t(dailyAdjustmentsModule.AUTHORIZE_VALIDATE_TITLE);
                message = t(
                  selected.length === 1
                    ? dailyAdjustmentsModule.AUTHORIZE_VALIDATE_MESSAGE
                    : dailyAdjustmentsModule.AUTHORIZE_VALIDATE_MESSAGE_PLURAL,
                  {
                    supplyQuantity: selected.length,
                  }
                );
              }
              setModal({
                title,
                message,
                icon: <WarningAmber color="warning" />,
                handleConfirm: authorizeMovements,
              });
              setOpenDialog(true);
            },
            disabled:
              adjustmentsForReviewFromStore.status === "loading" ||
              selected.length === 0 ||
              adjustmentsAuthorizationRequestStatus === "loading",
          },
        ]}
      />

      <AuthorizationTabs
        adjustmentsForReview={adjustmentsForReview}
        adjustmentsForValidation={adjustmentsForValidation}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        onClickDeleteButton={() => {
          setModal({
            title: t(dailyAdjustmentsModule.AUTHORIZE_DELETE_TITLE),
            message: t(
              selected.length === 1
                ? dailyAdjustmentsModule.AUTHORIZE_DELETE_MESSAGE
                : dailyAdjustmentsModule.AUTHORIZE_DELETE_MESSAGE_PLURAL,
              {
                supplyQuantity: selected.length,
              }
            ),
            icon: <DeleteOutlinedIcon color="error" sx={{ fontSize: 96 }} />,
            handleConfirm: archiveMovements,
          });
          setOpenDialog(true);
        }}
        disabledDeleteButton={
          adjustmentsForReviewFromStore.status === "loading" ||
          selected.length === 0 ||
          adjustmentsAuthorizationRequestStatus === "loading" ||
          isForValidate
        }
      />

      <Box sx={{ mt: 1 }}>
        <AuthorizationTable
          adjustments={adjustments}
          loadding={
            adjustmentsForReviewFromStore.status === "loading" ||
            adjustmentsAuthorizationRequestStatus === "loading"
          }
          quantities={quantities}
          setQuantity={setQuantity}
          selected={selected}
          setAdjustment={setAdjustment}
          toggle={toggle}
          setOpenDocumentList={setOpenDocumentList}
          handleOpenProductDrawer={handleOpenProductDrawer}
        />
        {openDocumentList && (
          <DocumentsViewer
            sourceId={adjustment?.id.toString() ?? ""}
            origin={OriginType.KIS}
            type={adjustment?.typeLoss ?? ""}
            onClose={() => setOpenDocumentList(false)}
            kitchenId={adjustment?.kitchenId ?? ""}
          />
        )}
        <CommonDialog
          handleClose={() => setOpenDialog(false)}
          handleConfirm={() => {
            modal?.handleConfirm
              ? modal?.handleConfirm()
              : setOpenDialog(false);
          }}
          open={openDialog}
          title={modal?.title ?? ""}
          message={modal?.message ?? ""}
          icon={modal?.icon ?? <></>}
          showCancelButton={true}
        />
      </Box>
      {selectedFinishedProduct && selectedAdjustment && kitchenId && (
        <FinishedProductDrawer
          adjustment={selectedAdjustment}
          product={selectedFinishedProduct}
          kitchenId={kitchenId}
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          getAdjusments={getAdjustments}
        />
      )}
    </PageBox>
  );
};

export default AuthorizationPage;
