import { FoodologyCountry } from "config/accuracy";
import { AppConfig } from "config/app";
import { getCompletedPercent } from "core/common/utils/percents";
import { unitShortNameToUnitName } from "core/common/utils/unitShortNameToUnitName";
import { CountingKitchenExportDetail } from "core/physicalCount/entities/Counting";
import { Group, GroupResponse } from "core/physicalCount/entities/Group";
import {
  Product,
  ProductHistoryItem,
} from "core/physicalCount/entities/Product";
import { CanAddItemCount } from "core/physicalCount/interfaces/product/CanAddItemCount";
import { CanAddScannedItemCount } from "core/physicalCount/interfaces/product/CanAddScannedItemCount";
import { CanGetItemCountHistory } from "core/physicalCount/interfaces/product/canGetItemHistory";
import { CanGetProductsForKitchen } from "core/physicalCount/interfaces/product/canGetProductsForKitchen";
import { CanSetProductActive } from "core/physicalCount/interfaces/product/canSetProductActive";
import { ProductionScannedtResponse } from "core/productions/entities/Productions";
import { ApiVersion, getHttp, postHttp } from "utils/http";

type ProductForKitchen = {
  _id: string;
  sku: string;
  name: string;
  unit: string;
  group: string;
  combo: string | null;
  category: string;
  station: string | null;
  country: FoodologyCountry;
  active: boolean;
  location: string | null;
  quantity: number;
  comment: string;
  validationMessage: string;
  status: "IDLE" | "IN_PROCESS" | "FINISHED";
  userId?: string;
};

type ProductsForKitchenResponse = {
  [groupName: string]: Array<ProductForKitchen>;
};

type CountingResponse = {
  id: number;
  type: string;
  kitchen: string;
  date: string;
  user: string;
  comment: string;
  countingDetails: ProductsForKitchenResponse;
  status: string;
};

type CountItemHistoryResponse = {
  id: number;
  type: "DIRECT" | "SUM";
  countingId: number;
  sku: string;
  quantity: number;
  createdAt: string;
};

export class ProductHTTPRepository
  implements
    CanGetProductsForKitchen,
    CanSetProductActive,
    CanGetItemCountHistory,
    CanAddItemCount,
    CanAddScannedItemCount
{
  constructor(private readonly config: AppConfig) {}

  public async addItemCount(
    countingId: string,
    sku: string,
    unit: string,
    quantity: number,
    comments: string,
    status: string
  ): Promise<number> {
    const response = await postHttp(
      this.config.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `counting/bySum`,
      {
        countingId,
        sku,
        unit,
        quantity,
        comments: comments ?? null,
        status,
      }
    );
    const responseJson = await response.json();
    return responseJson.sum;
  }

  public async addScannedItemCount(
    countingId: string,
    productionLabelId: string
  ): Promise<ProductionScannedtResponse> {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `counting/by-barcode-scanner`,
        {
          countingId,
          productionLabelId,
        }
      );
      const responseJson: ProductionScannedtResponse = await response.json();
      return responseJson;
    } catch (err) {
      console.error("addScannedItemCount error", err);
      throw err;
    }
  }

  public async getItemCountHistory(
    countingId: string,
    sku: string
  ): Promise<Array<ProductHistoryItem>> {
    try {
      const response = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `counting/${countingId}/history/sku/${sku}`
      );
      const responseJson: Array<CountItemHistoryResponse> =
        await response.json();
      const countHistory: Array<ProductHistoryItem> = responseJson.map(
        (countHistory) => ({
          id: countHistory.id.toString(),
          type: countHistory.type,
          countingId: countHistory.countingId,
          sku: countHistory.sku,
          quantity: countHistory.quantity,
          createdAt: countHistory.createdAt,
        })
      );

      return countHistory;
    } catch (err) {
      console.error("getProductForKitchen error", err);
      throw err;
    }
  }

  public async setProductActive(
    sku: string,
    kitchenId: string,
    active: boolean
  ): Promise<boolean> {
    const response = await postHttp(
      this.config.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `product/active`,
      {
        sku,
        kitchenId,
        comments: "",
        state: active,
      }
    );
    const setProductInactiveText = await response.text();
    if (setProductInactiveText !== "true") {
      return false;
    }
    return true;
  }

  public async getProductForKitchen(
    countingId: string
  ): Promise<GroupResponse> {
    try {
      const response = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `counting/by-id/${countingId}`
      );
      const responseJson: CountingResponse = await response.json();

      const groups: Array<Group> = [];

      Object.keys(responseJson.countingDetails).forEach((groupName: string) => {
        const group: Group = {
          groupName,
          groupItems: responseJson.countingDetails[groupName].map(
            productsForKitchenToProductMapper
          ),
          completed: getCompletedPercent(
            responseJson.countingDetails[groupName].filter(
              (product) => product.status === "FINISHED"
            ).length,
            responseJson.countingDetails[groupName].length
          ),
        };

        groups.push(group);
      });

      return {
        countingId: responseJson.id,
        groups,
        countingStatus: responseJson.status,
      };
    } catch (err) {
      console.error("getProductForKitchen error", err);
      throw err;
    }
  }

  public async getCountingDetailExport(
    countingId: string
  ): Promise<Array<CountingKitchenExportDetail>> {
    try {
      const response = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `counting/by-id/${countingId}`
      );

      const responseJson: CountingResponse = await response.json();

      const detail: Array<CountingKitchenExportDetail> = [];

      Object.keys(responseJson.countingDetails).forEach((group) => {
        responseJson.countingDetails[group].forEach((product) => {
          detail.push({
            sku: product.sku,
            productName: product.name,
            quantity: product.quantity?.toString(),
          });
        });
      });

      return detail;
    } catch (err) {
      console.error("getProductForKitchen error", err);
      throw err;
    }
  }
}

export function productsForKitchenToProductMapper(
  productForKitchen: ProductForKitchen
): Product {
  const product: Product = {
    image: null,
    name: productForKitchen.name,
    quantity: productForKitchen.quantity,
    unitName: unitShortNameToUnitName(productForKitchen.unit),
    unitShortName: productForKitchen.unit,
    SKU: productForKitchen.sku,
    available: productForKitchen.active,
    status: productForKitchen.status,
    state: {
      completed: productForKitchen.quantity !== null,
      status: "idle",
      error: productForKitchen.validationMessage || null,
    },
    history: {
      status: "idle",
      items: null,
      error: null,
    },
    active: productForKitchen.active,
    userId: productForKitchen.userId,
  };
  return product;
}
