import {
  Box,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import ListHeader from "app/components/ListHeader/ListHeader";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { commons, physicalCountSchedule } from "app/i18n/types";
import { getSuppliesByCountry } from "app/store/slices/supplies/thunks";
import { Supply, SupplyByGroup } from "core/supplies/entities/Supply";
import React, { FunctionComponent, memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce, useMount } from "react-use";
import {
  PhysicalCountScheduleLocation,
  clearSpecialCharacters,
  obtainSuppliesByGroups,
} from "utils/general";
import { AlertDefault } from "utils/generalUI";
import SupplyStepTable from "./Table";

interface SupplyStepProps {
  locations: PhysicalCountScheduleLocation[];
  selectedSupplies: Supply[];
  setSelectedSupplies: (selectedSupplies: Supply[]) => void;
}

const SupplyStep: FunctionComponent<SupplyStepProps> = (props) => {
  const { locations, selectedSupplies, setSelectedSupplies } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [supplies, setSupplies] = React.useState<Supply[]>([]);
  const [suppliesByGroup, setSuppliesByGroup] = React.useState<SupplyByGroup[]>(
    []
  );
  const [checkedSupplies, setCheckedSupplies] = React.useState<string[]>([]);
  const [searchSupplyInput, setSearchSupplyInput] = React.useState<string>("");
  const [searchSupply, setSearchSupply] = React.useState<string>("");
  const [open, setOpen] = React.useState<string[]>([]);

  const toggleOpen = React.useCallback(
    (id: string) => {
      if (open.includes(id)) {
        setOpen(open.filter((el) => el !== id));
      } else {
        setOpen([...open, id]);
      }
    },
    [open]
  );

  const setSelectedSuppliesTmp = (checkedSuppliesTmp: string[]) => {
    const selectedSuppliesTmp = checkedSuppliesTmp.map((sku) => {
      const supply = supplies.find((el) => el.sku === sku) as Supply;
      return supply;
    });
    setSelectedSupplies(selectedSuppliesTmp);
  };

  const toggleChecked = React.useCallback(
    (sku: string) => {
      if (checkedSupplies.includes(sku)) {
        setCheckedSupplies((prevState) => {
          const checkedSuppliesTmp = prevState.filter(
            (prevSelected) => prevSelected !== sku
          );
          setSelectedSuppliesTmp(checkedSuppliesTmp);
          return checkedSuppliesTmp;
        });
        return;
      }

      setCheckedSupplies((prevState) => {
        const checkedSuppliesTmp = [...prevState, sku];
        setSelectedSuppliesTmp(checkedSuppliesTmp);
        return checkedSuppliesTmp;
      });
    },
    [checkedSupplies, supplies]
  );

  const toggleSelectAll = React.useCallback(
    (skus: string[]) => {
      if (skus.every((sku) => checkedSupplies.includes(sku))) {
        setCheckedSupplies((current) => {
          const checkedSuppliesTmp = current.filter(
            (sku) => !skus.includes(sku)
          );
          setSelectedSuppliesTmp(checkedSuppliesTmp);
          return checkedSuppliesTmp;
        });
        return;
      }
      if (skus.some((sku) => checkedSupplies.includes(sku))) {
        setCheckedSupplies((current) => {
          const filtered = current.filter((sku) => !skus.includes(sku));
          const checkedSuppliesTmp = [...filtered, ...skus];
          setSelectedSuppliesTmp(checkedSuppliesTmp);
          return checkedSuppliesTmp;
        });
        return;
      }
      setCheckedSupplies((current) => {
        const checkedSuppliesTmp = [...current, ...skus];
        setSelectedSuppliesTmp(checkedSuppliesTmp);
        return checkedSuppliesTmp;
      });
    },
    [checkedSupplies, supplies]
  );

  const getData = async () => {
    const countryStr = locations.length ? locations[0].country : "COL";
    const result = await dispatch(getSuppliesByCountry(countryStr));
    const optionsTmp = result.payload as Supply[];
    setSupplies(optionsTmp);
  };

  useMount(() => {
    getData();
    setCheckedSupplies(selectedSupplies.map((supply) => supply.sku));
  });

  useEffect(() => {
    if (supplies.length) {
      const suppliesByGroupTmp: SupplyByGroup[] = [];
      const search = clearSpecialCharacters(searchSupply).toLocaleLowerCase();
      const suppliesTmp = searchSupply
        ? supplies.filter((el) => {
            const sku = clearSpecialCharacters(el.sku).toLocaleLowerCase();
            const name = clearSpecialCharacters(el.name).toLocaleLowerCase();
            return sku.includes(search) || name.includes(search);
          })
        : supplies;
      obtainSuppliesByGroups(suppliesTmp, "", suppliesByGroupTmp);
      setSuppliesByGroup(suppliesByGroupTmp);
    }
  }, [supplies, searchSupply]);

  useDebounce(
    () => {
      setSearchSupply(searchSupplyInput);
    },
    1000,
    [searchSupplyInput]
  );

  return (
    <Grid container spacing={2}>
      <Grid item sm={3} xs={12}>
        <Card>
          <CardContent sx={{ padding: "16px !important" }}>
            <Typography
              variant="body1"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <strong>{checkedSupplies.length}</strong>
              &nbsp;{t(commons.SELECTED)}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={6} xs={12}></Grid>
      <Grid item sm={3} xs={12}>
        <TextField
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
          }}
          label={t(commons.FIND_INPUT)}
          type="search"
          onChange={(event) => {
            setSearchSupplyInput(event.target.value);
          }}
          inputProps={{ style: { height: "100%" } }}
          value={searchSupplyInput}
          autoComplete="off"
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        {!suppliesByGroup.length && (
          <AlertDefault message={t(physicalCountSchedule.STEP_4_TABLE_EMPTY)} />
        )}
        {suppliesByGroup.map((option) => (
          <Box key={`SupplyStepGroup${option.group}`} mb={1}>
            <ListHeader
              label={option.group}
              count={option.supplies.length}
              selected={
                option.supplies.filter((supply) =>
                  checkedSupplies.includes(supply.sku)
                ).length
              }
              onClick={() => toggleOpen(option.group)}
              open={open.includes(option.group) || !!searchSupply}
              sticky
            />

            {(open.includes(option.group) || !!searchSupply) && (
              <Box px={0.25}>
                <SupplyStepTable
                  supplies={option.supplies}
                  checkedSupplies={checkedSupplies}
                  toggleChecked={toggleChecked}
                  toggleSelectAll={toggleSelectAll}
                />
              </Box>
            )}
          </Box>
        ))}
      </Grid>
    </Grid>
  );
};

export default memo(SupplyStep);
