import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { Grid } from "@mui/material";
import PageBox from "app/components/PageBox";
import PageHeader from "app/components/PageHeader";
import VendorBySkuAndCityTable from "app/components/Vendor/Supply/BySkuAndCity/Table";
import { VendorSupplyManagerDialog } from "app/components/Vendor/Supply/Manager";
import SearchOnTopBar from "app/components/common/SearchOnTopBar";
import { useNavigator } from "app/hooks/useNavigator";
import { commons, vendor } from "app/i18n/types";
import { VendorJoinWithSupply } from "core/vendors/entities/Vendor";
import { CreateOrModifyDataResponse } from "core/vendors/entities/VendorSupply";
import { bySkuAndCity } from "core/vendors/repositories/http/vendor";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { compareSearchText } from "utils/general";
import { Actions, VendorSupplyModal } from "utils/modal";
import VendorBySkuAndCityResume from "./Resume";

interface Props {}

const VendorBySkuAndCityPage = (props: Props) => {
  const { t } = useTranslation();
  const navigator = useNavigator();
  const { sku, city } = navigator.params();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<VendorJoinWithSupply[]>();
  const [vendorsJoinWithSupply, setVendorsJoinWithSupply] = useState<
    VendorJoinWithSupply[]
  >([]);
  const [search, setSearch] = useState<string>("");
  const [modal, setModal] = useState<VendorSupplyModal>({ open: false });

  const getData = () => {
    if (sku && city) {
      setLoading(true);
      bySkuAndCity(sku, city)
        .then((data) => {
          setData(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getData();
  }, [sku, city]);

  const searchValidator = (search: string, item: VendorJoinWithSupply) => {
    if (!search) return true;
    return (
      compareSearchText(item.vendor.name, search) ||
      compareSearchText(item.vendor.externalCode, search)
    );
  };

  useEffect(() => {
    setVendorsJoinWithSupply(
      data?.filter((item) => {
        return searchValidator(search, item);
      }) ?? []
    );
  }, [data, search]);

  const onSuccess = useCallback(
    (result: CreateOrModifyDataResponse) => {
      if (!data) return;
      if (modal.action === Actions.edit) {
        setData(
          data.map((el) => {
            const supply = result.refinedSupplies.find(
              (ref) => ref.id === el.supply.id
            );
            if (supply) {
              return { ...el, supply };
            }
            return el;
          })
        );
      }
    },
    [modal]
  );

  const firstData = data?.at(0);

  const vendorCount = useCallback(() => {
    const list = new Set(data?.map((el) => el.vendor.id));
    return list.size;
  }, [data]);

  const vendorUnitPriceMin = useCallback(() => {
    const list = data?.map((el) => el.supply.vendorUnitPrice) ?? [];
    return list.length ? Math.min(...list) : 0;
  }, [data]);

  const supplyUnitPriceMin = useCallback(() => {
    const list = data?.map((el) => el.supply.supplyUnitPrice) ?? [];
    return list.length ? Math.min(...list) : 0;
  }, [data]);

  return (
    <PageBox>
      <PageHeader
        title={vendor.SUPPLY_VENDORS_TITLE}
        rightArea={[
          {
            children: t(commons.UPDATE),
            variant: "outlined",
            startIcon: <RestartAltOutlinedIcon />,
            onClick: getData,
          },
        ]}
      />

      {!!data && (
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <SearchOnTopBar onSearch={setSearch} />
          {!!firstData && (
            <VendorBySkuAndCityResume
              data={firstData}
              vendorCount={vendorCount()}
              vendorUnitPriceMin={vendorUnitPriceMin()}
              supplyUnitPriceMin={supplyUnitPriceMin()}
            />
          )}
        </Grid>
      )}
      <VendorBySkuAndCityTable
        vendors={vendorsJoinWithSupply}
        loading={loading}
        setModal={setModal}
      />
      {modal.open && (
        <VendorSupplyManagerDialog
          {...modal}
          onClose={() => setModal({ open: false })}
          onSuccess={onSuccess}
        />
      )}
    </PageBox>
  );
};

export default VendorBySkuAndCityPage;
