import appConfig, { AppConfig } from "config/app";
import { Session } from "core/account/entities/Session";
import {
  AddManualAdjustmentResponse,
  ManualAdjustment,
} from "core/adjustments/entities/ManualAdjustment";
import {
  CountingAccuracyAndDetails,
  CountingExportDetail,
  CountingRecord,
  CountingResponse,
  CountingType,
  InventoryResponse,
  LastRecountResponse,
  MassiveDataToSent,
  RawCounting,
} from "core/physicalCount/entities/Counting";
import { CanCreateCounting } from "core/physicalCount/interfaces/counting/canCreateCounting";
import {
  CanFinishCounting,
  FinishCountingResult,
} from "core/physicalCount/interfaces/counting/canFinishCounting";
import { CanFinishItemCounting } from "core/physicalCount/interfaces/counting/canFinishItemCounting";
import { CanGetCountingDetailsByIds } from "core/physicalCount/interfaces/counting/canGetCountingDetailsByIds";
import { CanGetDetailsByCounting } from "core/physicalCount/interfaces/counting/canGetDetailsByCounting";
import { CanGetLastFinishedCountings } from "core/physicalCount/interfaces/counting/canGetLastFinishedCountings";
import {
  CanSetProduct,
  ProductCountResultDTO,
} from "core/physicalCount/interfaces/counting/canSetProductCount";
import { LocationType } from "utils/general";
import { ApiVersion, getHttp, postHttp } from "utils/http";
import { ValidationQuantityResponse } from "../../entities/ValidationQuantity";
import { CanGetValidateQuantity } from "../../interfaces/counting/canGetValidateQuantity";
import {
  CanUpdateScheduledCounting,
  UpdateScheduledCounting,
} from "../../interfaces/counting/canStartScheduledCounting";

type ProductCountResponse = ProductCountResultDTO;
type FinishCountingResponse = FinishCountingResult;

export class CountingHTTPRepository
  implements
    CanCreateCounting,
    CanSetProduct,
    CanFinishCounting,
    CanGetLastFinishedCountings,
    CanGetCountingDetailsByIds,
    CanFinishItemCounting,
    CanGetValidateQuantity,
    CanUpdateScheduledCounting,
    CanGetDetailsByCounting
{
  constructor(private readonly config: AppConfig) {}

  public async getCountingType(): Promise<CountingType[]> {
    const response = await getHttp(
      this.config.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      "counting/types"
    );
    const responseJSON: CountingType[] = await response.json();
    return responseJSON;
  }

  public async finishCounting(
    countingId: string
  ): Promise<FinishCountingResult> {
    const response = await getHttp(
      this.config.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `counting/${countingId}/finish`
    );
    const responseJSON: FinishCountingResponse = await response.json();
    return responseJSON;
  }

  public async setProductCount(
    countingId: number,
    sku: string,
    unit: string,
    quantity: number,
    comment: string,
    overwrite?: boolean
  ): Promise<ProductCountResultDTO> {
    const response = await postHttp(
      this.config.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `counting/item`,
      {
        countingId: countingId,
        sku: sku,
        unit: unit,
        quantity: quantity,
        comments: comment,
        status: "IN_PROCESS",
        userId: "",
        overwrite: overwrite,
      }
    );
    const responseJSON: ProductCountResponse = await response.json();
    return responseJSON;
  }

  public async createCounting(
    kitchenId: string,
    countingTypeCode: string,
    session: Session
  ) {
    const response = await postHttp(
      this.config.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `counting`,
      {
        id: null,
        countingTypeCode: countingTypeCode,
        kitchenId: kitchenId,
        userId: session.userId,
        comment: "",
      }
    );

    const responseJSON: CountingResponse = await response.json();

    const counting: CountingRecord = {
      id: responseJSON.id,
      countingType: responseJSON.type,
      kitchenId: responseJSON.kitchenId,
      status: responseJSON.status,
      // groups: responseJSON.countingDetails,
      comment: responseJSON.comment ?? "",
    };

    return counting;
  }

  public async createSchedulePhysicalCounting(
    countingTypeCode: string,
    name: string,
    kitchenIds: string[],
    dates: string[],
    supplies: string[],
    massiveDataToSent: MassiveDataToSent[]
  ) {
    const response = await postHttp(
      this.config.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `counting/by/type`,
      {
        countingTypeCode,
        kitchenIds,
        userId: "",
        dates,
        supplies: supplies.length ? supplies : null,
        massiveData: massiveDataToSent.length ? massiveDataToSent : null,
        comment: "",
        name,
      }
    );
    const responseJSON = await response.json();
    return responseJSON.status;
  }

  public async getCountingHistory(kitchenId: string) {
    try {
      const response = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `counting/kitchen/${kitchenId}`
      );
      const responseJSON: RawCounting[] = await response.json();
      return responseJSON;
    } catch (err) {
      console.error("getCountingHistory error", err);
      throw err;
    }
  }

  public async getCountingHistoryByStatus(
    kitchenId: string,
    status: "FINISHED" | "NOT_FINISHED"
  ) {
    try {
      const response = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `counting/kitchen/${kitchenId}/status/${status}`
      );
      const responseJSON: RawCounting[] = await response.json();
      return responseJSON;
    } catch (err) {
      console.error("getCountingHistoryByStatus error", err);
      throw err;
    }
  }

  public async getLastFinishedCountings(
    initDate: Date,
    endDate: Date
  ): Promise<RawCounting[]> {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `counting/between-dates`,
        { initDate, endDate }
      );
      const responseJSON: Array<RawCounting> = await response.json();
      return responseJSON;
    } catch (err) {
      console.error("getLastFinishedCountings error", err);
      throw err;
    }
  }

  public async getdetailsByIds(ids: number[]): Promise<CountingExportDetail[]> {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `counting/ids`,
        ids
      );
      const responseJSON: CountingExportDetail[] = await response.json();
      return responseJSON;
    } catch (err) {
      console.error("getdetailsByIds error", err);
      throw err;
    }
  }

  public async finishItemCounting(
    countingId: number,
    sku: string
  ): Promise<InventoryResponse> {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `counting/id/${countingId}/finish/sku/${sku}`
      );
      const responseJSON: InventoryResponse = await response.json();
      return responseJSON;
    } catch (err) {
      console.error("finishItemCounting error", err);
      throw err;
    }
  }

  public async validateQuantity(
    sku: string,
    kitchenId: string
  ): Promise<ValidationQuantityResponse> {
    const response = await getHttp(
      this.config.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `counting/validate/${kitchenId}/${sku}`
    );
    const responseJSON: ValidationQuantityResponse = await response.json();
    return responseJSON;
  }

  public async UpdateScheduledCounting(
    countingId: string,
    userId: string,
    kitchenId: string
  ): Promise<UpdateScheduledCounting> {
    const response = await postHttp(
      this.config.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `counting/scheduled/start`,
      {
        countingId: countingId,
        kitchenId: kitchenId,
        userId: userId,
      }
    );
    const responseJSON: UpdateScheduledCounting = await response.json();
    return responseJSON;
  }

  public async getDetailsByCountingId(
    countingId: number
  ): Promise<CountingAccuracyAndDetails> {
    try {
      const response = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `counting/details/${countingId}`
      );
      const responseJSON: CountingAccuracyAndDetails = await response.json();
      return responseJSON;
    } catch (err) {
      console.error("getDetailsByCountingId error", err);
      throw err;
    }
  }

  public async getCountingByLocation(
    locationType: LocationType,
    location: string
  ): Promise<CountingResponse[]> {
    try {
      const response = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `counting/location/${location}/type/${locationType}`
      );
      const responseJSON: CountingResponse[] = await response.json();
      return responseJSON;
    } catch (err) {
      console.error("getDetailsByCountingId error", err);
      throw err;
    }
  }

  public async archiveCounts(
    countingIds: number[]
  ): Promise<InventoryResponse> {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `counting/archive`,
        countingIds
      );
      const responseJSON: InventoryResponse = await response.json();
      return responseJSON;
    } catch (err) {
      console.error("archiveCounts error", err);
      throw err;
    }
  }
}

export const countingsForApprove = async (
  locationType: LocationType,
  location: string
): Promise<CountingResponse[]> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `counting/recount/location/${location}/type/${locationType}`
    );
    const responseJSON: CountingResponse[] = await response.json();
    return responseJSON;
  } catch (err) {
    console.error("countingsForApprove error", err);
    throw err;
  }
};

export const recountDetail = async (kitchenId: string, countingId: string) => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `counting/last-re-count/${kitchenId}/${countingId}`
    );
    const responseJSON: LastRecountResponse = await response.json();
    return responseJSON;
  } catch (err) {
    console.error("getCountingHistory error", err);
    throw err;
  }
};

export const validateCounting = async (
  countingId: string,
  adjustments: Array<ManualAdjustment>
): Promise<AddManualAdjustmentResponse> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `counting/id/${countingId}/validate-many`,
      adjustments
    );
    const responseJSON: AddManualAdjustmentResponse = await response.json();
    return responseJSON;
  } catch (err) {
    console.error("Error validate adjustments", err);
    throw err;
  }
};
