import {
  convertDateUtc,
  KOSDateRangePicker,
  KOSDateRangePickerState,
  KOSSelectedResult,
} from "@foodology-co/alejandria";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

import {
  Alert,
  Autocomplete,
  Grid,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { ManagerDialog } from "app/components/common/Manager/Dialog";
import { ManagerDialogFooter } from "app/components/common/Manager/Dialog/Footer";
import SearchOnTopBar from "app/components/common/SearchOnTopBar";
import KitchenSelector from "app/components/Kitchen/Selector";
import PageBox from "app/components/PageBox";
import PageHeader from "app/components/PageHeader/PageHeader";
import { PurchaseOrderInvoiceImportDetail } from "app/components/PurchaseOrder/Invoice/ImportDetail";
import { PurchaseOrderInvoiceListTable } from "app/components/PurchaseOrder/Invoice/ListTable";
import UploadArea from "app/components/UploadArea";
import VendorSelector from "app/components/Vendor/Selector";
import { commons, purchaseOrderModule } from "app/i18n/types";
import { convertToBase64 } from "app/utils/file";
import { AttachmentFile } from "core/attachment/entity";
import {
  InvoiceItem,
  NewInvoicePayload,
  PurchaseInvoice,
  VendorInfo,
  XmlInvoice,
} from "core/purchaseOrders/entities/Invoice";
import {
  importInvoice,
  invoicesByQuery,
  saveInvoice,
} from "core/purchaseOrders/repositories/http/purchase";
import { Vendor } from "core/vendors/entities/Vendor";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { compareSearchText } from "utils/general";
import writeXlsxFile from "write-excel-file";

interface Props {}

const PurchaseOrderInvoices: FunctionComponent<Props> = () => {
  const { t } = useTranslation();

  const [invoices, setInvoices] = useState<PurchaseInvoice[]>([]);
  const [filteredInvoices, setFilteredInvoices] = useState<PurchaseInvoice[]>(
    []
  );
  const [selectedItems, setSelectedItems] = useState<KOSSelectedResult[]>([]);
  const [reset, setReset] = useState<number>(new Date().getTime());

  const [search, setSearch] = useState<string>("");
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [selectedVendor, setSelectedVendor] = useState<Vendor>();
  const [xmlInvoice, setXmlInvoice] = useState<XmlInvoice | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingImport, setLoadingImport] = useState<boolean>(false);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [openImportDialog, setOpenImportDialog] = useState<boolean>(false);
  const [openNewInvoiceDialog, setOpenNewInvoiceDialog] =
    useState<boolean>(false);

  const [errorCode, setErrorCode] = useState<string | null>(null);
  const [vendorInfo, setVendorInfo] = useState<VendorInfo | null>(null);

  const [xml, setXml] = useState<AttachmentFile | null>(null);

  const [dateRange, setDateRange] = useState<KOSDateRangePickerState>();
  const [selected, setSelected] = useState<string | undefined>(undefined);

  const getData = () => {
    if (selectedCountry) {
      setLoading(true);
      setInvoices([]);

      invoicesByQuery(
        selectedCountry,
        selectedVendor?.externalCode ?? undefined,
        dateRange
          ? convertDateUtc(dateRange.startDate, "yyyy-MM-dd")
          : undefined,
        dateRange ? convertDateUtc(dateRange.endDate, "yyyy-MM-dd") : undefined,
        selected ? selected === "YES" : undefined
      )
        .then((response) => {
          setInvoices(response);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!selected) {
      setReset(new Date().getTime());
    }
    getData();
  }, [selectedCountry, selectedVendor, dateRange, selected]);

  const [resetUploadArea, setResetUploadArea] = useState<number>(
    new Date().getTime()
  );

  const onUpload = async (files: File[] | null) => {
    if (files) {
      const file = files[0];
      const { name, type } = file;
      const base64 = await convertToBase64(file);
      setXml({ name, type, base64, verified: false });

      setErrorCode(null);
      setXmlInvoice(null);
      setVendorInfo(null);
      setLoadingImport(true);

      importInvoice(selectedCountry, base64)
        .then((response) => {
          if (response.success) {
            setXmlInvoice(response.xml!);
            setVendorInfo(response.vendor!);
            setOpenImportDialog(false);
            setOpenNewInvoiceDialog(true);
          } else {
            setErrorCode(response.erroCode ?? "");
          }
        })
        .finally(() => {
          setLoadingImport(false);
        });
      setResetUploadArea(new Date().getTime());
    }
  };

  const onClose = () => {
    setOpenImportDialog(false);
    setOpenNewInvoiceDialog(false);
  };

  const saveAttachment = (payload: NewInvoicePayload) => {
    setLoadingSave(true);
    saveInvoice(selectedCountry, payload).finally(() => {
      setOpenNewInvoiceDialog(false);
      setLoadingSave(false);
      getData();
    });
  };

  const searchValidate = (
    invoice: PurchaseInvoice,
    toSearch: string
  ): boolean => {
    if (!toSearch) return true;
    return (
      compareSearchText(invoice.invoice, toSearch) ||
      compareSearchText(invoice.id, toSearch)
    );
  };

  useEffect(() => {
    setFilteredInvoices(
      invoices.filter((invoice) => {
        return searchValidate(invoice, search);
      })
    );
  }, [search, invoices, selected]);

  const exportInvoices = async () => {
    const excelData = [];
    const headerRow = [
      { value: "id", fontWeight: "bold" },
      { value: "factura", fontWeight: "bold" },
      { value: "fecha", fontWeight: "bold" },
      { value: "proveedor", fontWeight: "bold" },
      { value: "asociada", fontWeight: "bold" },
      { value: "subtotal", fontWeight: "bold" },
    ];
    excelData.push(headerRow);

    selectedItems.forEach((invoice) => {
      excelData.push([
        { type: String, value: invoice.id },
        { type: String, value: invoice.invoice },
        { type: String, value: invoice.issueDate },
        { type: String, value: invoice.vendorCode },
        { type: String, value: invoice.synchronized ? "SI" : "NO" },
        { type: Number, value: invoice.subtotal },
      ]);
    });

    downloadExcel(excelData, "invoices").catch((err) => {
      console.error("Error in downloadExcel", err);
    });
  };

  const exportInvoicesDetail = async () => {
    const excelData = [];
    const headerRow = [
      { value: "Id", fontWeight: "bold" },
      { value: "Factura", fontWeight: "bold" },
      { value: "Fecha", fontWeight: "bold" },
      { value: "Proveedor", fontWeight: "bold" },
      { value: "Item", fontWeight: "bold" },
      { value: "Cantidad", fontWeight: "bold" },
      { value: "subtotal", fontWeight: "bold" },
    ];
    excelData.push(headerRow);

    selectedItems.forEach((invoice) => {
      invoice.items.forEach((item: InvoiceItem) => {
        excelData.push([
          { type: String, value: invoice.id },
          { type: String, value: invoice.invoice },
          { type: String, value: invoice.issueDate },
          { type: String, value: invoice.vendorCode },
          { type: String, value: item.description },
          { type: Number, value: item.invoicedQuantity },
          { type: Number, value: item.subtotal },
        ]);
      });
    });

    downloadExcel(excelData, "invoiceDetails").catch((err) => {
      console.error("Error in downloadExcel", err);
    });
  };

  const downloadExcel = async (data: any, name: string) => {
    await writeXlsxFile(data, {
      fileName: `${name}.xlsx`,
    });
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const options: string[] = ["YES", "NO"];

  return (
    <PageBox>
      <PageHeader
        title={t(purchaseOrderModule.INVOICE_TITLE)}
        rightArea={[
          {
            children: t(commons.UPDATE),
            variant: "contained",
            startIcon: <RestartAltOutlinedIcon />,
            onClick: getData,
          },
          {
            children: t(commons.EXPORT),
            variant: "outlined",
            onClick: openMenu,
            disabled: !selectedItems.length,
            startIcon: <FileDownloadOutlinedIcon />,
          },
          {
            children: t(commons.IMPORT),
            variant: "outlined",
            onClick: () => setOpenImportDialog(true),
            disabled: !selectedCountry,
            startIcon: <FileDownloadOutlinedIcon />,
          },
        ]}
      />

      <Menu
        id="download-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={exportInvoices}>
          {t(purchaseOrderModule.INVOICE_REPORT)}
        </MenuItem>
        <MenuItem onClick={exportInvoicesDetail}>
          {t(purchaseOrderModule.INVOICE_REPORT_DETAILED)}
        </MenuItem>
      </Menu>

      <SearchOnTopBar onSearch={setSearch} />

      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t(purchaseOrderModule.FILTER_INVOICES)}
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={2} xs={12}>
                <KitchenSelector
                  selected={{
                    country: selectedCountry,
                  }}
                  onChange={{
                    country: (value) => setSelectedCountry(value?.code ?? ""),
                  }}
                  hide={{
                    city: true,
                    kitchen: true,
                  }}
                  extra={{ showHash: true, disabled: loading }}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <VendorSelector
                  country={selectedCountry ?? ""}
                  selectedVendor={selectedVendor}
                  setSelectedVender={setSelectedVendor}
                  disabled={!selectedCountry}
                  isShortTitle
                />
              </Grid>
              <Grid item sm={3}>
                <KOSDateRangePicker
                  fullWidth
                  type="input"
                  defaultValue="empty"
                  onChange={setDateRange}
                />
              </Grid>
              <Grid item sm={3}>
                <Autocomplete
                  key={reset}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t(commons.LINKED)}
                      sx={{ bgcolor: "white" }}
                    />
                  )}
                  value={selected}
                  options={options}
                  getOptionLabel={(option) => t(`common.${option}`)}
                  onChange={(_, value) => {
                    setSelected(value ?? undefined);
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <PurchaseOrderInvoiceListTable
        invoices={filteredInvoices}
        loading={loading}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        onUpdate={getData}
      />

      <ManagerDialog
        title={t(purchaseOrderModule.IMPORT_TITLE)}
        open={openImportDialog}
        onClose={onClose}
        content={
          <Grid container spacing={2}>
            {loadingImport && (
              <Grid item xs={12}>
                <LinearProgress></LinearProgress>
              </Grid>
            )}

            {errorCode && (
              <Grid item xs={12}>
                <Alert severity="error">{t("error." + errorCode)}</Alert>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sx={{
                justifyContent: "center",
                textAlign: "center",
                display: "flex",
              }}
            >
              <UploadArea
                key={resetUploadArea}
                accept=".xml"
                onUpload={onUpload}
                label={commons.SELECT_MOVE_FILE}
                maxSizeInMb={5}
                autoUpload
              />
            </Grid>

            <Grid item xs={12}>
              <ManagerDialogFooter onCancel={onClose} loading={false} />
            </Grid>
          </Grid>
        }
      />

      {xmlInvoice && xml && vendorInfo && (
        <PurchaseOrderInvoiceImportDetail
          xml={xml}
          onClose={() => setOpenNewInvoiceDialog(false)}
          loading={loadingSave}
          open={openNewInvoiceDialog}
          xmlInvoice={xmlInvoice}
          vendorInfo={vendorInfo}
          saveInvoice={saveAttachment}
        />
      )}
    </PageBox>
  );
};

export default PurchaseOrderInvoices;
