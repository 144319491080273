import { KOSBaseTableHeader, KOSRowData } from "@foodology-co/alejandria";
import BulkLoad from "app/components/common/BulkLoad";
import {
  isErrorToRequiredColumns,
  rowToNumberOrEmpty,
  rowToString,
  rowToStringOrEmpty,
} from "app/components/common/BulkLoad/util";
import { useAlert } from "app/hooks/useAlert";
import { bulkLoadModule, commons, vendor } from "app/i18n/types";
import { excelToJson } from "app/utils/excel";
import {
  BulkLoadActionType,
  BulkLoadError,
  BulkLoadLineSave,
  BulkLoadLineValidator,
  BulkLoadVendorSupplyValidator,
} from "core/bulkLoad/entities/BulkLoad";
import {
  bulkLoadVendorSupplySave,
  bulkLoadVendorSupplyValidator,
} from "core/bulkLoad/repositories/http/bulkLoad";
import { VendorSupply, VendorType } from "core/vendors/entities/VendorSupply";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row } from "read-excel-file/types";
import { replaceLineBreak } from "utils/general";
import { numberWithDecimalsFormat } from "utils/number";

interface Props {
  onLoading: (loading: boolean) => void;
}

const VendorSupplyBulkLoad = (props: Props) => {
  const { onLoading } = props;
  const { t } = useTranslation();
  const alert = useAlert();

  const requiredColumns = 4;
  const [loading, setLoading] = useState<boolean>(false);
  const [responseValidator, setResponseValidator] =
    useState<BulkLoadVendorSupplyValidator[]>();
  const [responseSave, setResponseSave] = useState<BulkLoadLineSave[]>();
  const [fileErrors, setFileErrors] = useState<BulkLoadError[]>([]);
  const [actions, setActions] = useState<BulkLoadLineValidator[]>([]);

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (responseValidator?.length) {
      setActions(
        responseValidator.map((line) => ({
          index: line.index,
          action: line.action,
        }))
      );
    }
  }, [responseValidator]);

  const onValidator = async (files: File[] | null) => {
    setLoading(true);
    setFileErrors([]);
    if (!files) return;

    const fileRows = await Promise.all(
      files.map((file) =>
        excelToJson(file, {
          skipRows: 1,
        })
      )
    );
    const rows: Row[] = [];

    const errors: BulkLoadError[] = [];

    fileRows.flat().forEach((row, index) => {
      const error = isErrorToRequiredColumns(row, requiredColumns);
      if (error) {
        errors.push({
          index,
          content: t(bulkLoadModule.REQUIRED_COLUMNS, { num: requiredColumns }),
        });
      }

      rows.push(replaceLineBreak(row));
    });

    setFileErrors(errors);

    const records: Partial<VendorSupply>[] = rows.map((row) => {
      return {
        vendorExternalCode: rowToString(row, 0),
        city: rowToString(row, 1),
        supplySku: rowToString(row, 2),
        vendorType: rowToString(row, 3) as VendorType,
        vendorSupplyName: rowToStringOrEmpty(row, 4),
        supplyUnitPrice: rowToNumberOrEmpty(row, 5),
        vendorUnit: rowToStringOrEmpty(row, 6),
        supplyQuantity: rowToNumberOrEmpty(row, 7),
        vendorMinQuantityToOrder: rowToNumberOrEmpty(row, 8),
        vendorOrdersPerWeek: rowToNumberOrEmpty(row, 9),
        leadTime: rowToNumberOrEmpty(row, 10),
      };
    });

    const response = await bulkLoadVendorSupplyValidator(records);
    response.ok && response.data && setResponseValidator(response.data);
    setLoading(false);
  };

  const getHeaders = (): KOSBaseTableHeader[] => {
    return [
      {
        label: t(commons.CODE),
        field: "vendorExternalCode",
      },
      {
        label: t(commons.CITY),
        field: "city",
      },
      {
        label: t(commons.SKU),
        field: "supplySku",
      },
      {
        label: t(commons.TYPE),
        field: "vendorType",
        align: "center",
      },
      {
        label: t(vendor.VENDOR_SUPPLY_NAME),
        field: "vendorSupplyName",
      },
      {
        label: t(vendor.SUPPLY_UNIT_PRICE),
        align: "right",
        text: (item: KOSRowData) => {
          if (!item.supplyUnitPrice) return undefined;
          return numberWithDecimalsFormat(String(item.supplyUnitPrice), 5);
        },
      },
      {
        label: t(vendor.VENDOR_UNIT),
        field: "vendorUnit",
        align: "center",
      },
      {
        label: t(vendor.SUPPLY_QUANTITY),
        field: "supplyQuantity",
        align: "right",
      },
      {
        label: t(vendor.VENDOR_MIN_ORDER_QUANTITY),
        field: "vendorMinQuantityToOrder",
        align: "right",
      },
      {
        label: t(vendor.VENDOR_ORDER_PER_WEEK),
        field: "vendorOrdersPerWeek",
        align: "right",
      },
      {
        label: t(vendor.LEAD_TIME),
        field: "leadTime",
        align: "right",
      },
    ];
  };

  const onSave = useCallback(() => {
    if (!responseValidator?.length) return;
    setLoading(true);
    const data = responseValidator.map((line) => {
      const currentAction = actions.find((el) => el.index === line.index);
      return { ...line, action: currentAction?.action ?? line.action };
    });
    bulkLoadVendorSupplySave(data)
      .then((response) => {
        if (response.ok) {
          alert.success();
        } else {
          alert.error();
        }
        setResponseValidator(data);
        response.data && setResponseSave(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [responseValidator, actions]);

  const onReset = () => {
    setResponseValidator(undefined);
    setResponseSave(undefined);
  };

  const onChangeAction = (index: number, newAction: BulkLoadActionType) => {
    setActions((prev) =>
      prev.map((action) => {
        if (action.index === index) {
          return { ...action, action: newAction };
        }
        return action;
      })
    );
  };

  return (
    <BulkLoad
      loading={loading}
      responseHeaders={getHeaders()}
      responseLines={responseValidator?.map((line) => {
        const { index, action, data, error } = line;
        const fileError = fileErrors.find((el) => el.index === index);
        const serverError = error ? t(`bulkLoadError.${error}`) : undefined;
        return {
          ...data,
          index,
          action,
          error: fileError?.content ?? serverError,
        };
      })}
      responseSave={responseSave}
      templateUrl="https://docs.google.com/spreadsheets/d/1Y-dZVVemFs8Vj_pCJFWgCojJPqguxzIuSg9Dojx1ZmM/copy"
      onValidator={onValidator}
      onSave={onSave}
      onChangeAction={onChangeAction}
      onReset={onReset}
    />
  );
};

export default VendorSupplyBulkLoad;
