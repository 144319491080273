import { AlertColor, SnackbarOrigin } from "@mui/material";
import { StoreRequestError, StoreRequestStatus } from "app/store/types";
import { City } from "core/city/entities/City";
import { Country } from "core/country/entities/Country";
import { Product } from "core/dailyAdjustments/entities/Catalog";
import { KitchenSlice } from "core/kitchens/entities/Kitchen";

export interface Alert {
  show?: boolean;
  title?: string | null;
  message?: string | null;
  duration?: number | null;
  severity?: AlertColor;
  position?: SnackbarOrigin;
  icon?: React.ReactNode;
  action?: React.ReactNode;
  audioOn?: boolean | null;
}

export interface KitchensState {
  data: KitchenSlice[];
  status: StoreRequestStatus;
  error: StoreRequestError;
}

export interface CitiesState {
  data: City[];
  status: StoreRequestStatus;
  error: StoreRequestError;
}

export interface CountriesState {
  data: Country[];
  status: StoreRequestStatus;
  error: StoreRequestError;
}

export interface ProductsState {
  data: Product[];
  status: StoreRequestStatus;
  error: StoreRequestError;
}

export interface GlobalState {
  alert: Alert;
  title: string | null;
  kitchens: KitchensState;
  countries: CountriesState;
  cities: CitiesState;
  selectedKitchen?: KitchenSlice;
  products: ProductsState;
  canSearchOnTopBar: boolean;
  textSearchOnTopBar: string;
  textSearchOnFullDialog: string;
  canSearchOnFullDialog: boolean;
}

export const defaultData = {
  data: [],
  status: "idle",
  error: null,
};

export const initialState: GlobalState = {
  alert: { show: false },
  selectedKitchen: undefined,
  kitchens: defaultData as KitchensState,
  countries: defaultData as CountriesState,
  cities: defaultData as CitiesState,
  products: defaultData as ProductsState,
  title: null,
  canSearchOnTopBar: false,
  textSearchOnTopBar: "",
  textSearchOnFullDialog: "",
  canSearchOnFullDialog: false,
};
