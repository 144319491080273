import { CancelOutlined, TaskAlt } from "@mui/icons-material";
import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import KitchenSelector from "app/components/Kitchen/Selector";
import NumberField from "app/components/common/Field/Number";
import { ManagerDialogFooter } from "app/components/common/Manager/Dialog/Footer";
import { useAlert } from "app/hooks/useAlert";
import { commons, measureUnits, vendor } from "app/i18n/types";
import { cities } from "app/utils/city.utils";
import { VendorMeasureUnit } from "app/utils/measureUnits";
import { Supply } from "core/supplies/entities/Supply";
import {
  VendorSupply,
  VendorTypeEnum,
} from "core/vendors/entities/VendorSupply";
import {
  createVendorSupply,
  modifyById,
} from "core/vendors/repositories/http/vendorSupply";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Actions } from "utils/modal";
import { ValidationError } from "yup";
import VendorSupplyManagerValidatorModal from "./ValidatorModal";
import { BaseDialogProps, FormState } from "./constants";
import { handleValidations } from "./formValidations";

export const HandleForm = (props: BaseDialogProps) => {
  const {
    item: initialValues,
    onClose,
    onSuccess,
    supplies,
    action,
    country,
  } = props;
  const { t } = useTranslation();
  const alert = useAlert();

  const [formState, setFormState] = useState<FormState>({
    id: initialValues?.id ?? 0,
    supplySku: initialValues?.supplySku ?? "",
    supplyName: initialValues?.supplyName ?? "",
    supplyUnit: initialValues?.supplyUnit ?? "",
    supplyCategory: initialValues?.supplyCategory ?? "",
    supplyUnitPrice: initialValues?.supplyUnitPrice ?? 0,
    supplyQuantity: initialValues?.supplyQuantity ?? 0,
    vendorId: initialValues?.vendorId ?? 0,
    vendorSupplyName: initialValues?.vendorSupplyName ?? "",
    vendorUnit: initialValues?.vendorUnit ?? "",
    vendorUnitPrice: initialValues?.vendorUnitPrice ?? 0,
    vendorType: initialValues?.vendorType ?? "",
    vendorMinQuantityToOrder: initialValues?.vendorMinQuantityToOrder ?? 0,
    city: initialValues?.city ?? "",
    vendorOrdersPerWeek: initialValues?.vendorOrdersPerWeek ?? 0,
    leadTime: initialValues?.leadTime ?? 0,
  });

  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedSupply, setSelectedSupply] = useState<Supply>();
  const [saveOrUpdateValidator, setSaveOrUpdateValidator] =
    useState<boolean>(false);
  const [openValidatorModal, setOpenValidatorModal] = useState<boolean>(false);

  useEffect(() => {
    setErrors({});
    handleValidations
      .validate({ ...formState }, { abortEarly: false })
      .catch((error: ValidationError) => {
        let modifiedErrors: Record<string, boolean> = {};
        error.inner.forEach((errorDetail) => {
          if (errorDetail.path) modifiedErrors[errorDetail.path] = true;
        });
        setErrors(modifiedErrors);
      });
  }, [formState]);

  const getResponse = async (data: VendorSupply) => {
    if (props.action === Actions.edit) {
      return await modifyById(data.id, data);
    }

    return await createVendorSupply(data);
  };

  const handleSubmit = useCallback(async () => {
    if (!handleValidations.isValidSync(formState)) {
      return;
    }
    if (!saveOrUpdateValidator) {
      setOpenValidatorModal(true);
      return;
    }
    setIsLoading(true);
    const data = formState as VendorSupply;

    const response = await getResponse(data);

    const title = t(`common.${response.message}`);
    if (response.ok) {
      alert.successWithMsg({ title });
      response.data && onSuccess(response.data);
      onClose();
    } else {
      alert.errorWithMsg({ title });
    }

    setIsLoading(false);
  }, [formState, props.action, saveOrUpdateValidator]);

  const handleIcon = (isError: boolean) => {
    if (isError) {
      return <CancelOutlined color="warning" />;
    }

    return <TaskAlt color="success" />;
  };

  const disableInputs = action === Actions.add && !selectedSupply;

  useEffect(() => {
    if (saveOrUpdateValidator) {
      handleSubmit();
    }
  }, [saveOrUpdateValidator]);

  return (
    <Grid container spacing={3}>
      {isLoading && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}

      {action === Actions.add && (
        <Grid item xs={12}>
          <Autocomplete
            renderInput={(params) => (
              <TextField
                {...params}
                label={t(commons.SUPPLY)}
                sx={{ bgcolor: "white" }}
              />
            )}
            value={selectedSupply}
            options={supplies ?? []}
            getOptionLabel={(option) => `${option.sku} - ${option.name}`}
            onChange={(_, value) => {
              setSelectedSupply(value ?? undefined);
              setFormState((prev) => {
                return {
                  ...prev,
                  supplySku: value?.sku ?? "",
                  supplyName: value?.name ?? "",
                  supplyUnit: value?.unit ?? "",
                  supplyCategory: value?.group ?? "",
                };
              });
            }}
            forcePopupIcon={!!handleIcon(errors["supplySku"])}
            popupIcon={handleIcon(errors["supplySku"])}
          />
        </Grid>
      )}

      <Grid item xs={5}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>{t(commons.CATEGORY)}</InputLabel>
          <OutlinedInput
            type="text"
            label={t(commons.CATEGORY)}
            value={t(formState.supplyCategory)}
            disabled
          />
        </FormControl>
      </Grid>
      <Grid item xs={5}>
        {action === Actions.add && (
          <KitchenSelector
            selected={{
              country,
              city: formState.city,
            }}
            onChange={{
              city: (value) =>
                setFormState((prev) => ({
                  ...prev,
                  city: value?.code ?? "",
                })),
            }}
            hide={{
              country: true,
              kitchen: true,
            }}
          />
        )}
        {action === Actions.edit && (
          <FormControl fullWidth>
            <InputLabel>{t(commons.CITY)}</InputLabel>
            <OutlinedInput
              type="text"
              label={t(commons.CITY)}
              value={cities[formState.city]}
              disabled
            />
          </FormControl>
        )}
      </Grid>
      <Grid item xs={2}>
        <FormControl variant="outlined" fullWidth>
          <NumberField
            label={t(vendor.LEAD_TIME)}
            value={formState.leadTime}
            endAdornment={handleIcon(errors["leadTime"])}
            onChangeValue={(leadTime) => {
              setFormState((prev) => ({
                ...prev,
                leadTime,
              }));
            }}
            disabled={disableInputs}
          />
        </FormControl>
      </Grid>

      {action === Actions.edit && (
        <>
          <Grid item xs={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>{t(commons.SKU)}</InputLabel>
              <OutlinedInput
                type="text"
                label={t(commons.SKU)}
                value={formState.supplySku}
                disabled
              />
            </FormControl>
          </Grid>
          <Grid item xs={9}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>{t(vendor.SUPPLY_NAME)}</InputLabel>
              <OutlinedInput
                type="text"
                label={t(vendor.SUPPLY_NAME)}
                value={formState.supplyName}
                disabled
              />
            </FormControl>
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>{t(vendor.VENDOR_SUPPLY_NAME)}</InputLabel>
          <OutlinedInput
            type="text"
            label={t(vendor.VENDOR_SUPPLY_NAME)}
            value={formState.vendorSupplyName}
            endAdornment={handleIcon(errors["vendorSupplyName"])}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setFormState((prev) => ({
                ...prev,
                vendorSupplyName: event.target.value,
              }));
            }}
            disabled={disableInputs}
          />
        </FormControl>
      </Grid>

      <Grid item xs={4}>
        <FormControl variant="outlined" fullWidth>
          <NumberField
            label={t(vendor.VENDOR_UNIT_PRICE)}
            value={formState.vendorUnitPrice}
            endAdornment={handleIcon(errors["vendorUnitPrice"])}
            onChangeValue={(vendorUnitPrice) => {
              const supplyUnitPrice = formState.supplyQuantity
                ? vendorUnitPrice / formState.supplyQuantity
                : 0;
              setFormState((prev) => ({
                ...prev,
                supplyUnitPrice,
                vendorUnitPrice,
              }));
            }}
            disabled={disableInputs}
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl variant="outlined" fullWidth>
          <NumberField
            label={t(vendor.SUPPLY_QUANTITY)}
            value={formState.supplyQuantity}
            endAdornment={
              <>
                <Typography variant="body1" sx={{ mr: 0.5 }}>
                  {formState.supplyUnit}
                </Typography>
                {handleIcon(errors["supplyQuantity"])}
              </>
            }
            onChangeValue={(supplyQuantity) => {
              const supplyUnitPrice = supplyQuantity
                ? formState.vendorUnitPrice / supplyQuantity
                : 0;
              setFormState((prev) => ({
                ...prev,
                supplyQuantity,
                supplyUnitPrice,
              }));
            }}
            disabled={disableInputs}
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel>{t(vendor.VENDOR_UNIT)}</InputLabel>
          <Select
            label={t(vendor.VENDOR_UNIT)}
            value={formState.vendorUnit}
            endAdornment={handleIcon(errors["vendorUnit"])}
            onChange={(event: SelectChangeEvent<string>) => {
              setFormState((prev) => ({
                ...prev,
                vendorUnit: event.target.value,
              }));
            }}
            disabled={disableInputs}
          >
            {Object.keys(VendorMeasureUnit).map((key) => (
              <MenuItem key={`vendorMeasureUnit-${key}`} value={key}>
                {t(`vendorMeasureUnit.${key}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={4}>
        <FormControl variant="outlined" fullWidth>
          <NumberField
            label={t(vendor.SUPPLY_UNIT_PRICE)}
            value={formState.supplyUnitPrice}
            endAdornment={handleIcon(errors["supplyUnitPrice"])}
            onChangeValue={(supplyUnitPrice) => {
              const vendorUnitPrice =
                supplyUnitPrice * formState.supplyQuantity;
              setFormState((prev) => ({
                ...prev,
                vendorUnitPrice,
                supplyUnitPrice,
              }));
            }}
            disabled={disableInputs}
          />
        </FormControl>
      </Grid>
      <Grid item xs={8}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>{t(vendor.SUPPLY_UNIT)}</InputLabel>
          <OutlinedInput
            type="text"
            label={t(vendor.SUPPLY_UNIT)}
            value={t(
              measureUnits[formState.supplyUnit as keyof typeof measureUnits]
            )}
            disabled
          />
        </FormControl>
      </Grid>

      <Grid item xs={4}>
        <FormControl variant="outlined" fullWidth>
          <NumberField
            label={t(vendor.VENDOR_MIN_ORDER_QUANTITY)}
            value={formState.vendorMinQuantityToOrder}
            endAdornment={handleIcon(errors["vendorMinQuantityToOrder"])}
            onChangeValue={(vendorMinQuantityToOrder) => {
              setFormState((prev) => ({
                ...prev,
                vendorMinQuantityToOrder,
              }));
            }}
            disabled={disableInputs}
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl variant="outlined" fullWidth>
          <NumberField
            label={t(vendor.VENDOR_ORDER_PER_WEEK)}
            value={formState.vendorOrdersPerWeek}
            endAdornment={handleIcon(errors["vendorOrdersPerWeek"])}
            onChangeValue={(vendorOrdersPerWeek) => {
              setFormState((prev) => ({
                ...prev,
                vendorOrdersPerWeek,
              }));
            }}
            disabled={disableInputs}
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel>{t(vendor.CATEGORY)}</InputLabel>
          <Select
            label={t(vendor.CATEGORY)}
            value={formState.vendorType}
            endAdornment={handleIcon(errors["vendorType"])}
            onChange={(event: SelectChangeEvent<string>) => {
              setFormState((prev) => ({
                ...prev,
                vendorType: event.target.value,
              }));
            }}
            disabled={disableInputs}
          >
            {Object.keys(VendorTypeEnum).map((type) => (
              <MenuItem key={`vendorType-${type}`} value={type}>
                {`${t(commons.TYPE)} ${type}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <ManagerDialogFooter
          onCancel={props.onClose}
          mainButton={{
            children: t(commons.SAVE),
            onClick: handleSubmit,
            disabled: isLoading || !!Object.keys(errors).length,
          }}
          loading={isLoading}
        />
      </Grid>
      {openValidatorModal && (
        <VendorSupplyManagerValidatorModal
          sku={formState.supplySku}
          type={formState.vendorType}
          city={formState.city}
          excludeId={formState.id}
          onValidator={setSaveOrUpdateValidator}
          onClose={() => setOpenValidatorModal(false)}
        />
      )}
    </Grid>
  );
};
