import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSRowData,
} from "@foodology-co/alejandria";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import { Button } from "@mui/material";
import {
  commons,
  countingTypeSelector,
  physicalCountSchedule,
} from "app/i18n/types";
import { CountingResponse } from "core/physicalCount/entities/Counting";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  loading: boolean;
  items: CountingResponse[];
  onSelectCounting: (counting: CountingResponse) => void;
}

const AuthorizeReCountsTable: FunctionComponent<Props> = (props) => {
  const { loading, items, onSelectCounting } = props;

  const { t } = useTranslation();

  const detailButton = (item: KOSRowData) => {
    return (
      <Button
        onClick={() => onSelectCounting(item as CountingResponse)}
        variant="outlined"
        startIcon={<FactCheckOutlinedIcon color="primary" />}
      >
        {t(commons.DETAIL)}
      </Button>
    );
  };

  const getHeader = (): KOSBaseTableHeader[] => [
    {
      label: t(commons.KITCHEN),
      field: "kitchenId",
    },
    {
      label: t(countingTypeSelector.LABEL),
      text: (item: KOSRowData) => {
        const comment = item.comment ? ` (${item.comment})` : ``;
        return `${t(item.type)}${comment}`;
      },
    },
    {
      label: t(commons.STATUS),
      text: (item: KOSRowData) => t(`module.physicalCount.${item.status}`),
    },
    {
      label: t(physicalCountSchedule.COUNT_DATE),
      field: "scheduledDate",
      type: "date",
    },
    {
      label: t(commons.DATETIME),
      field: "date",
      type: "datetime_utc",
    },
    {
      label: t(commons.ACTIONS),
      align: "center",
      component: (item: KOSRowData) => detailButton(item),
    },
  ];

  return (
    <KOSBaseTable
      columns={getHeader()}
      rows={{ data: items, total: items.length, loading }}
    />
  );
};

export default AuthorizeReCountsTable;
