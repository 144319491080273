import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ModuleSelectorCard from "app/components/ModuleSelector/ModuleSelectorCard";
import PageBox from "app/components/PageBox";
import PageHeader from "app/components/PageHeader";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import { kitchenInventoryManagement } from "app/i18n/types";
import { setSelectedKitchen } from "app/store/slices/global";
import { trackDeviceProperties } from "core/common/utils/remoteConfig";
import { t } from "i18next";
import React, { FunctionComponent, useEffect, useState } from "react";
import { ObjectParams } from "utils/http";
import { InventoryModule, getModuleLink, refineModules } from "utils/module";
import { TypeOfRole } from "utils/role";
import { refineLink } from "utils/route";

interface Props {}

const ModuleSelectorPage: FunctionComponent<Props> = () => {
  const navigator = useNavigator();
  const { kitchenId, inventoryModule } = navigator.params();
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.session.user.data);
  const [currentModule, setCurrentModule] = useState<InventoryModule>();
  const [modules, setModules] = useState<InventoryModule[]>([]);

  useEffect(() => {
    if (user?.role) {
      setModules(refineModules(user?.role as TypeOfRole));
    }
  }, [user?.role]);

  useEffect(() => {
    setCurrentModule(
      modules.find((module) => module.moduleId === inventoryModule)
    );
  }, [inventoryModule, modules]);

  useEffect(() => {
    trackDeviceProperties(kitchenId);
    dispatch(setSelectedKitchen(kitchenId));
  }, [kitchenId]);

  useEffect(() => {
    if (inventoryModule && !kitchenId) {
      navigator.toByLocationType(getModuleLink(inventoryModule));
    }
  }, [inventoryModule, kitchenId]);

  return (
    <PageBox showWidgetTicketTorre>
      <PageHeader
        title={t(
          currentModule?.moduleName ??
            kitchenInventoryManagement.SELECT_MODULE_TITLE
        )}
        subtitle={t(
          currentModule?.description ??
            kitchenInventoryManagement.SELECT_MODULE_SUBTITLE
        )}
      />

      <Box mt={4}>
        <Grid spacing={2} container>
          {!inventoryModule && (
            <>
              {modules.map((mod) => (
                <Grid xs={12} sm={3} key={mod.moduleId} item>
                  <ModuleSelectorCard
                    title={t(mod.moduleName)}
                    description={t(mod.description ?? "")}
                    link={`/modules/${mod.moduleId}`}
                  />
                </Grid>
              ))}
            </>
          )}

          {!!inventoryModule && (
            <>
              {currentModule?.submodules?.map((submodule, index) => (
                <React.Fragment key={submodule.moduleId}>
                  {submodule.link && (
                    <Grid
                      xs={12}
                      sm={6}
                      md={3}
                      key={`${submodule.moduleId}-${index}`}
                      item
                    >
                      <ModuleSelectorCard
                        moduleId={submodule.moduleId}
                        title={t(submodule.moduleName)}
                        description={t(submodule.description ?? "")}
                        link={refineLink(submodule.link, {
                          kitchenId,
                          locationType: currentModule.locationType,
                        } as ObjectParams)}
                      />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </>
          )}
        </Grid>
      </Box>
    </PageBox>
  );
};

export default ModuleSelectorPage;
