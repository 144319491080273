import { CoreBaseResponse } from "core/common/interfaces/core";

export interface PurchaseLine {
  id: number;
  sku: string;
  type: string;
  description: string;
  unit: string;
  quantity: number;
  quantityReceive: number;
  unitCost: number;
  amout: number;
  status?: string;

  supplyVendorName?: string;
  supplyVendorQuantity?: number;
  supplyVendorUnit?: string;
  supplyVendorPrice?: number;
}

export enum OriginType {
  BC = "BC",
  KIS = "KIS",
}

export type PurchaseOrderOrigin = `${OriginType}`;

export enum PurchaseOrderStatus {
  // KIS
  PENDING = "PENDING",
  RELEASED = "RELEASED",
  PARTIAL = "PARTIAL",
  PARTIAL_RECEIVED = "PARTIAL_RECEIVED",
  RECEIVED = "RECEIVED",
  VALIDATED = "VALIDATED",
  WITHOUT_INVOICE = "WITHOUT_INVOICE",
  ERROR_IN_PRICE = "ERROR_IN_PRICE",
  ERROR_IN_QUANTITY = "ERROR_IN_QUANTITY",
  ERROR_IN_PRICE_AND_QUANTITY = "ERROR_IN_PRICE_AND_QUANTITY",
  CORRECTED = "CORRECTED",
  ARCHIVED = "ARCHIVED",
  IN_PROCESS = "IN_PROCESS",
  ACCOUNTED = "ACCOUNTED",
  PRE_VALIDATED = "PRE_VALIDATED",
  OPS_REVIEW = "OPS_REVIEW",
  PAID = "PAID",
  // BC
  IN_TRANSIT = "IN_TRANSIT",
  COMPLETE = "COMPLETE",
}

export type PurchaseOrderStatusType = `${PurchaseOrderStatus}`;

export interface Purchase {
  documentType: string;
  orderId: string;
  receiptNo: string | null;
  vendorId: string;
  vendorName: string;
  totalGross: number;
  totalNet: number;
  status: PurchaseOrderStatusType;
  origin: PurchaseOrderOrigin;
  dateDocument: Date | string;
  initialExpectedReceiptAt?: Date;
  expectedReceiptAt?: Date | string;
  lines: PurchaseLine[] | null;
  code: string;
  invoiceNo: string;
  receiptDate?: Date;
  country: string;
  userId: string;
  userName: string;
  kitchenId: string;
  legalDocumentNo?: string;
  externalInvoiceNo?: string;
  externalIssueDate?: string;
  externalSubtotal?: number;
  adjustmentAmount?: number;
}

export interface NewPurchaseOrder {
  vendorCode: string;
  postingDate: string;
  kitchenId: string;
  orderDate: string;
  invoiceNo: string;
  expectedReceiptAt: string;
  lines: NewPurchaseOrderLine[];
}

export interface NewPurchaseOrderLine {
  sku: string;
  quantity: number;
  unitPrice: number;
  measureUnit?: string;
}

export interface NewIndividualPurchaseOrderLine {
  purchaseOrderId: number;
  sku: string;
  quantity: number;
  productName: string;
  unitPrice: number;
  measureUnit: string;
  vendorSupplyId?: number;
}

export interface PurchaseOrder {
  id: number;
  vendorCode: string;
  vendorName: string;
  postingDate: string;
  locationCode: string;
  country: string;
  orderDate: string;
  invoiceNo: string;
  purchaseEmail: string;
  expectedReceiptAt: string;
  lines: PurchaseOrderLine[];
}
export interface PurchaseOrderLine {
  id: number;
  sku: string;
  productName: string;
  quantity: number;
  unitPrice: number;
  measureUnit: string;
  vendorSupplyId?: number;
  supplyVendorPrice?: number;
  supplyUnitPrice?: number;
  supplyVendorUnit?: string;
}

export interface CreatePurchaseOrderResponse {
  ok: boolean;
  ordersCreated: number;
  errors?: CreatePurchaseOrderError[];
}

export interface CreatePurchaseOrderError {
  invoiceNo: string;
  type: ErrorType;
  code: string;
}

export enum ErrorType {
  VENDOR,
  SKU,
}

export interface ModifyPayload {
  id: number;
  lines: LineModify[];
}

export interface LineModify {
  id: number;
  quantity: number;
  quantityReceive: number;
  unitPrice: number;
}

export interface CreatePurchaseLineResponse {
  ok: boolean;
  id: number;
}

export interface UpdateInvoiceNroOfReceiptResponse extends CoreBaseResponse {
  data?: string;
}

export interface UpdateOrderDateResponse extends CoreBaseResponse {
  data?: Date;
}
