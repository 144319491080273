import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSRowData,
} from "@foodology-co/alejandria";
import { Grid } from "@mui/material";
import CommentDrawerWithFloatButton from "app/components/CommentArea/DrawerWithFloatButton";
import PageBox from "app/components/PageBox";
import PageHeader from "app/components/PageHeader";
import TransferOrderDetailsPrincipal from "app/components/TransferOrderDetails/Principal";
import TransferOrderSummary from "app/components/TransferOrderDetails/TransferOrderSummary";
import TransferChipStatus from "app/components/TransferOrders/TransferChipStatus";
import InputSearchCustom from "app/components/common/InputSearchCustom";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useNavigator } from "app/hooks/useNavigator";
import { commons, transferOrderDetails } from "app/i18n/types";
import { getTransferOrderById } from "app/store/slices/transfer/thunks";
import { CommentTypeEnum } from "core/comment/entity";
import {
  TransferLine,
  TransferResponse,
} from "core/transfer/entities/TransferOrder";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMount } from "react-use";
import {
  DataFilterTransferOrder,
  clearSpecialCharacters,
  compareSearchText,
} from "utils/general";

interface Props {}

const TransferOrderDetailsPage: FunctionComponent<Props> = () => {
  const [transferOrder, setTransferOrder] = useState<TransferResponse>();
  const navigator = useNavigator();
  const { id } = navigator.params();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [filteredTransferLines, setFilteredTransferLines] = useState<
    TransferLine[]
  >([]);
  const [dataFilter, setDataFilter] = useState<DataFilterTransferOrder>(
    DataFilterTransferOrder.ALL
  );

  const getTransferOrder = async () => {
    if (id) {
      setLoading(true);
      const transferOrder = await dispatch(
        getTransferOrderById({ transferId: id })
      );
      setTransferOrder(transferOrder.payload as TransferResponse);
      if (transferOrder.payload) {
        setLoading(false);
      }
    }
  };

  const searchValidator = (line: TransferLine, toSearch: string): boolean => {
    if (!toSearch) return true;
    return (
      compareSearchText(line.sku, toSearch) ||
      compareSearchText(line.name, toSearch)
    );
  };

  const filterValidator = (
    line: TransferLine,
    filter: DataFilterTransferOrder
  ): boolean => {
    if (filter === "ALL") return true;
    return filter === line.status;
  };

  useEffect(() => {
    const toSearch = clearSpecialCharacters(search);
    setFilteredTransferLines(
      transferOrder?.lines?.length
        ? transferOrder.lines.filter((line) => {
            return (
              searchValidator(line, toSearch) &&
              filterValidator(line, dataFilter)
            );
          })
        : []
    );
  }, [transferOrder, search, dataFilter]);

  useMount(() => {
    getTransferOrder();
  });

  const getHeader = (): KOSBaseTableHeader[] => [
    {
      label: t(commons.SKU),
      field: "sku",
    },
    {
      label: t(commons.PRODUCT_NAME),
      field: "name",
    },
    {
      label: t(commons.STATUS),
      align: "center",
      component: (item: KOSRowData) => (
        <TransferChipStatus status={String(item.status)} />
      ),
    },
    {
      label: t(commons.UNITS),
      field: "measureUnit",
      align: "center",
    },
    {
      label: t(commons.REQUESTED),
      field: "quantity",
      align: "right",
    },
    {
      label: t(commons.SENT),
      field: "sendQuantity",
      align: "right",
    },
    {
      label: t(commons.RECEIVED),
      field: "receivedQuantity",
      align: "right",
    },
    {
      label: t(commons.RETURNED),
      align: "right",
      type: "number",
      text: (item: KOSRowData) => {
        const returned =
          Number(item.sendQuantity) - Number(item.receivedQuantity);
        return String(returned);
      },
    },
    {
      label: t(commons.REASON),
      text: (item: KOSRowData) =>
        item.returnReason ? t(`missingReason.${item.returnReason}`) : ``,
    },
  ];

  return (
    <PageBox>
      <PageHeader title={t(transferOrderDetails.TITLE)} />

      {transferOrder && (
        <>
          <Grid container spacing={2}>
            <TransferOrderDetailsPrincipal transferOrder={transferOrder} />
            <Grid item sm={3} xs={12}>
              <InputSearchCustom
                title={transferOrderDetails.FIND_SUPPLY}
                label={transferOrderDetails.FIND_SUPPLY_LABEL}
                setSearch={setSearch}
              />
            </Grid>
            <TransferOrderSummary
              transferLines={transferOrder.lines}
              dataFilter={dataFilter}
              setDataFilter={setDataFilter}
            />
          </Grid>

          <CommentDrawerWithFloatButton
            sourceId={transferOrder.id.toString()}
            kitchenId={transferOrder.destinationKitchenId}
            type={CommentTypeEnum.TRANSFER_ORDER}
          />
        </>
      )}

      <KOSBaseTable
        columns={getHeader()}
        rows={{
          data: filteredTransferLines,
          total: filteredTransferLines.length,
          loading,
        }}
      />
    </PageBox>
  );
};

export default TransferOrderDetailsPage;
