import VendorSupplyDistinctTable from "app/components/Vendor/Supply/Distinct/Table";
import SearchOnTopBar from "app/components/common/SearchOnTopBar";
import { VendorSupplyDistinct } from "core/vendors/entities/VendorSupply";
import { distinctByCity } from "core/vendors/repositories/http/vendorSupply";
import { useEffect, useState } from "react";
import { compareSearchText } from "utils/general";

interface Props {
  selectedCity: string;
  onLoading: (loading: boolean) => void;
}

const BySuppliesView = (props: Props) => {
  const { selectedCity, onLoading } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<VendorSupplyDistinct[]>([]);
  const [supplies, setSupplies] = useState<VendorSupplyDistinct[]>([]);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (!selectedCity) return;
    setLoading(true);
    distinctByCity(selectedCity)
      .then((vendors) => {
        setData(vendors);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedCity]);

  useEffect(() => {
    setSupplies(
      data.filter((el) => {
        if (!search) return true;
        return (
          compareSearchText(el.supplySku, search) ||
          compareSearchText(el.supplyName, search) ||
          compareSearchText(el.supplyCategory, search)
        );
      })
    );
  }, [data, search]);

  return (
    <>
      <SearchOnTopBar onSearch={setSearch} />
      <VendorSupplyDistinctTable supplies={supplies} loading={loading} />
    </>
  );
};

export default BySuppliesView;
