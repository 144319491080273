import { KOSEmptyState } from "@foodology-co/alejandria";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box } from "@mui/material";
import PageBox from "app/components/PageBox";
import PageHeader from "app/components/PageHeader";
import BarCodeScanner, {
  OnActionResponse,
} from "app/components/Scanner/BarCode";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { commons, productionManagement } from "app/i18n/types";
import { getProductionLogByType } from "app/store/slices/productions/thunks";
import {
  ProductionLogSearchResponse,
  ProductionLogSearchTypeEnum,
  productionLogSearchDefault,
} from "core/productions/entities/Productions";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import ProductionLogDetail from "./ProductionLogDetail";
import ProductionLogHeader from "./ProductionLogHeader";

const ProductionLogPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [data, setData] = useState<ProductionLogSearchResponse>(
    productionLogSearchDefault
  );

  const handleSearch = async (code?: string) => {
    const search = !code ? searchTerm : code;
    if (search) {
      const type = isNaN(Number(search))
        ? ProductionLogSearchTypeEnum.BY_BATCH
        : ProductionLogSearchTypeEnum.BY_LABEL_ID;
      const response = await dispatch(
        getProductionLogByType({ type, searchTerm: search })
      );

      setData(response.payload as ProductionLogSearchResponse);
    } else {
      setData(productionLogSearchDefault);
    }
  };

  const onActionScan = async (code: string): Promise<OnActionResponse> => {
    handleSearch(code);
    return { success: true, message: t(commons.SCANNED) };
  };

  return (
    <PageBox>
      <PageHeader
        title={productionManagement.LOG_TITLE}
        subtitle={productionManagement.LOG_DESCRIPTION}
      />
      <ProductionLogHeader
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleClick={() => handleSearch()}
      />
      {data.ok && <ProductionLogDetail logDetail={data} />}
      {!data.ok && (
        <Box mt={2}>
          <KOSEmptyState
            icon={InfoOutlinedIcon}
            message={t(productionManagement.LOG_EMPTY_LABEL_1)}
            instruction={t(productionManagement.LOG_EMPTY_LABEL_2)}
          />
        </Box>
      )}
      <BarCodeScanner onAction={onActionScan} />
    </PageBox>
  );
};

export default ProductionLogPage;
