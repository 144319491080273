import { setUserProperties } from "firebase/analytics";
import {
  RemoteConfig,
  fetchAndActivate,
  getAll,
  getBoolean,
  getNumber,
  getRemoteConfig,
  getString,
} from "firebase/remote-config";
import {
  getLocalStorageJSON,
  getLocalStorageString,
  setLocalStorage,
} from "utils/localStorage";
import { analytics, firebaseApp } from "./firebase-config";

export enum RemoteConfigKey {
  ALLOW_NEW_PRODUCTIONS = "allowNewProductions",
  SHOW_INVENTORY_ON_NEW_PRODUCTIONS = "showInventoryOnNewProduction",
  SHOW_PRODUCTION_PLAN = "showProductionPlan",
  VALIDATION_REQUIRED = "validationRequired",
  PRICE_RANGE_IN_PO = "priceRangeInPO",
}

let remoteConfig: RemoteConfig;
let initialized = false;
const secondsTimeoutToResponse = 60;
const secondsToUpdate = 900;

const initRemoteConfig = (): void => {
  if (!initialized) {
    remoteConfig = getRemoteConfig(firebaseApp().app);
    remoteConfig.settings.fetchTimeoutMillis = secondsTimeoutToResponse * 1000;
    remoteConfig.settings.minimumFetchIntervalMillis = secondsToUpdate * 1000;
    initialized = true;
  }
};

const getAndUpdateFromRemoteConfig = async (configuration: RemoteConfig) => {
  const remoteConfigLS = getLocalStorageString("remoteConfig");
  fetchAndActivate(configuration)
    .then(() => {
      const configs = getAll(remoteConfig);
      if (
        configs &&
        Object.keys(configs).length &&
        JSON.stringify(configs) !== remoteConfigLS
      ) {
        setLocalStorage("remoteConfig", configs);
      }
    })
    .catch((error) => {
      console.error({ error });
    });
};

const getConfiguration = (isForced = false) => {
  initRemoteConfig();

  const temporalConfig = {};
  const resultConfig = Object.assign(temporalConfig, remoteConfig);

  resultConfig.settings.fetchTimeoutMillis = isForced
    ? 0
    : secondsTimeoutToResponse * 1000;
  resultConfig.settings.minimumFetchIntervalMillis = isForced
    ? 0
    : secondsToUpdate * 1000;

  return resultConfig;
};

const getRemoteValueToLocalStorage = (key: string): string => {
  const configs = getLocalStorageJSON("remoteConfig") as any;
  /* eslint-disable no-underscore-dangle */
  try {
    return configs[key]._value;
  } catch (e) {
    return "";
  }
};

const getRemoteValueNumber = (key: string): number => {
  try {
    return getNumber(remoteConfig, key);
  } catch (ex) {
    return Number(getRemoteValueToLocalStorage(key));
  }
};

const getRemoteValueString = (key: string): string => {
  try {
    return getString(remoteConfig, key);
  } catch (ex) {
    return getRemoteValueToLocalStorage(key);
  }
};

const getRemoteValueJSON = (key: string): any => {
  try {
    return JSON.parse(getString(remoteConfig, key));
  } catch (ex) {
    return JSON.parse(getRemoteValueToLocalStorage(key));
  }
};

const getRemoteValueBoolean = (key: string): boolean => {
  try {
    return getBoolean(remoteConfig, key);
  } catch (ex) {
    return getRemoteValueToLocalStorage(key).toLowerCase() === "true";
  }
};

export const onStartFetchRemoteConfig = async (): Promise<void> => {
  initRemoteConfig();

  await getAndUpdateFromRemoteConfig(getConfiguration());
};

export const forceRemoteConfigRefresh = async (): Promise<void> => {
  initRemoteConfig();

  await getAndUpdateFromRemoteConfig(getConfiguration(true));
};

export const trackDeviceProperties = (kitchenId?: string): void => {
  const selectedKitchen =
    kitchenId ?? getLocalStorageString("selectedKitchenId");
  if (selectedKitchen) {
    setUserProperties(analytics, { selectedKitchen });
    getConfiguration(true);
  }
};

export {
  getRemoteValueBoolean,
  getRemoteValueJSON,
  getRemoteValueNumber,
  getRemoteValueString,
};
