import { FactCheckOutlined } from "@mui/icons-material";
import PageBox from "app/components/PageBox";
import PageHeader from "app/components/PageHeader";
import CreatePurchaseOrderCheckedDialog from "app/components/PurchaseOrder/Create/CheckedDialog";
import CreatePurchaseOrderMassiveDialog from "app/components/PurchaseOrder/Create/MassiveDialog";
import CreatePurchaseOrderPending from "app/components/PurchaseOrder/Create/Pending";
import CreatePurchaseOrderModal from "app/components/PurchaseOrder/CreatePurchaseOrderModal";
import SearchOnTopBar from "app/components/common/SearchOnTopBar";
import { commons, purchaseOrderCreation } from "app/i18n/types";
import {
  PurchaseOrder,
  PurchaseOrderLine,
} from "core/purchaseOrders/entities/PurchaseOrder";
import { getPendingPurchaseOrderByUserId } from "core/purchaseOrders/repositories/http/purchase";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMount } from "react-use";
import { OnChange, compareSearchText, onChangeDefault } from "utils/general";

const CreatePurchaseOrderPage = () => {
  const { t } = useTranslation();

  const [fileErrors, setFileErrors] = useState<string[]>([]);
  const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrder[]>([]);
  const [openCreatePurchaseOrder, setOpenCreatePurchaseOrder] =
    useState<boolean>(false);
  const [purchaseOrdersFiltered, setPurchaseOrdersFiltered] = useState<
    PurchaseOrder[]
  >([]);
  const [checkedPurchaseOrders, setCheckedPurchaseOrders] = useState<number[]>(
    []
  );
  const [search, setSearch] = useState<string>("");
  const [
    openCreateMassivePurchaseOrderModal,
    setOpenCreateMassivePurchaseOrderModal,
  ] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  const [change, setChange] = useState<OnChange>(onChangeDefault);

  const toggleCheckedAll = useCallback(() => {
    if (
      !!checkedPurchaseOrders.length &&
      checkedPurchaseOrders.length === purchaseOrders.length
    ) {
      setCheckedPurchaseOrders([]);
      return;
    }
    setCheckedPurchaseOrders(
      purchaseOrders.filter((el) => !!el.lines.length).map((el) => el.id)
    );
  }, [checkedPurchaseOrders, purchaseOrders]);

  const toggleChecked = useCallback(
    (purchaseOrderId?: number) => {
      if (!purchaseOrderId) {
        toggleCheckedAll();
        return;
      }

      if (checkedPurchaseOrders.includes(purchaseOrderId)) {
        setCheckedPurchaseOrders((prevState) =>
          prevState.filter((id) => id !== purchaseOrderId)
        );
        return;
      }
      setCheckedPurchaseOrders((prev) => [...prev, purchaseOrderId]);
    },
    [checkedPurchaseOrders]
  );

  const getPurchaseOrders = useCallback(() => {
    getPendingPurchaseOrderByUserId().then((purchaseData) => {
      setPurchaseOrders(purchaseData);
    });
  }, [setPurchaseOrders]);

  useEffect(() => {
    setPurchaseOrdersFiltered(
      purchaseOrders.filter((purchaseOrder) => {
        if (!search) return true;
        const vendorValidator = compareSearchText(
          purchaseOrder.vendorName,
          search
        );
        const orderId = compareSearchText(purchaseOrder.id, search);
        return vendorValidator || orderId;
      })
    );
  }, [purchaseOrders, search]);

  const getPurchaseOrderLinesRefined = (
    purchaseOrder: PurchaseOrder,
    change: Partial<PurchaseOrderLine>,
    lineId?: number
  ): PurchaseOrderLine[] => {
    if (lineId) {
      return purchaseOrder.lines.map((line) => {
        if (line.id === lineId) return { ...line, ...change };
        return line;
      });
    }
    return [...purchaseOrder.lines, change as PurchaseOrderLine];
  };

  useMount(() => {
    getPurchaseOrders();
  });

  return (
    <PageBox>
      <SearchOnTopBar onSearch={setSearch} />
      <PageHeader
        title={t(purchaseOrderCreation.TITLE)}
        rightArea={[
          {
            children: t(commons.BULK_LOAD),
            startIcon: <FactCheckOutlined />,
            variant: "outlined",
            color: "info",
            onClick: () => setOpenCreateMassivePurchaseOrderModal(true),
          },
          {
            children: t(purchaseOrderCreation.CREATE_INDIVIDUAL_BUTTON),
            startIcon: <FactCheckOutlined />,
            variant: "outlined",
            color: "info",
            onClick: () => setOpenCreatePurchaseOrder(true),
          },
          {
            children: t(commons.CONFIRM),
            variant: "contained",
            size: "large",
            startIcon: <FactCheckOutlined />,
            disabled: checkedPurchaseOrders.length === 0,
            onClick: () => setOpenConfirm(true),
          },
        ]}
      />

      <CreatePurchaseOrderPending
        purchaseOrders={purchaseOrders}
        setPurchaseOrders={setPurchaseOrders}
        purchaseOrdersFiltered={purchaseOrdersFiltered}
        change={change}
        setChange={setChange}
        checkedPurchaseOrders={checkedPurchaseOrders}
        emptySearchMessage={t(commons.NO_SEARCH_RESULTS, { search })}
        fileErrors={fileErrors}
        getPurchaseOrders={getPurchaseOrders}
        toggleChecked={toggleChecked}
        getPurchaseOrderLinesRefined={getPurchaseOrderLinesRefined}
      />
      <CreatePurchaseOrderMassiveDialog
        open={openCreateMassivePurchaseOrderModal}
        onClose={() => setOpenCreateMassivePurchaseOrderModal(false)}
        getPurchaseOrders={getPurchaseOrders}
        setFileErrors={setFileErrors}
      />
      <CreatePurchaseOrderCheckedDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        purchaseOrders={purchaseOrders}
        checkedPurchaseOrders={checkedPurchaseOrders}
        onSuccess={(removes) => {
          setChange({ label: "removeMany", value: removes });
          setCheckedPurchaseOrders([]);
          setOpenConfirm(false);
        }}
      />
      {openCreatePurchaseOrder && (
        <CreatePurchaseOrderModal
          onClose={() => setOpenCreatePurchaseOrder(false)}
          onPurchaseOrderCreated={getPurchaseOrders}
        />
      )}
    </PageBox>
  );
};

export default CreatePurchaseOrderPage;
