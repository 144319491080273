import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import { Grid, LinearProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import KitchenSelector from "app/components/Kitchen/Selector";
import PageBox from "app/components/PageBox";
import PageHeader from "app/components/PageHeader/PageHeader";
import AuthorizeReCountsCountingDetailDialog from "app/components/PhysicalCount/AuthorizeRecount/CountingDetailDialog/CountingDetailDialog";
import AuthorizeReCountsTable from "app/components/PhysicalCount/AuthorizeRecount/CountsTable/CountsTable";
import FullDialog from "app/components/common/FullDialog";
import {
  kitchenInventoryManagement,
  theoricalInventoryModule,
} from "app/i18n/types";
import { localizeDayjs } from "app/utils/dayjs";
import { CountingResponse } from "core/physicalCount/entities/Counting";
import { countingsForApprove } from "core/physicalCount/repositories/http/counting";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getLocationAndType } from "utils/general";

const AuthorizationReCount: FunctionComponent = () => {
  const { t, i18n } = useTranslation();

  const [counts, setCounts] = useState<CountingResponse[]>([]);

  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [selectedCity, setSelectedCity] = useState<string>("");
  const [selectedKitchen, setSelectedKitchen] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);
  const [openDetail, setOpenDetail] = React.useState<boolean>(false);
  const [selectedCounting, setSelectedCounting] =
    useState<CountingResponse | null>(null);

  const getCountings = async () => {
    const params = getLocationAndType({
      selectedCountry,
      selectedCity,
      selectedKitchen,
    });
    if (params) {
      setLoading(true);
      countingsForApprove(params.type, params.location)
        .then((response) => {
          setCounts(response);
        })
        .catch(() => {
          setCounts([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    localizeDayjs(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    getCountings();
  }, [selectedCountry, selectedCity, selectedKitchen]);

  return (
    <PageBox>
      <PageHeader
        title={t(kitchenInventoryManagement.AUTHORIZATION_RECOUNTING_TITLE)}
        subtitle={t(
          kitchenInventoryManagement.AUTHORIZATION_RECOUNTING_DESCRIPTION
        )}
        rightArea={[
          {
            children: "Actualizar",
            variant: "contained",
            size: "large",
            startIcon: <FactCheckOutlinedIcon />,
            color: "inherit",
            sx: { bgcolor: "white", mr: 2 },
            onClick: getCountings,
          },
        ]}
      />

      <Paper sx={{ p: 2 }}>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item sm={12} xs={12}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t(theoricalInventoryModule.REPORT_FILTER)}
            </Typography>
            <KitchenSelector
              selected={{
                country: selectedCountry,
                city: selectedCity,
                kitchen: selectedKitchen,
              }}
              onChange={{
                country: (value) => setSelectedCountry(value?.code ?? ""),
                city: (value) => setSelectedCity(value?.code ?? ""),
                kitchen: (value) => setSelectedKitchen(value?.kitchenId ?? ""),
              }}
              extra={{ showHash: true, disabled: loading }}
            />
          </Grid>
        </Grid>
      </Paper>

      {loading && <LinearProgress />}

      <Box sx={{ mt: 1 }}>
        <AuthorizeReCountsTable
          loading={loading}
          items={counts}
          onSelectCounting={(counting) => {
            setSelectedCounting(counting);
            setOpenDetail(true);
          }}
        />
      </Box>

      {selectedCounting && (
        <FullDialog
          open={openDetail}
          onClose={() => setOpenDetail(false)}
          title={selectedCounting.kitchenId}
          content={
            <AuthorizeReCountsCountingDetailDialog
              counting={selectedCounting}
              onUpdate={() => {
                setOpenDetail(false);
                getCountings();
              }}
            />
          }
        />
      )}
    </PageBox>
  );
};

export default AuthorizationReCount;
