import {
  KitchenType,
  KOSKitchen,
  KOSLocationSelector,
} from "@foodology-co/alejandria";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useAllCities } from "app/hooks/useAllCities";
import { useAllCountries } from "app/hooks/useAllCountries";
import { useAllKitchens } from "app/hooks/useAllKitchens";
import { useNavigator } from "app/hooks/useNavigator";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { commons } from "../../../i18n/types";

interface Props {}

const KitchenSelectorDialog: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const navigator = useNavigator();
  const { inventoryModule, kitchenId } = navigator.params();

  const [selectedCountry, setSelectedCountry] = useState<string>();
  const [selectedCity, setSelectedCity] = useState<string>();
  const [selectedLocation, setSelectedLocation] = useState<string>();

  const selectorOptions = useMemo((): KitchenType => {
    if (inventoryModule === "production-center-inventory-management") {
      return "PRODUCTION_CENTER";
    }
    return "KITCHEN";
  }, [inventoryModule]);

  useEffect(() => {
    if (kitchenId && selectedLocation) {
      navigator.to(`/modules/${inventoryModule}/${selectedLocation}`);
    }
  }, [kitchenId, selectedLocation]);

  return (
    <Dialog open maxWidth="md" fullWidth>
      <DialogTitle>{t(commons.SELECT_LOCATIONS)}</DialogTitle>
      <DialogContent sx={{ padding: "20px 24px!important" }}>
        <KOSLocationSelector
          selected={{
            country: selectedCountry,
            city: selectedCity,
            kitchen: selectedLocation,
          }}
          onChange={{
            country: (value) => setSelectedCountry(value?.code),
            city: (value) => setSelectedCity(value?.code),
            kitchen: (value) => setSelectedLocation(value?.kitchenId),
          }}
          extra={{
            filterType: selectorOptions,
          }}
          list={{
            country: useAllCountries().list,
            city: useAllCities().list,
            kitchen: useAllKitchens().list as KOSKitchen[],
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default KitchenSelectorDialog;
