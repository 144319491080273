import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSRowData,
  KOSSelectedResult,
} from "@foodology-co/alejandria";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import { Box } from "@mui/material";
import CommonDialog from "app/components/common/CommonDialog";
import NumberField from "app/components/common/Field/Number";
import PageBox from "app/components/PageBox";
import PageHeader from "app/components/PageHeader";
import { useAlert } from "app/hooks/useAlert";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import {
  adjustmentType,
  commons,
  dailyAdjustmentsModule,
  kitchenInventoryManagement,
  physicalCountModule,
  physicalCountSchedule,
  theoricalInventoryModule,
} from "app/i18n/types";
import { archiveCounts } from "app/store/slices/counting/thunks";
import {
  EntryType,
  ManualAdjustment,
} from "core/adjustments/entities/ManualAdjustment";
import {
  CountingDetail,
  CountingResponse,
} from "core/physicalCount/entities/Counting";
import {
  recountDetail,
  validateCounting,
} from "core/physicalCount/repositories/http/counting";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  counting: CountingResponse;
  onUpdate: () => void;
}

const AuthorizeReCountsCountingDetailDialog: FunctionComponent<Props> = (
  props
) => {
  const { counting, onUpdate } = props;
  const { t } = useTranslation();
  const alert = useAlert();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [details, setDetails] = useState<CountingDetail[]>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const [selectedItems, setSelectedItems] = useState<KOSSelectedResult[]>([]);

  useEffect(() => {
    getLastReCount();
  }, [counting]);

  const getLastReCount = async () => {
    setLoading(true);
    recountDetail(counting.kitchenId, counting.id)
      .then((resp) => {
        setDetails(
          resp.data?.countingDetails
            .filter((det) => !det.validationBy)
            .sort(compareDifference) ?? []
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onArchive = () => {
    setLoading(true);
    const ids = [Number(counting.id)];
    dispatch(archiveCounts(ids))
      .then((_) => {
        alert.success();
        onUpdate();
      })
      .catch(() => {
        alert.errorWithMsg({ title: t(physicalCountSchedule.ARCHIVE_ERROR) });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const compareDifference = (a: CountingDetail, b: CountingDetail) => {
    const aDiff = a.quantity - (a.lastQuantity ?? 0);
    const bDiff = b.quantity - (b.lastQuantity ?? 0);
    return Math.abs(bDiff) - Math.abs(aDiff);
  };

  const setQuantity = useCallback(
    (item: CountingDetail, newQuantity: number) => {
      setDetails((prevState) =>
        prevState.map((detail: CountingDetail) => {
          if (detail.sku === item.sku) {
            return { ...item, quantity: newQuantity };
          } else {
            return detail;
          }
        })
      );
    },
    []
  );

  const getManualAdjustment = (
    countingDetail: CountingDetail
  ): ManualAdjustment => {
    const quantityDiff =
      countingDetail.quantity - (countingDetail.lastQuantity ?? 0);
    const now = new Date();
    return {
      quantity: Math.abs(quantityDiff),
      quantityAfterValidation: countingDetail.quantity,
      sku: countingDetail.sku,
      kitchenId: counting.kitchenId ?? "",
      entryType: quantityDiff < 0 ? EntryType.NEGATIVE : EntryType.POSITIVE,
      name: countingDetail?.productName,
      unit: countingDetail?.unit,
      documentCode: `VAL-${counting.id}`,
      key: now.toTimeString(),
    } as ManualAdjustment;
  };

  const entryColor = (item: CountingDetail) => {
    const quantity = item.quantity - (item.lastQuantity ?? 0);
    return quantity >= 0 ? "success.main" : "error.main";
  };

  const quantityInput = (item: KOSRowData) => {
    return (
      <NumberField
        name="value"
        value={item.quantity}
        label={t(commons.REPORTED_AMOUNT)}
        size="small"
        onChangeValue={(newValue) =>
          setQuantity(item as CountingDetail, newValue)
        }
        sx={{ maxWidth: 160 }}
      />
    );
  };

  const detailAdjustmentType = (item: KOSRowData) => {
    const quantityDiff = item.quantity - item.lastQuantity;
    const entryType =
      quantityDiff < 0 ? EntryType.NEGATIVE : EntryType.POSITIVE;
    return t(`adjustmentType.${entryType}`);
  };

  const getHeader = (): KOSBaseTableHeader[] => [
    {
      label: t(commons.SKU),
      field: "sku",
      sort: true,
    },
    {
      label: t(commons.NAME),
      field: "productName",
      sort: true,
    },
    {
      label: t(theoricalInventoryModule.THEORETICAL_AMOUNT),
      field: "lastTheoretical",
      type: "number",
      sort: true,
    },
    {
      label: t(theoricalInventoryModule.ACTUAL_AMOUNT),
      field: "lastQuantity",
      type: "number",
      sort: true,
    },
    {
      label: t(commons.QUANTITY),
      component: (item: KOSRowData) => quantityInput(item),
    },
    {
      label: t(adjustmentType.MANUAL_ADJUSTMENT),
      color: (item: KOSRowData) => entryColor(item as CountingDetail),
      text: (item: KOSRowData) =>
        Math.abs(item.quantity - item.lastQuantity).toString(),
      type: "number",
      sort: true,
      weight: "bold",
    },
    {
      label: t(commons.TYPE),
      color: (item: KOSRowData) => entryColor(item as CountingDetail),
      text: (item: KOSRowData) => detailAdjustmentType(item),
      weight: "bold",
      sort: true,
    },
    {
      label: t(commons.UNIT),
      field: "unit",
    },
  ];

  const authorizeMovements = async () => {
    setOpenDialog(false);

    const manualAdjustments: ManualAdjustment[] = selectedItems.map(
      (detail) => {
        return getManualAdjustment(detail as CountingDetail);
      }
    );
    setLoading(true);
    validateCounting(counting.id, manualAdjustments)
      .then((resp) => {
        alert.success();
        onUpdate();
      })
      .finally(() => setLoading(false));
  };

  return (
    <PageBox>
      <PageHeader
        title={t(kitchenInventoryManagement.AUTHORIZATION_RECOUNTING_TITLE)}
        isFullDialog
        rightArea={[
          {
            children: t(commons.ARCHIVE),
            variant: "outlined",
            color: "error",
            size: "large",
            startIcon: <DeleteOutlineOutlinedIcon />,
            onClick: () => setOpenDeleteDialog(true),
            disabled: loading,
          },
          {
            children: t(commons.AUTHORIZE),
            variant: "contained",
            size: "large",
            startIcon: <FactCheckOutlinedIcon />,
            onClick: () => setOpenDialog(true),
            disabled: selectedItems.length === 0 || loading,
          },
        ]}
      />
      <Box sx={{ mt: 1, width: "100%" }}>
        <KOSBaseTable
          columns={getHeader()}
          sortable={{
            sort: {
              orderBy: "quantity",
              order: "asc",
            },
          }}
          rows={{
            data: details,
            total: details.length,
            loading,
          }}
          selectable={{
            resultHeader: [
              "sku",
              "productName",
              "lastTheoretical",
              "lastQuantity",
              "quantity",
              "unit",
            ],
            items: selectedItems,
            onChange: setSelectedItems,
            canSelectAll: true,
          }}
        />
        <CommonDialog
          handleClose={() => setOpenDialog(false)}
          handleConfirm={() => authorizeMovements()}
          open={openDialog}
          title={t(dailyAdjustmentsModule.MOVEMENTS_AUTHORIZATION)}
          message={
            selectedItems.length === 1
              ? t(dailyAdjustmentsModule.AUTHORIZE_CONFIRM_MESSAGE)
              : t(dailyAdjustmentsModule.AUTHORIZE_CONFIRM_MESSAGE_PLURAL, {
                  supplyQuantity: selectedItems.length,
                })
          }
          icon={<FactCheckOutlinedIcon />}
          showCancelButton={true}
        />
        <CommonDialog
          handleClose={() => setOpenDeleteDialog(false)}
          handleConfirm={() => onArchive()}
          open={openDeleteDialog}
          title={t(physicalCountModule.ARCHIVE_COUNTING_TITLE)}
          message={t(physicalCountModule.ARCHIVE_COUNTING_MESSAGE)}
          icon={<DeleteOutlineOutlinedIcon />}
          showCancelButton={true}
        />
      </Box>
    </PageBox>
  );
};

export default AuthorizeReCountsCountingDetailDialog;
