import { createSlice } from "@reduxjs/toolkit";
import { getCompletedForGroup } from "core/common/utils/percents";

import { Group } from "core/physicalCount/entities/Group";

import { initialState } from "./state";
import {
  addItemCount,
  addScannedItemCount,
  finishCounting,
  finishItemCounting,
  getCountItemHistory,
  getProductsForKitchen,
  setProductCount,
} from "./thunks";
import { getGroup, getItem } from "./utils";

export const countingSlice = createSlice({
  name: "counting",
  initialState,
  reducers: {
    toggleGroupFilter: (state, action) => {
      let groupFilter = [...state.filters.group];

      if (state.filters.group.includes(action.payload)) {
        groupFilter = groupFilter.filter(
          (groupName) => groupName !== action.payload
        );
      } else {
        groupFilter.push(action.payload);
      }

      state.filters.group = groupFilter;
    },
    setSearch: (state, action) => {
      state.filters.search = action.payload;
    },
    clearState: (state) => {
      state.countingStatus = "IN_PROCESS";
      state = initialState;
    },
  },
  extraReducers(builder) {
    builder
      //getProductsForKitchen
      .addCase(getProductsForKitchen.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getProductsForKitchen.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.countingId = action.payload.productsForKitchen.countingId;
        state.countingStatus = action.payload.productsForKitchen.countingStatus;
        state.groups = action.payload.productsForKitchen.groups.sort(
          (groupA: Group, groupB: Group) =>
            groupA.groupName > groupB.groupName ? 1 : -1
        );
        state.filters.group = action.payload.productsForKitchen.groups.map(
          (group: Group) => group.groupName
        );
      })
      .addCase(getProductsForKitchen.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? null;
      })

      // setProductCount
      .addCase(setProductCount.pending, (state, action) => {
        const { sku } = action.meta.arg;

        if (!state.groups) {
          throw new Error("Groups state is empty");
        }

        const { group, groupIndex } = getGroup(state.groups, sku);

        const { item, itemIndex } = getItem(group.groupItems, sku);

        item.state.status = "loading";
        item.state.error = null;

        state.groups[groupIndex].groupItems[itemIndex] = item;
      })
      .addCase(setProductCount.fulfilled, (state, action) => {
        const { sku, quantity } = action.meta.arg;

        if (!state.groups) {
          throw new Error("Groups are empty");
        }

        const { group, groupIndex } = getGroup(state.groups, sku);

        const { item, itemIndex } = getItem(group.groupItems, sku);

        item.status = "IN_PROCESS";

        if (action.payload.validations.length > 0) {
          item.state.status = "warning";
          item.state.error = action.payload.validations[0].message;
        } else {
          item.state.status = "succeeded";
          item.quantity = quantity;
          item.state.error = null;
          item.userId = action.payload.userId;
        }

        state.groups[groupIndex].groupItems[itemIndex] = item;
        state.groups[groupIndex].completed = getCompletedForGroup(group);
      })
      .addCase(setProductCount.rejected, (state, action) => {
        const { sku } = action.meta.arg;

        if (!state.groups) {
          throw new Error("Groups state is empty");
        }

        const { group, groupIndex } = getGroup(state.groups, sku);

        const { item, itemIndex } = getItem(group.groupItems, sku);

        item.state.completed = false;
        item.state.status = "failed";
        item.state.error = "action.error.message ?? null";

        state.groups[groupIndex].groupItems[itemIndex] = item;
      })

      // finishCounting
      .addCase(finishCounting.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(finishCounting.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (state.countingStatus != null) {
          state.countingStatus = "FINISHED";
        }
      })
      .addCase(finishCounting.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? null;
      })
      // getCountItemHistory
      .addCase(getCountItemHistory.pending, (state, action) => {
        const { sku } = action.meta.arg;

        if (!state.groups) {
          throw new Error("Groups state is empty");
        }

        const { group, groupIndex } = getGroup(state.groups, sku);

        const { item, itemIndex } = getItem(group.groupItems, sku);

        item.history.status = "loading";
        item.history.error = null;

        state.groups[groupIndex].groupItems[itemIndex] = item;
      })
      .addCase(getCountItemHistory.fulfilled, (state, action) => {
        const { sku } = action.meta.arg;

        if (!state.groups) {
          throw new Error("Groups is empty");
        }

        const { group, groupIndex } = getGroup(state.groups, sku);

        const { item, itemIndex } = getItem(group.groupItems, sku);

        item.history = {
          status: "succeeded",
          items: action.payload,
          error: null,
        };

        state.groups[groupIndex].groupItems[itemIndex] = item;
      })
      .addCase(getCountItemHistory.rejected, (state, action) => {
        const { sku } = action.meta.arg;

        if (!state.groups) {
          throw new Error("Groups state is empty");
        }

        const { group, groupIndex } = getGroup(state.groups, sku);

        const { item, itemIndex } = getItem(group.groupItems, sku);

        item.history.status = "failed";
        item.history.error = action.error.message ?? null;

        state.groups[groupIndex].groupItems[itemIndex] = item;
      })
      // addItemCount
      .addCase(addItemCount.pending, (state, action) => {
        const { sku } = action.meta.arg;

        if (!state.groups) {
          throw new Error("Groups state is empty");
        }

        const { group, groupIndex } = getGroup(state.groups, sku);

        const { item, itemIndex } = getItem(group.groupItems, sku);

        item.state.status = "loading";
        item.state.error = null;

        state.groups[groupIndex].groupItems[itemIndex] = item;
      })
      .addCase(addItemCount.fulfilled, (state, action) => {
        const { sku, quantity } = action.meta.arg;

        if (!state.groups) {
          throw new Error("Groups is empty");
        }

        const { group, groupIndex } = getGroup(state.groups, sku);

        const { item, itemIndex } = getItem(group.groupItems, sku);

        item.state.status = "succeeded";
        item.state.error = null;
        item.quantity = action.payload;
        item.status = "IN_PROCESS";

        if (!item.history.items) {
          item.history.items = [];
        }

        item.history.items?.push({
          id: item.history.items.length.toString(),
          type: "SUM",
          countingId: state.countingId!!,
          sku: sku,
          quantity: quantity,
          createdAt: new Date().toString(),
        });

        state.groups[groupIndex].groupItems[itemIndex] = item;
        state.groups[groupIndex].completed = getCompletedForGroup(group);
      })
      .addCase(addItemCount.rejected, (state, action) => {
        const { sku } = action.meta.arg;

        if (!state.groups) {
          throw new Error("Groups state is empty");
        }

        const { group, groupIndex } = getGroup(state.groups, sku);

        const { item, itemIndex } = getItem(group.groupItems, sku);

        item.state.completed = false;
        item.state.status = "failed";
        item.state.error = action.error.message ?? null;

        state.groups[groupIndex].groupItems[itemIndex] = item;
      })
      // addScannedItemCount
      .addCase(addScannedItemCount.pending, (state, action) => {
        if (!state.groups) {
          throw new Error("Groups state is empty");
        }
      })
      .addCase(addScannedItemCount.fulfilled, (state, action) => {
        const { ok, sku, sum, quantity } = action.payload;

        if (!ok) {
          return;
        }

        if (!state.groups) {
          throw new Error("Groups is empty");
        }

        const { group, groupIndex } = getGroup(state.groups, sku);

        const { item, itemIndex } = getItem(group.groupItems, sku);

        item.state.status = "succeeded";
        item.state.error = null;
        item.quantity = sum;
        item.status = "IN_PROCESS";

        if (!item.history.items) {
          item.history.items = [];
        }

        item.history.items?.push({
          id: item.history.items.length.toString(),
          type: "SCANNED",
          countingId: state.countingId!!,
          sku: sku,
          quantity: quantity,
          createdAt: new Date().toString(),
        });

        state.groups[groupIndex].groupItems[itemIndex] = item;
        state.groups[groupIndex].completed = getCompletedForGroup(group);
      })
      .addCase(addScannedItemCount.rejected, (state, action) => {
        if (!state.groups) {
          throw new Error("Groups state is empty");
        }
      })
      // finishItemCounting
      .addCase(finishItemCounting.pending, (state, action) => {})
      .addCase(finishItemCounting.fulfilled, (state, action) => {
        if (!state.groups) {
          throw new Error("Groups state is empty");
        }
        const { sku } = action.meta.arg;
        const { group, groupIndex } = getGroup(state.groups, sku);

        const { item, itemIndex } = getItem(group.groupItems, sku);

        item.state.status = "succeeded";
        item.state.error = null;
        item.state.completed = true;
        item.status = "FINISHED";

        state.groups[groupIndex].groupItems[itemIndex] = item;
        state.groups[groupIndex].completed = getCompletedForGroup(group);
      })
      .addCase(finishItemCounting.rejected, (state, action) => {});
  },
});

export const { toggleGroupFilter, setSearch, clearState } =
  countingSlice.actions;

export const { reducer } = countingSlice;

export default countingSlice;
