import { Grid } from "@mui/material";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import KitchenSelector from "app/components/Kitchen/Selector";
import { useAllCities } from "app/hooks/useAllCities";
import { useAllKitchens } from "app/hooks/useAllKitchens";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import { commons, purchaseOrderLine, transferModule } from "app/i18n/types";
import { getSuppliesByCountry } from "app/store/slices/supplies/thunks";
import { clearTransfersState } from "app/store/slices/transfer";
import { KitchenSlice } from "core/kitchens/entities/Kitchen";
import { Supply } from "core/supplies/entities/Supply";
import { TransferOrder } from "core/transfer/entities/TransferOrder";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SingleTransferTable from "./SingleTransferTable";

export type OrderLine = Supply & {
  quantity: number;
};

interface SingleTransferProps {
  onChangeSingleTransferOrder: (transferOrder: TransferOrder | null) => void;
}

const SingleTransfer: FunctionComponent<SingleTransferProps> = (props) => {
  const { onChangeSingleTransferOrder } = props;

  const navigator = useNavigator();
  const { kitchenId } = navigator.params();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const kitchen = useAllKitchens().byKitchenId(kitchenId ?? "");
  const city = useAllCities().byCode(kitchen?.city ?? "");

  const createSingleTransferOrder = useAppSelector(
    (state) => state.transfer.createSingleTransferOrder
  );

  const suppliesRequest = useAppSelector(
    (state) => state.supplies.suppliesByCountry
  );

  const [destinationCity, setDestinationCity] = useState<string>();
  const [selectedLocation, setSelectedLocation] = useState<KitchenSlice | null>(
    null
  );
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [selectedSupplies, setSelectedSupplies] = useState<Array<OrderLine>>(
    []
  );
  const [errorSameOrigin, setErrorSameOrigin] = useState<boolean>(false);

  const onSelectDestination = (selectedLocation: KitchenSlice | null) => {
    setErrorSameOrigin(false);
    if (!selectedLocation) return;
    if (selectedLocation.kitchenId === kitchenId) {
      setErrorSameOrigin(true);
      return;
    }
    setSelectedLocation(selectedLocation);
  };

  const getSupplies = useCallback(() => {
    if (!kitchen) return;
    dispatch(getSuppliesByCountry(kitchen.country));
  }, [kitchen]);

  const onSupplySelect = useCallback(
    (supply: Supply | null) => {
      if (supply === null) {
        return;
      }

      const orderLine: OrderLine = {
        ...supply,
        quantity: 0,
      };

      if (selectedSupplies.some((supp) => supp.sku === supply.sku)) {
        return;
      }

      setSelectedSupplies((prev) => [...prev, orderLine]);
    },
    [selectedSupplies]
  );

  const onSupplyChange = (supply: OrderLine) => {
    setSelectedSupplies((prev) => {
      const clone = [...prev];
      const supplyIndex = prev.findIndex(
        (prevSupply) => prevSupply.sku === supply.sku
      );

      if (supplyIndex < 0) {
        return prev;
      }

      clone[supplyIndex] = supply;

      return clone;
    });
  };

  const unselectSupplies = useCallback(
    (skus: Array<string>) => {
      if (selectedSupplies === null) {
        return;
      }

      setSelectedSupplies((prevSupplies) =>
        prevSupplies.filter((prevSupply) => !skus.includes(prevSupply.sku))
      );
    },
    [selectedSupplies]
  );

  useEffect(() => {
    getSupplies();
  }, [getSupplies, kitchen]);

  useEffect(() => {
    if (selectedLocation && kitchenId) {
      onChangeSingleTransferOrder({
        kitchenId: selectedLocation?.kitchenId,
        userId: "",
        type: "EXTRA",
        lines: selectedSupplies.map((supply) => ({
          sku: supply.sku,
          quantity: supply.quantity,
          measureUnit: supply.unit,
          sendQuantity: 0,
          packedQuantity: supply.quantity,
          itemGroup: "",
        })),
      });
    }
  }, [selectedSupplies]);

  useEffect(() => {
    if (createSingleTransferOrder.status === "succeeded") {
      setShowSuccessAlert(true);
      setSelectedSupplies([]);
      setSelectedLocation(null);
      onChangeSingleTransferOrder(null);

      const timer = setTimeout(() => {
        setShowSuccessAlert(false);
        dispatch(clearTransfersState());
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [createSingleTransferOrder.status]);

  return (
    <Box>
      {kitchen && (
        <Stack direction="row" gap={2} mb={2}>
          <Card
            elevation={0}
            sx={{ border: "1px solid #D7D3D3", flexBasis: "100%" }}
          >
            <CardContent>
              <Typography variant="h6" gutterBottom mb={2}>
                {t(commons.ORIGIN)}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label={t(commons.CITY)}
                    value={t(city?.name ?? "")}
                    size="small"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={t(commons.LOCATION)}
                    value={t(kitchen.name)}
                    size="small"
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card
            elevation={0}
            sx={{ border: "1px solid #D7D3D3", flexBasis: "100%" }}
          >
            <CardContent>
              <Typography variant="h6" gutterBottom mb={2}>
                {t(transferModule.DESTINATION)}
              </Typography>
              <KitchenSelector
                selected={{
                  country: kitchen.country,
                  city: destinationCity,
                }}
                hide={{
                  country: true,
                }}
                onChange={{
                  city: (value) => setDestinationCity(value?.code),
                  kitchen: (value) =>
                    onSelectDestination(value ? (value as KitchenSlice) : null),
                }}
                extra={{
                  size: "small",
                  disabled: createSingleTransferOrder.status === "loading",
                }}
              />
            </CardContent>
          </Card>
        </Stack>
      )}

      {showSuccessAlert && (
        <Alert sx={{ my: 1 }}>{t(transferModule.SCHEDULE_SUCCESSFULLY)}</Alert>
      )}

      {createSingleTransferOrder.status === "failed" && (
        <Alert sx={{ my: 1 }}>
          {t(purchaseOrderLine.DIALOG_ERROR_MESSAGE)}:{" "}
          {createSingleTransferOrder.error}
        </Alert>
      )}

      {errorSameOrigin && (
        <Alert severity="error" sx={{ my: 1 }}>
          {t(transferModule.ERROR_SINGLE_TRANSFER)}
        </Alert>
      )}

      <Box display="flex" bgcolor={"white"} px={2} py={1} justifyContent="end">
        <Autocomplete
          options={suppliesRequest.supplies ?? []}
          getOptionLabel={(option) => `${option.sku} - ${option.name}`}
          getOptionDisabled={(option) =>
            selectedSupplies.some(
              (selectedSupply) => selectedSupply.sku === option.sku
            )
          }
          sx={{ minWidth: { xs: "100%", md: 400 } }}
          onChange={(_, value) => onSupplySelect(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t(
                suppliesRequest.status === "loading"
                  ? commons.LOADING
                  : commons.FIND_SUPPLY_LABEL
              )}
              size="small"
              sx={{ bgcolor: "white" }}
            />
          )}
          disabled={
            suppliesRequest.supplies === null ||
            suppliesRequest.supplies.length === 0 ||
            suppliesRequest.status === "loading" ||
            selectedLocation === null ||
            createSingleTransferOrder.status === "loading"
          }
          clearOnBlur
          disablePortal
        />
      </Box>

      <SingleTransferTable
        supplies={selectedSupplies}
        loading={
          suppliesRequest.status === "loading" ||
          createSingleTransferOrder.status === "loading"
        }
        unselectSupplies={unselectSupplies}
        onSupplyChange={onSupplyChange}
      />
    </Box>
  );
};

export default SingleTransfer;
