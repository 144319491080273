import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import { useMediaQuery } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import InputAdornment from "@mui/material/InputAdornment";
import Popover from "@mui/material/Popover";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { commons, physicalCountModule } from "app/i18n/types";
import { setSearch, toggleGroupFilter } from "app/store/slices/counting";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

interface PhysicalCountFilterProps {
  onIdleFilterChange: (isIdleFiltered: boolean) => void;
}

const PhysicalCountFilter: React.FC<PhysicalCountFilterProps> = ({
  onIdleFilterChange,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [searchDialogOpen, setSearchDialogOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const groups = useAppSelector((state) => state.counting.groups);
  const groupsFilter = useAppSelector((state) => state.counting.filters.group);
  const searchFilter = useAppSelector((state) => state.counting.filters.search);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isIdleFiltered, setIsIdleFiltered] = useState(false);

  const handleFilterChange = useCallback(() => {
    setIsIdleFiltered((prev) => {
      const newValue = !prev;
      onIdleFilterChange(newValue);
      return newValue;
    });
  }, [onIdleFilterChange]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleSearch = () => {
    if (searchTerm === "") {
      dispatch(setSearch(null));
    } else {
      dispatch(setSearch(searchTerm));
    }
    setSearchTerm(null);
    setSearchDialogOpen(false);
  };

  const handleToggleSelectAll = () => {
    if (!groups?.length) return;

    const allGroupNames = groups.map((group) => group.groupName);
    const isAllSelected = allGroupNames.length === groupsFilter.length;

    const groupsToToggle = isAllSelected
      ? allGroupNames
      : allGroupNames.filter((name) => !groupsFilter.includes(name));

    groupsToToggle.forEach((name) => dispatch(toggleGroupFilter(name)));
  };

  const isAllSelected = groups ? groupsFilter.length === groups.length : false;

  return (
    <Box>
      <Box display="flex" gap={2}>
        <Button
          size="large"
          fullWidth={isMobile}
          variant="outlined"
          sx={{
            bgcolor: "white",
            px: 8,
            position: isMobile ? "fixed" : "static",
            bottom: isMobile ? "0px" : "auto",
            left: isMobile ? "50%" : "auto",
            transform: isMobile ? "translateX(-50%)" : "none",
            zIndex: 1000,
          }}
          onClick={handleClick}
          disabled={searchFilter !== null}
        >
          <Box component={FilterListIcon} mr={2} /> {t(commons.FILTERS)}
          <Avatar
            sx={{
              width: 24,
              height: 24,
              fontSize: 12,
              bgcolor: "primary.main",
              ml: 1,
            }}
          >
            {groupsFilter.length}
          </Avatar>
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => setSearchDialogOpen(true)}
          sx={{
            position: isMobile ? "fixed" : "static",
            right: isMobile ? "0px" : "auto",
            top: isMobile ? "50%" : "auto",
            transform: isMobile ? "translateY(-50%)" : "none",
            zIndex: isMobile ? 1000 : "auto",
          }}
        >
          <SearchIcon />
        </Button>
      </Box>

      <Dialog
        open={searchDialogOpen}
        onClose={() => setSearchDialogOpen(false)}
      >
        <DialogTitle>{t(physicalCountModule.SEARCH_ACTION)}</DialogTitle>

        <DialogContent>
          <Typography mb={2}>
            {t(physicalCountModule.SEARCH_ITEM_NAME)}
          </Typography>

          <TextField
            id="search-input"
            label={t(physicalCountModule.FILTER_SEARCH_PLACEHOLDER)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchTerm ?? ""}
            onChange={(e) => setSearchTerm(e.target.value)}
            variant="outlined"
            size="small"
            sx={{ mb: 2 }}
            fullWidth
            autoComplete="off"
          />

          <Button
            onClick={handleSearch}
            variant="contained"
            size="large"
            fullWidth
          >
            {t(commons.SEARCH)} <ChevronRightIcon />
          </Button>
        </DialogContent>
      </Dialog>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box p={3} width={{ xs: "calc(100vw - 48px)", sm: 330 }}>
          <Typography variant="h5" gutterBottom>
            {t(commons.FILTERS)}
          </Typography>

          <Typography variant="h6" gutterBottom>
            {t(commons.CATEGORIES)}
          </Typography>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAllSelected}
                  onChange={handleToggleSelectAll}
                />
              }
              label={t(physicalCountModule.SELECT_ALL).toUpperCase()}
            />
            {groups !== null &&
              groups.map((group) => (
                <FormControlLabel
                  key={group.groupName}
                  control={
                    <Checkbox
                      checked={groupsFilter.includes(group.groupName)}
                      onChange={() =>
                        dispatch(toggleGroupFilter(group.groupName))
                      }
                    />
                  }
                  label={t(group.groupName)}
                />
              ))}
          </FormGroup>
          <Typography variant="h6" gutterBottom>
            {t(commons.PRODUCTS)}
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={isIdleFiltered}
                onChange={handleFilterChange}
              />
            }
            label={t(physicalCountModule.FILTER_UNCOUNTED_ITEMS).toUpperCase()}
          />
        </Box>
      </Popover>
    </Box>
  );
};

export default PhysicalCountFilter;
