import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import { Alert, Grid, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import CommentDrawer from "app/components/CommentArea/Drawer";
import { commentButtonHeader } from "app/components/CommentArea/util";
import PageBox from "app/components/PageBox";
import PageHeader from "app/components/PageHeader";
import {
  ProgressBarData,
  progressBarDataDefault,
} from "app/components/ProgressBar/ProgressBar";
import BarCodeScanner, {
  OnActionResponse,
} from "app/components/Scanner/BarCode";
import TransferPackingDeleteLabels from "app/components/Transfers/TransferPackingDeleteLabels";
import TransferPackingForKitchenDialog from "app/components/Transfers/TransferPackingForKitchenDialog";
import TransferPackingForSupplyDialog from "app/components/Transfers/TransferPackingForSupplyDialog";
import TransferPickingTable from "app/components/Transfers/TransferPickingTable";
import CommonDialog from "app/components/common/CommonDialog";
import InputSearchCustom from "app/components/common/InputSearchCustom";
import { useAlert } from "app/hooks/useAlert";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import {
  commons,
  kitchenInventoryManagement,
  productionScannedError,
  transferModule,
  transferPackingDialog,
} from "app/i18n/types";
import { scannedOnPackingForSupply } from "app/store/slices/transfer";
import {
  completeTransfersByKitchen,
  existsLabelLog,
  getTransferOrderPickingItems,
  getTransferOrderPickingOrders,
  saveScannedTransfer,
  setTransferOrderAsPacked,
} from "app/store/slices/transfer/thunks";
import { CommentTypeEnum } from "core/comment/entity";
import {
  ProductionLabel,
  ProductionScannedtResponse,
} from "core/productions/entities/Productions";
import {
  PackingKitchen,
  PickingDetailResponse,
  PickingResponse,
} from "core/transfer/entities/TransferOrder";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OnChange, onChangeDefault } from "utils/general";
import { getLabelFromResponse } from "utils/scanner";
import Chips from "./Chips";
import PackingFilters from "./Filters";
import PackingProgress from "./PackingProgress";
import PackingSelectKitchen from "./PackingSelectKitchen";
import PackingViewModes from "./PackingViewModes";

interface PackingAndPackingProps {}

const PackingAndPacking: FunctionComponent<PackingAndPackingProps> = () => {
  const navigator = useNavigator();
  const { kitchenId } = navigator.params();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const alert = useAlert();

  const [loading, setLoading] = useState<boolean>(false);
  const [packingByKitchen, setPackingByKitchen] = useState<PackingKitchen[]>(
    []
  );
  const [packingByItem, setPackingByItem] = useState<PickingDetailResponse[]>(
    []
  );
  const [transferSelected, setTransferSelected] = useState<number | null>(null);

  const [tab, setTab] = useState<number>(0);
  const [progBar, setProgBar] = useState<ProgressBarData>(
    progressBarDataDefault
  );

  const setTransferOrderAsPackedRequest = useAppSelector(
    (state) => state.transfer.setTransferOrderAsPacked
  );

  const [openDeleteLabels, setOpenDeleteLabels] = useState<boolean>(false);
  const [deleteLabels, setDeleteLabels] = useState<ProductionLabel[]>([]);
  const [selectedLine, setSelectedLine] =
    useState<PickingDetailResponse | null>(null);
  const [lines, setLines] = useState<PickingDetailResponse[]>([]);
  const [filteredLines, setFilteredLines] = useState<PickingDetailResponse[]>(
    []
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [chips, setChips] = useState<string[]>([]);
  const [change, setChange] = useState<OnChange>(onChangeDefault);

  const changeStatusByItem = (change: OnChange) => {
    const refinedLIst = packingByItem.map((el) => {
      if (Number(el.sku) === change.id) {
        if (change.label === "status") {
          const value =
            typeof change.value === "number" ? "PENDING" : String(change.value);
          return {
            ...el,
            status: value ?? "PENDING",
            packedQuantity: change.quantity ?? 0,
          };
        }
      }
      return el;
    });
    setPackingByItem(refinedLIst);
  };

  const changeStatusByKitchen = (change: OnChange) => {
    const refinedLIst = packingByKitchen.map((kitchen) => {
      if (change.label === "progress") {
        kitchen.progress = Number(change.value ?? "0");
        kitchen.percentage = ((kitchen.progress * 100) / kitchen.total).toFixed(
          1
        );
      }
      let progress = 0;
      kitchen.lines = kitchen.lines.map((line) => {
        if (line.status === "COMPLETE") progress++;
        if (line.id === change.id) {
          if (change.label === "status") {
            const value =
              typeof change.value === "number"
                ? "PENDING"
                : String(change.value);
            progress++;
            return {
              ...line,
              status: value ?? "PENDING",
              packedQuantity: change.quantity ?? 0,
            };
          }
        }
        return line;
      });
      kitchen.progress = progress;
      kitchen.percentage = ((progress * 100) / kitchen.total).toFixed(1);
      return kitchen;
    });
    setPackingByKitchen(refinedLIst);
  };

  const setStatus = (
    id: number,
    status: "PENDING" | "COMPLETE",
    packedQuantity: number
  ) => {
    setChange({ id, label: "status", value: status, quantity: packedQuantity });
  };

  useEffect(() => {
    if (!change.id) return;
    if (tab === 0) {
      changeStatusByItem(change);
    }
    if (tab === 1) {
      changeStatusByKitchen(change);
    }
  }, [change]);

  const onConfirmTransferOrder = useCallback(() => {
    if (kitchenId) {
      dispatch(setTransferOrderAsPacked(kitchenId));
    }
  }, [dispatch, kitchenId]);

  const onCompleteTransfersByKitchen = useCallback(() => {
    if (kitchenId && transferSelected) {
      setLoading(true);
      dispatch(
        completeTransfersByKitchen({
          transferId: transferSelected,
        })
      ).then((response) => {
        setLoading(false);
        if (response.payload as boolean) {
          getItemsByKitchen();
          alert.successWithMsg({ title: t(transferModule.SUCCESS) });
        }
      });
    }
  }, [dispatch, kitchenId, transferSelected]);

  const navigateToInitialScreen = useCallback(() => {
    if (setTransferOrderAsPackedRequest.status === "succeeded") {
      navigator.toByLocationType(
        `/modules/production-center-inventory-management/${kitchenId}`
      );
    }
  }, [kitchenId, setTransferOrderAsPackedRequest.status]);

  const getProgBarForSupply = useCallback(() => {
    const total = packingByItem.length;
    if (total) {
      const progress = packingByItem.filter(
        (line) => line.status === "COMPLETE"
      ).length;

      const percentage = ((100 * progress) / total).toFixed(1);

      setProgBar({ progress, total, percentage });
    }
  }, [packingByItem]);

  const getProgBarForKitchen = useCallback(() => {
    const total = packingByKitchen.length;
    if (total) {
      const progress = packingByKitchen.filter(
        (line) => line.progress === line.total
      ).length;

      const percentage = ((100 * progress) / total).toFixed(1);

      setProgBar({ progress, total, percentage });
    }
  }, [packingByKitchen]);

  const progressResume = () => {
    if (tab === 0) {
      getProgBarForSupply();
    }
    if (tab === 1) {
      getProgBarForKitchen();
    }
  };

  useEffect(() => {
    progressResume();
  }, [lines]);

  useEffect(() => {
    if (!selectedLine && tab === 0) {
      setTransferSelected(null);
    }
  }, [selectedLine]);

  const getAllItems = () => {
    if (kitchenId) {
      setLoading(true);
      dispatch(
        getTransferOrderPickingItems({
          locationId: kitchenId,
        })
      )
        .then((response) => {
          const payload = response.payload as PickingResponse;
          setPackingByItem(payload.detail);
        })
        .catch(() => {
          setPackingByItem([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getItemsByKitchen = () => {
    if (kitchenId) {
      setLoading(true);
      dispatch(
        getTransferOrderPickingOrders({
          locationId: kitchenId,
        })
      )
        .then((response) => {
          setPackingByKitchen(response.payload as PackingKitchen[]);
        })
        .catch(() => {
          setPackingByKitchen([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setSelectedLine(null);
    setTransferSelected(null);
    setLines([]);
    if (tab === 0) {
      getAllItems();
    }
    if (tab === 1) {
      getItemsByKitchen();
    }
  }, [tab]);

  useEffect(() => {
    if (tab === 0) {
      setLines(packingByItem);
    }
  }, [packingByItem]);

  const getLinesByTransfer = (transferId: number) => {
    const kitchenLines =
      packingByKitchen.find((el) => el.transferOrderId === transferId)?.lines ??
      [];
    setLines(kitchenLines);
  };

  useEffect(() => {
    if (tab === 1) {
      if (!transferSelected) {
        setTransferSelected(packingByKitchen.at(0)?.transferOrderId ?? null);
      } else {
        getLinesByTransfer(transferSelected);
      }
    }
  }, [getLinesByTransfer]);

  useEffect(() => {
    if (tab === 1 && transferSelected) {
      getLinesByTransfer(transferSelected);
    }
  }, [transferSelected]);

  console.log({ transferSelected });

  const getScannedSuccessMsg = (
    response: ProductionScannedtResponse
  ): string => {
    const { quantity, unit, productName, sku } = response;
    if (!openDeleteLabels && tab === 1) {
      const lineFound = lines.find((line) => line.sku === sku);
      if (lineFound) setSelectedLine(lineFound);
    }
    return `${t(
      commons.SCANNED
    )} ${productName} ${quantity} ${unit.toLowerCase()}`;
  };

  const onActionScanFetch = async (code: string) => {
    if (!openDeleteLabels && transferSelected != null) {
      return await dispatch(
        saveScannedTransfer({
          transferOrderId: transferSelected,
          productionLabelId: code,
        })
      );
    }
    return await dispatch(
      existsLabelLog({
        originId: kitchenId ?? "",
        productionLabelId: code,
      })
    );
  };

  const onActionScan = async (code: string): Promise<OnActionResponse> => {
    if (!transferSelected && !openDeleteLabels) {
      return {
        success: false,
        message: productionScannedError.SELECT_THE_KITCHEN,
      };
    }

    const response = await onActionScanFetch(code);
    const res = response.payload as ProductionScannedtResponse;

    if (!res.ok) {
      return { success: false, message: res.error };
    }

    if (!openDeleteLabels) {
      dispatch(
        scannedOnPackingForSupply({
          lineId: res.reasonId,
          productionLabelId: response.meta.arg.productionLabelId,
          productName: res.productName,
          sum: res.sum,
          quantity: res.quantity,
          unit: res.unit,
        })
      );
    } else {
      const label = getLabelFromResponse(res);
      const labelFound = deleteLabels.find((el) => el.id === label.id);
      if (!labelFound) {
        setDeleteLabels([...deleteLabels, label]);
      }
    }

    const message = getScannedSuccessMsg(res);

    return { success: true, message };
  };

  useEffect(() => {
    setDeleteLabels([]);
  }, [openDeleteLabels]);

  const submitTransfers = useCallback(() => {
    let result = true;
    if (tab === 0) {
      result =
        Number(progBar.percentage) < 100 ||
        setTransferOrderAsPackedRequest.status === "loading";
    }
    if (tab === 1) {
      packingByKitchen.forEach((kitchen) => {
        if (kitchen.transferOrderId === transferSelected) {
          let progress = 0;
          kitchen.lines.forEach((line) => {
            if (line.status === "COMPLETE") progress++;
          });
          result = progress !== kitchen.total;
        }
      });
    }
    return result;
  }, [
    packingByKitchen,
    progBar,
    setTransferOrderAsPackedRequest,
    transferSelected,
    tab,
  ]);

  useEffect(() => {
    setFilteredLines(
      lines.filter((line) => {
        return (
          chips.includes(t(line.itemGroup)) &&
          chips.includes(t(line.storage ?? transferModule.UNSPECIFIED))
        );
      })
    );
  }, [lines, chips]);

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [messagesCount, setMessagesCount] = useState<number>(0);

  return (
    <PageBox>
      <PageHeader
        title={kitchenInventoryManagement.TRANSFER_PICKING_PACKING_TITLE}
        subtitle={
          kitchenInventoryManagement.TRANSFER_PICKING_PACKING_DESCRIPTION
        }
        rightArea={[
          {
            variant: "contained",
            startIcon: <FactCheckOutlinedIcon />,
            children: t(transferModule.CONFIRM),
            disabled: submitTransfers() || loading,
            onClick:
              tab === 0 ? onConfirmTransferOrder : onCompleteTransfersByKitchen,
          },
          {
            variant: "outlined",
            color: "error",
            startIcon: <FactCheckOutlinedIcon />,
            children: t(transferModule.DELETE_LABELS),
            disabled: loading,
            onClick: () => setOpenDeleteLabels(true),
          },
          commentButtonHeader(t, messagesCount, !!transferSelected, () =>
            setOpenDrawer(true)
          ),
        ]}
      />

      {transferSelected && kitchenId && (
        <CommentDrawer
          sourceId={transferSelected.toString()}
          kitchenId={kitchenId}
          type={CommentTypeEnum.TRANSFER_ORDER}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          onChangeMessagesCount={setMessagesCount}
        />
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={5}>
          <Paper sx={{ p: 2, height: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6">
                  {t(commons.SUPPLIES_FILTER)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <InputSearchCustom
                  label={commons.FIND_SUPPLY_LABEL}
                  setSearch={setSearchTerm}
                  compact
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <PackingFilters
                  lines={lines}
                  chips={chips}
                  setChips={setChips}
                  compact
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={3}>
          <PackingProgress
            progBar={progBar}
            label={
              tab === 0
                ? commons.PACKING_PROGRESS_SUPPLIES
                : commons.PACKING_PROGRESS_KITCHENS
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <PackingViewModes
            tab={tab}
            setTab={setTab}
            totalSupplies={packingByItem.length}
            totalKitchens={packingByKitchen.length}
          />
        </Grid>
        <Chips chips={chips} setChips={setChips} />
      </Grid>

      <Alert severity="info" sx={{ my: 1 }}>
        {t(
          tab === 0
            ? kitchenInventoryManagement.FOR_SUPPLY_INFO
            : kitchenInventoryManagement.FOR_KITCHEN_INFO
        )}
      </Alert>

      {!loading && (
        <>
          {tab === 0 && (
            <>
              {selectedLine !== null && (
                <TransferPackingForSupplyDialog
                  selectedLine={selectedLine}
                  transferId={transferSelected ?? 0}
                  setSelectedTransfer={setTransferSelected}
                  onClose={() => setSelectedLine(null)}
                  setStatus={setStatus}
                />
              )}
            </>
          )}
          {tab === 1 && (
            <>
              <PackingSelectKitchen
                packingKitchens={packingByKitchen}
                selectedTransferOrderId={transferSelected ?? 0}
                setSelectedTransferOrderId={setTransferSelected}
              />
              {selectedLine !== null && transferSelected !== null && (
                <TransferPackingForKitchenDialog
                  selectedLine={selectedLine}
                  transferId={transferSelected}
                  onClose={() => setSelectedLine(null)}
                  setStatus={setStatus}
                />
              )}
            </>
          )}
          <TransferPackingDeleteLabels
            open={openDeleteLabels}
            deleteLabels={deleteLabels}
            originId={kitchenId ?? ""}
            onClose={() => setOpenDeleteLabels(false)}
          />
        </>
      )}

      <TransferPickingTable
        loading={loading}
        lines={filteredLines}
        searchTerm={searchTerm}
        onEdit={(line: PickingDetailResponse) => setSelectedLine(line)}
      />

      <CommonDialog
        open={setTransferOrderAsPackedRequest.status === "succeeded"}
        title={t(transferPackingDialog.CONFIRMED_TRANSFERS_TITLE)}
        message={t(transferPackingDialog.CONFIRMED_TRANSFERS_MESSAGE)}
        icon={
          <CheckCircleOutlinedIcon
            sx={{
              height: "80px",
              width: "80px",
              paddingRight: "5px",
              color: "success.main",
            }}
          />
        }
        showCancelButton={false}
        handleConfirm={navigateToInitialScreen}
        handleClose={() => {}}
      />

      <BarCodeScanner onAction={onActionScan} />
    </PageBox>
  );
};

export default PackingAndPacking;
