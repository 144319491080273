import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSRowData,
  KOSTabs,
} from "@foodology-co/alejandria";
import { InsertChartOutlined } from "@mui/icons-material";
import LegendToggleIcon from "@mui/icons-material/LegendToggle";
import ListAltIcon from "@mui/icons-material/ListAlt";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import { Alert, Box, Button, Chip } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useNavigator } from "app/hooks/useNavigator";
import {
  commons,
  supplyingManagement,
  theoricalInventoryModule,
} from "app/i18n/types";
import { FdlgyCountry, inventoryAccuracyConfig } from "config/accuracy";
import { AmplitudeEvent, logEvent } from "core/common/utils/analytics";
import { TheoreticalInventoryComparisonByKitchen } from "core/theoricalInventory/entities/ThereticalInventory";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAlertColor } from "utils/general";
import { getTheoricalInventoryConfig } from "utils/generalUI";
import DataAlertIcon from "../../../utils/icons/DataAlertIcon";
import MonitoringIcon from "../../../utils/icons/MonitoringIcon";

interface Props {
  items: TheoreticalInventoryComparisonByKitchen[];
  loading: boolean;
  country: FdlgyCountry;
  kitchenId: string;
  accuracy?: number;
}

const TheoreticalInventoryComparisonTable: FunctionComponent<Props> = (
  props
) => {
  const { t } = useTranslation();
  const { items, loading, country, kitchenId, accuracy } = props;
  const [tab, setTab] = useState<number>(-1);
  const [filteredItems, setFilteredItems] = useState<
    TheoreticalInventoryComparisonByKitchen[]
  >([]);
  const navigator = useNavigator();

  const navigateToInventory = (sku: string) => {
    navigator.toByLocationType(`/theorical-inventory/${kitchenId}/${sku}`);
  };

  let numberZeroDesviation = 0;
  let numberTolerableDesviation = 0;
  let numberHighDesviation = 0;
  let numberExcludedItems = 0;

  items.forEach((item) => {
    const config = getTheoricalInventoryConfig(item, country);
    const { percentage, percentageMax, percentageMin } = config;
    const exceptions = inventoryAccuracyConfig.find(
      (i) => i.country === country
    )?.exceptions;
    if (percentage === 0) numberZeroDesviation++;
    else if (percentageMin <= percentage && percentage <= percentageMax)
      numberTolerableDesviation++;
    else if (percentage < percentageMin || percentageMax < percentage)
      numberHighDesviation++;
    if (
      exceptions?.skus.includes(item.sku) ||
      exceptions?.groups.includes(item.group)
    )
      numberExcludedItems++;
  });

  const getDifferenceIconChip = useCallback(
    (item: TheoreticalInventoryComparisonByKitchen) => {
      const config = getTheoricalInventoryConfig(item, country);
      const { color, percentage, icon } = config;

      return (
        <Chip
          sx={{
            pl: "12px",
            fontSize: "15px",
          }}
          icon={icon}
          label={percentage + " %"}
          color={color}
        />
      );
    },
    []
  );

  useEffect(() => {
    const newItems = items.filter((item) => {
      const config = getTheoricalInventoryConfig(item, country);
      const { percentage, percentageMax, percentageMin } = config;
      const exeptions = inventoryAccuracyConfig.find(
        (i) => i.country === country
      )?.exceptions;
      switch (tab) {
        case 0:
          return true;
        case 1:
          return percentage === 0;
        case 2:
          return percentageMin <= percentage && percentage <= percentageMax;
        case 3:
          return percentage < percentageMin || percentageMax < percentage;
        case 4:
          return (
            exeptions?.skus.includes(item.sku) ||
            exeptions?.groups.includes(item.group)
          );
      }
    });
    setFilteredItems(newItems);
  }, [items, tab]);

  const getHeader = (): KOSBaseTableHeader[] => [
    {
      label: t(commons.SKU),
      field: "sku",
    },
    {
      label: t(commons.SUPPLY),
      field: "productName",
    },
    {
      label: t(commons.GROUP),
      field: "group",
    },
    {
      label: t(commons.UNITS),
      field: "unit",
      align: "center",
    },
    {
      label: t(theoricalInventoryModule.THEORETICAL_AMOUNT),
      field: "theoreticalQuantity",
      type: "number",
      align: "right",
    },
    {
      label: t(theoricalInventoryModule.ACTUAL_AMOUNT),
      field: "realQuantity",
      type: "number",
      align: "right",
    },
    {
      label: t(theoricalInventoryModule.DIFFERENCE),
      field: "difference",
      type: "number",
      align: "right",
    },
    {
      label: t(theoricalInventoryModule.DEVIATION),
      align: "center",
      component: (item: KOSRowData) =>
        getDifferenceIconChip(item as TheoreticalInventoryComparisonByKitchen),
    },
    {
      label: t(commons.DETAILS),
      align: "center",
      component: (item: KOSRowData) => (
        <Button
          onClick={() => {
            logEvent(AmplitudeEvent.ViewProductDetailComparison, {
              sku: item.sku,
              productName: item.productName,
              group: item.group,
              kitchenId: kitchenId,
            });
            navigateToInventory(item.sku);
          }}
          variant="outlined"
          startIcon={<ListAltIcon />}
          sx={{
            boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.3)",
          }}
        >
          {t(commons.SHOW_DETAILS)}
        </Button>
      ),
    },
  ];

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Paper elevation={0}>
        <KOSTabs
          tabs={[
            {
              children: {
                startIcon: <QueryStatsIcon />,
                text: t(supplyingManagement.ALL_ITEMS),
                chipLabel: items.length,
              },
            },
            {
              children: {
                startIcon: <MonitoringIcon color="#011A5B" />,
                text: t(theoricalInventoryModule.DEVIATION_ZERO),
                chipLabel: numberZeroDesviation,
              },
            },
            {
              children: {
                startIcon: <LegendToggleIcon />,
                text: t(theoricalInventoryModule.DEVIATION_LOW),
                chipLabel: numberTolerableDesviation,
              },
            },
            {
              children: {
                startIcon: <TrendingDownIcon />,
                text: t(theoricalInventoryModule.DEVIATION_HIGH),
                chipLabel: numberHighDesviation,
              },
            },
            {
              children: {
                startIcon: <DataAlertIcon />,
                text: t(theoricalInventoryModule.SUPPLIES_EXCLUDED),
                chipLabel: numberExcludedItems,
              },
            },
          ]}
          onChange={setTab}
        />
      </Paper>
      {accuracy && (
        <Alert
          icon={<InsertChartOutlined />}
          severity={getAlertColor(accuracy * 100)}
          sx={{
            bgcolor: "#F1EFEF",
          }}
        >
          <strong>{t(theoricalInventoryModule.RESULT)}:</strong>{" "}
          {t(theoricalInventoryModule.ACCURACY_RESULT, {
            accuracy: Math.round(accuracy * 100),
          })}
        </Alert>
      )}
      <Box>
        <KOSBaseTable
          columns={getHeader()}
          rows={{
            data: filteredItems,
            total: filteredItems.length,
            loading,
          }}
          pagination={{
            enable: true,
          }}
        />
      </Box>
    </Box>
  );
};

export default TheoreticalInventoryComparisonTable;
