import { KOSTabs } from "@foodology-co/alejandria";
import {
  AvTimer,
  CalendarMonth,
  FactCheckOutlined,
  Numbers,
} from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PageBox from "app/components/PageBox";
import PageHeader from "app/components/PageHeader";
import ConfirmSupplyingModal from "app/components/Productions/Supply/Receipt/ConfirmSupplyingModal";
import CreateSupplyingModal from "app/components/Productions/Supply/Receipt/CreateSupplyingModal";
import SupplyReceptionTable from "app/components/Productions/Supply/Receipt/Details/SupplyReceptionTable";
import SupplyRequestTable from "app/components/Productions/Supply/Receipt/Details/SupplyRequestTable";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import { commons, supplyReception } from "app/i18n/types";
import {
  create,
  getWithDetails,
} from "app/store/slices/productions/Supply/thunks";
import { getSuppliesByCountry } from "app/store/slices/supplies/thunks";
import { convertLocalDate } from "app/utils/dateUtils";
import { SlotEnum } from "core/productions/entities/Plan";
import {
  DetailToSave,
  ProductionSupplyWithDetails,
} from "core/productions/entities/Supply";
import { Supply } from "core/supplies/entities/Supply";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface ProductionSupplyReceptionDetailsProps {}

const ProductionSupplyReceptionDetails: FunctionComponent<
  ProductionSupplyReceptionDetailsProps
> = () => {
  const navigator = useNavigator();
  const { kitchenId, supplyId } = navigator.params();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [tab, setTab] = useState<number>(0);

  const [productionDetails, setProductionDetails] =
    useState<ProductionSupplyWithDetails>();

  const [search, setSearch] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [supplies, setSupplies] = useState<Supply[]>([]);
  const [newDetails, setNewDetails] = useState<DetailToSave[]>([]);

  const kitchen = useAppSelector((state) => state.global.kitchens.data).find(
    (kitchen) => kitchen.kitchenId === kitchenId
  );

  const getData = async () => {
    if (supplyId) {
      setLoading(true);
      const response = await dispatch(getWithDetails({ supplyId }));
      const data = response.payload as ProductionSupplyWithDetails;
      setProductionDetails(data);
      setLoading(false);
    }
  };

  const getSupplies = async () => {
    if (!kitchen) return;
    const result = await dispatch(getSuppliesByCountry(kitchen.country));
    const tempSupplies = result.payload as Supply[];
    setSupplies(tempSupplies);
  };

  const handleSelect = (supply: Supply | null) => {
    if (supply && productionDetails) {
      setNewDetails((prevState) => [
        ...prevState,
        {
          sku: supply.sku,
          name: supply.name,
          measureUnit: supply.unit,
          packedQuantity: 0,
          receivedQuantity: 0,
          quantity: 0,
          supplyId: productionDetails.id,
          timeSlot: SlotEnum.AM,
        },
      ]);
    }
  };

  const onCreate = async () => {
    if (kitchenId) {
      setLoading(true);
      setOpenCreateModal(false);
      await dispatch(
        create({
          productionDate: new Date(),
          locationId: kitchenId,
          details: newDetails,
        })
      );
      getData();
      setTab(0);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [supplyId]);

  useEffect(() => {
    getSupplies();
  }, [kitchen]);

  return (
    <PageBox>
      <PageHeader
        title={t(supplyReception.TITLE)}
        rightArea={[
          tab === 0
            ? {
                variant: "contained",
                color: "primary",
                startIcon: <FactCheckOutlined />,
                children: t(supplyReception.CONFIRM_SUPPLY),
                onClick: () => setOpenConfirmModal(true),
              }
            : {
                variant: "contained",
                color: "primary",
                startIcon: <FactCheckOutlined />,
                children: t(supplyReception.REQUEST_SUPPLIES_TAB),
                onClick: () => setOpenCreateModal(true),
                disabled: newDetails.length === 0,
              },
        ]}
      />
      <Grid container spacing={2} my={2}>
        {tab === 0 && (
          <Grid item sm={8}>
            <Paper
              sx={{ display: "flex", justifyContent: "space-between", p: 2 }}
              elevation={0}
            >
              <Box display="flex" alignItems="center" gap={2}>
                <Numbers />
                <Box>
                  <Typography color="text.secondary">
                    {t(supplyReception.REQUISITION_NUMBER_LABEL)}
                  </Typography>
                  <Typography>{productionDetails?.id}</Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" gap={2}>
                <CalendarMonth />
                <Box>
                  <Typography color="text.secondary">
                    {t(supplyReception.DATE_LABEL)}
                  </Typography>
                  {productionDetails && (
                    <Typography>
                      {convertLocalDate(
                        productionDetails?.productionDate.toString(),
                        "date"
                      )}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Paper>
          </Grid>
        )}
        <Grid item sm={4}>
          <Paper sx={{ p: 2 }} elevation={0}>
            {tab === 0 && (
              <TextField
                label={t(commons.FIND_SUPPLY_LABEL)}
                onChange={(e) => setSearch(e.target.value)}
                fullWidth
              />
            )}
            {tab === 1 && (
              <Autocomplete
                fullWidth
                onChange={(_, value) => {
                  handleSelect(value);
                }}
                options={supplies}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label={t(commons.FIND_SUPPLY_LABEL)} />
                )}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
      <KOSTabs
        onChange={setTab}
        tabs={[
          {
            children: {
              text: t(supplyReception.RECEIVE_SUPPLIES_TAB),
              startIcon: <CalendarMonth />,
            },
          },
          {
            children: {
              text: t(supplyReception.REQUEST_SUPPLIES_TAB),
              startIcon: <AvTimer />,
            },
          },
        ]}
      />
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          {tab === 0 && productionDetails && (
            <SupplyReceptionTable
              details={productionDetails?.details}
              search={search}
              onRefresh={getData}
            />
          )}
          {tab === 1 && (
            <SupplyRequestTable
              newDetails={newDetails}
              setNewDetails={setNewDetails}
            />
          )}
        </>
      )}
      <ConfirmSupplyingModal
        productionSupply={productionDetails}
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
      />
      <CreateSupplyingModal
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        onConfirm={onCreate}
      />
    </PageBox>
  );
};

export default ProductionSupplyReceptionDetails;
