import { KOSEmptyState } from "@foodology-co/alejandria";
import { Rule } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Chip, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { commons, purchaseOrderModule } from "app/i18n/types";
import { ReceiptOrder } from "core/purchaseOrders/entities/ReceiptOrder";
import { FunctionComponent, MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { compareSearchText } from "utils/general";
import ReceiptTypeSelector from "../Receipts/TypeSelector";
import BoxTitleTable from "./BoxTitleTable";
import PurchaseOrdersTableSkeleton from "./PurchaseOrdersSkeleton";

interface PurchaseOrdersProps {
  items: ReceiptOrder[] | null;
  loading: boolean;
  onClick: (
    event: MouseEvent<HTMLButtonElement>,
    purchaseOrder: ReceiptOrder
  ) => void;
  filter: string;
}

const PurchaseOrders: FunctionComponent<PurchaseOrdersProps> = (props) => {
  const { items, loading, onClick, filter } = props;
  const { t } = useTranslation();

  const [filteredItems, setFilteredItems] = useState<ReceiptOrder[]>([]);
  const [selectedType, setSelectedType] = useState<string>("ALL");

  const filterValidator = (el: ReceiptOrder): boolean => {
    if (!filter) return true;
    return (
      compareSearchText(el.vendorName, filter) ||
      compareSearchText(el.receiptNo, filter) ||
      compareSearchText(el.sourceId, filter)
    );
  };

  useEffect(() => {
    setFilteredItems(items?.filter((el) => filterValidator(el)) ?? []);
  }, [items, filter]);

  const filterReceiptType = (type: string) => {
    setSelectedType(type);
    if (type !== "ALL") {
      setFilteredItems(items?.filter((el) => el.type === type) ?? []);
    } else {
      setFilteredItems(items?.filter((el) => filterValidator(el)) ?? []);
    }
  };

  return (
    <Box>
      <Grid container spacing={2} pb={3} alignItems="center">
        <Grid item md={6} lg={4} xs={12}>
          <ReceiptTypeSelector
            setSelected={filterReceiptType}
            selected={selectedType}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650, maxHeight: 600 }}
          aria-label="inventory"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <BoxTitleTable data={t(commons.ORDER_ID)} />
              <BoxTitleTable data={t(commons.RECEIPT_ID)} />
              <BoxTitleTable data={t(commons.SUPPLIER)} />
              <BoxTitleTable data={t(commons.ORDER_DATE)} />
              <BoxTitleTable data={t(commons.DELIVERY_DATE)} />
              <BoxTitleTable data={t(commons.TYPE)} />
              <BoxTitleTable data={t(purchaseOrderModule.STATUS_RECEIPT)} />
              <BoxTitleTable data={t(commons.ACTIONS)} />
            </TableRow>
          </TableHead>

          {loading && <PurchaseOrdersTableSkeleton columns={8} />}

          <TableBody>
            {filteredItems.map((row, i) => (
              <TableRow
                key={row.receiptNo}
                sx={{ backgroundColor: i % 2 === 0 ? "#FFFFFF" : "#F4F4F4" }}
              >
                <TableCell align="left">
                  <Typography fontWeight={300} color="#454B54">
                    {row.sourceId}
                  </Typography>
                </TableCell>

                <TableCell align="left">
                  <Typography fontWeight={300} color="#454B54">
                    {row.receiptNo}
                  </Typography>
                </TableCell>

                <TableCell align="left">
                  <Box display="flex" alignItems="center" gap={2}>
                    <Typography fontWeight={300} color="#454B54">
                      {row.vendorName}
                    </Typography>
                  </Box>
                </TableCell>

                <TableCell align="left">
                  <Typography fontWeight={300} color="#454B54">
                    {row.createAt.toString()}
                  </Typography>
                </TableCell>

                <TableCell align="left">
                  <Typography fontWeight={300} color="#454B54">
                    {row.expectedAt.toString()}
                  </Typography>
                </TableCell>

                <TableCell align="left">
                  <Typography fontWeight={300} color="#454B54">
                    {row.isReturn ? (
                      <strong>{t(commons.RETURNED)}</strong>
                    ) : (
                      t(
                        `commons.receiptType.${row.type
                          .toUpperCase()
                          .replaceAll(" ", "_")}`
                      )
                    )}
                  </Typography>
                </TableCell>
                <TableCell align="left" sx={{ textTransform: "uppercase" }}>
                  {row.partial ? (
                    <Chip
                      icon={<Rule />}
                      label={t(commons.PARTIAL)}
                      color="info"
                    />
                  ) : (
                    <Chip
                      icon={<InfoOutlinedIcon />}
                      label={t(commons.PENDING)}
                      color="warning"
                    />
                  )}
                </TableCell>

                <TableCell align="left">
                  <Button onClick={(e) => onClick(e, row)} variant="outlined">
                    {t(commons.RECEIVE)}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {!loading && !filteredItems.length && (
              <TableRow>
                <TableCell align="center" colSpan={8}>
                  <KOSEmptyState
                    icon={InfoOutlinedIcon}
                    message={
                      !items?.length
                        ? t(commons.TABLE_EMPTY)
                        : t(commons.NO_SEARCH_RESULTS, {
                            search: filter,
                          })
                    }
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PurchaseOrders;
