import MonetizationOnRoundedIcon from "@mui/icons-material/MonetizationOnRounded";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { moduleConfig } from "config/module";
import React from "react";
import { ObjectParams } from "./http";
import AuthorizationRecountIcon from "./icons/AuthorizationRecountIcon";
import CountDownloadIcon from "./icons/CountDownloadIcon";
import CreatePurchaseOrderIcon from "./icons/CreatePurchaseOrderIcon";
import DailyMovsIcon from "./icons/DailyMovsIcon";
import InventoryReviewIcon from "./icons/InventoryReviewIcon";
import KitchenAdjustmtnsAuthorizationIcon from "./icons/KitchenAdjustmtnsAuthorizationIcon";
import KitchenAuthorizeTransfersIcon from "./icons/KitchenAuthorizeTransfersIcon";
import KitchenInventoryAdjustmentIcon from "./icons/KitchenInventoryAdjustmentIcon";
import KitchenInventoryIcon from "./icons/KitchenInventoryIcon";
import KitchenLoadTransfersIcon from "./icons/KitchenLoadTransfersIcon";
import KitchenPacktransfersIcon from "./icons/KitchenPacktransfersIcon";
import KitchenPurchaseOrdersReceptionIcon from "./icons/KitchenPurchaseOrdersReceptionIcon";
import MassiveAdjustsIcon from "./icons/MassiveAdjustsIcon";
import MicrosoftIcon from "./icons/MicrosoftIcon";
import ProductionLabelLogIcon from "./icons/ProductionLabelLogIcon";
import ProductionRecordsIcon from "./icons/ProductionRecordsIcon";
import ProductionSupplyReceptionIcon from "./icons/ProductionSupplyReceptionIcon";
import PurchaseOrderConciliationIcon from "./icons/PurchaseOrderConciliationIcon";
import PurchaseOrderIcon from "./icons/PurchaseOrderIcon";
import SchedulePhysicalCountingIcon from "./icons/SchedulePhysicalCountingIcon";
import SingleAdjustsIcon from "./icons/SingleAdjustsIcon";
import SuppliesManagementIcon from "./icons/SuppliesManagementIcon";
import SupplyingCP from "./icons/SupplyingCP";
import TheoreticalInventorycomparisonIcon from "./icons/TheoreticalInventorycomparisonIcon";
import TransferOrderIcon from "./icons/TransferOrderIcon";
import VendorSupplyIcon from "./icons/VendorSupplyIcon";
import { TypeOfRole } from "./role";
import { getRoutePaths, refineLink } from "./route";

export const getModuleIcon = (
  moduleId: string
): React.ReactElement | undefined => {
  switch (moduleId) {
    case "account":
      return <MonetizationOnRoundedIcon fontSize="large" />;
    case "kitchenInventory":
      return <KitchenInventoryIcon />;
    case "kitchenPurchaseOrdersReception":
      return <KitchenPurchaseOrdersReceptionIcon />;
    case "kitchenInventoryAdjustment":
    case "productReplacement":
      return <KitchenInventoryAdjustmentIcon />;
    case "productionRecords":
      return <ProductionRecordsIcon />;
    case "kitchenLoadTransfers":
      return <KitchenLoadTransfersIcon />;
    case "kitchenAuthorizeTransfers":
      return <KitchenAuthorizeTransfersIcon />;
    case "kitchenAdjustmtnsAuthorization":
      return <KitchenAdjustmtnsAuthorizationIcon />;
    case "kitchenPacktransfers":
      return <KitchenPacktransfersIcon />;
    case "inventoryReview":
      return <InventoryReviewIcon />;
    case "theoreticalInventorycomparison":
      return <TheoreticalInventorycomparisonIcon />;
    case "countDownload":
      return <CountDownloadIcon />;
    case "dailyMovs":
      return <DailyMovsIcon />;
    case "suppliesManagement":
      return <SuppliesManagementIcon />;
    case "massiveAdjusts":
      return <MassiveAdjustsIcon />;
    case "schedulePhysicalCounting":
      return <SchedulePhysicalCountingIcon />;
    case "singleAdjusts":
      return <SingleAdjustsIcon />;
    case "authorizationRecount":
    case "productReplacementAuthorize":
      return <AuthorizationRecountIcon />;
    case "purchaseOrder":
      return <PurchaseOrderIcon />;
    case "createPurchaseOrder":
      return <CreatePurchaseOrderIcon />;
    case "productionLabelLog":
      return <ProductionLabelLogIcon />;
    case "transferOrders":
      return <TransferOrderIcon />;
    case "purchaseOrderConciliation":
      return <PurchaseOrderConciliationIcon />;
    case "supplyingCP":
      return <SupplyingCP />;
    case "kitchenProductionSupplyReception":
      return <ProductionSupplyReceptionIcon />;
    case "productionLiberation":
      return <ProductionSupplyReceptionIcon />;
    case "bcIntegration":
      return <MicrosoftIcon />;
    case "vendorSupply":
      return <VendorSupplyIcon />;
    case "invoices":
      return <ReceiptIcon fontSize="large" />;
    default:
      return undefined;
  }
};

export type InventoryModule = {
  moduleId: string;
  moduleName: string;
  description?: string;
  link?: string;
  locationType?: string;
  submodules?: InventoryModule[];
};

const getLink = (link?: string): string => {
  if (!link?.includes(":locationType")) {
    return `/:locationType${link}`;
  }
  return link ?? "";
};

export const refineModules = (role: TypeOfRole): InventoryModule[] => {
  if (!role) return [];
  const result: InventoryModule[] = [];
  const links = getRoutePaths([], role);
  moduleConfig.forEach((module: InventoryModule) => {
    const submodules: InventoryModule[] = [];
    module.submodules?.forEach((submodule: InventoryModule) => {
      const link = getLink(submodule.link);
      const validate = links.includes(link);
      if (validate) {
        submodules.push({ ...submodule, link });
      }
    });
    if (submodules.length) {
      result.push({ ...module, submodules });
    }
  });
  return result;
};

export const getModuleLink = (inventoryModule: string) => {
  const link = `/modules/${inventoryModule}`;
  switch (inventoryModule) {
    case "kitchen-inventory-management":
      return `${link}/:kitchenId`;
    case "production-center-inventory-management":
      return `${link}/:cpId`;
    default:
      return link;
  }
};

export const getNavs = (
  navs: InventoryModule[],
  role: TypeOfRole,
  location: string,
  params: ObjectParams,
  modules?: InventoryModule[],
  locationType?: string
): InventoryModule[] => {
  const modulesTmp = modules ?? refineModules(role);
  const pathname = location.replace(/%20/g, " ");
  let ended = false;

  modulesTmp.forEach((module) => {
    const paramsRefined = {
      ...(!params ? {} : params),
      locationType: locationType ?? module.locationType,
    } as ObjectParams;
    if (!!module.submodules?.length && !navs.length) {
      getNavs(
        navs,
        role,
        pathname,
        paramsRefined,
        module.submodules,
        paramsRefined["locationType"]
      );
    }
    const link = module.link
      ? refineLink(module.link, paramsRefined)
      : getModuleLink(module.moduleId);
    if (navs.length && !ended) {
      navs.push({ moduleId: "", moduleName: module.moduleName, link });
      ended = true;
    }
    if (pathname === link && !ended) {
      navs.push({ moduleId: "", moduleName: module.moduleName, link });
      ended = true;
    }
  });

  return modules ? navs : navs.reverse();
};

export interface ModuleResume {
  titles: string[];
  path: string;
}

export const getModuleResume = (): ModuleResume[] => {
  const result: ModuleResume[] = [];
  moduleConfig.forEach((module) => {
    module.submodules?.forEach((submodule) => {
      result.push({
        titles: [module.moduleName, submodule.moduleName],
        path: submodule.link ?? "",
      });
    });
  });
  return result;
};
