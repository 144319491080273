interface ModuleConfig {
  url: string;
  apiBaseUrl: string;
}

export interface Firebase {
  apiKey: string;
  appId: string;
  authDomain: string;
  messagingSenderId: string;
  projectId: string;
  storageBucket: string;
  vapidKey: string;
}

export interface AppConfig {
  env: string;
  firebase: Firebase;
  kitchenDisplay: ModuleConfig;
  kitchenInventory: ModuleConfig;
  bcIntegrator: ModuleConfig;
  bistroKeeper: ModuleConfig;
  s3Url: string;
}

const appConfig: AppConfig = {
  env: process.env.REACT_APP_ENV ?? "development",
  firebase: JSON.parse(process.env.REACT_APP_FIREBASE ?? "{}") as Firebase,
  kitchenDisplay: {
    url: process.env.REACT_APP_KITCHEN_DISPLAY_URL ?? "",
    apiBaseUrl: "",
  },
  kitchenInventory: {
    url: process.env.REACT_APP_KITCHEN_INVENTORY_URL ?? "",
    apiBaseUrl: process.env.REACT_APP_KITCHEN_INVENTORY_API_BASE_URL ?? "",
  },
  s3Url: process.env.REACT_APP_S3_URL ?? "",
  bcIntegrator: {
    url: "",
    apiBaseUrl: process.env.REACT_APP_BC_INTEGRATOR_API_URL ?? "",
  },
  bistroKeeper: {
    url: "",
    apiBaseUrl: process.env.REACT_APP_BISTRO_KEEPER_API_BASE_URL ?? "",
  },
};

export default appConfig;
