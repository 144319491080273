import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import DailyAdjustmentTable from "app/components/DailyAdjustments/DailyAdjustmentTable";
import DailyAdjustmentsRegister from "app/components/DailyAdjustments/Register";
import PageBox from "app/components/PageBox";
import PageHeader from "app/components/PageHeader/PageHeader";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import { commons } from "app/i18n/types";
import { clearDailyAdjustmensForReview } from "app/store/slices/dailyAdjustments";
import {
  getAdjustmentsByKitchenForReview,
  getListProducts,
} from "app/store/slices/dailyAdjustments/thunks";
import { RegisterOption, registerOptions } from "config/dailyAdjustment";
import { AdjustmentRecord } from "core/dailyAdjustments/entities/Adjustment";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMount, useUnmount } from "react-use";
import DailyAdjusmentsSummary from "./Summary";

interface Props {}

const DailyAdjustmentsPage: FunctionComponent<Props> = () => {
  const navigator = useNavigator();
  const { kitchenId } = navigator.params();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dailyAdjustments = useAppSelector((state) => state.dailyAdjustments);
  const [adjustments, setAdjustments] = useState<AdjustmentRecord[]>([]);
  const [selectedOption, setSelectedOption] = useState<RegisterOption>(
    registerOptions[0]
  );
  const [items, setItems] = useState<AdjustmentRecord[]>([]);

  const typeLabel = t(`adjustmentType.${selectedOption.type}`).toLowerCase();

  useEffect(() => {
    setAdjustments(
      dailyAdjustments.forReview.data.map((el) => {
        return { ...el, createdAt: new Date(el.createdAt) };
      }) ?? []
    );
  }, [dailyAdjustments.forReview.data]);

  useEffect(() => {
    if (kitchenId) {
      dispatch(getListProducts({ kitchenId }));
    }
  }, [dispatch, kitchenId]);

  useEffect(() => {
    setItems(adjustments.filter((el) => el.typeLoss === selectedOption.type));
  }, [selectedOption, adjustments]);

  const getData = () => {
    if (!kitchenId) return;
    dispatch(
      getAdjustmentsByKitchenForReview({
        kitchenId: kitchenId ?? "",
      })
    );
  };

  useMount(() => {
    getData();
  });

  useUnmount(() => {
    dispatch(clearDailyAdjustmensForReview());
  });

  return (
    <PageBox>
      <PageHeader
        title={`${t(commons.RECORD)} ${t(commons.OF)} ${typeLabel}`}
        rightArea={[
          {
            children: t(commons.UPDATE),
            variant: "contained",
            size: "large",
            startIcon: <FactCheckOutlinedIcon />,
            color: "inherit",
            sx: { bgcolor: "white" },
            onClick: getData,
          },
        ]}
      />

      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <DailyAdjusmentsSummary
            items={adjustments}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1 }}
          >
            <DailyAdjustmentsRegister
              selectedOption={selectedOption}
              kitchenId={kitchenId ?? ""}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <DailyAdjustmentTable items={items} />
        </Grid>
      </Grid>
    </PageBox>
  );
};

export default DailyAdjustmentsPage;
