import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Box } from "@mui/material";
import CommonDialog from "app/components/common/CommonDialog";
import PageBox from "app/components/PageBox";
import PageHeader from "app/components/PageHeader/PageHeader";
import ArchiveCountsDialog from "app/components/PhysicalCount/Archive/Dialog";
import PhysicalCountScheduleStepper from "app/components/PhysicalCount/Schedule";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { commons, physicalCountSchedule } from "app/i18n/types";
import { createSchedulePhysicalCounting } from "app/store/slices/counting/thunks";
import {
  CountingType,
  MassiveData,
  MassiveDataToSent,
} from "core/physicalCount/entities/Counting";
import { Supply } from "core/supplies/entities/Supply";
import React, { FunctionComponent, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CountingTypeCode,
  getIsRecountOrMassive,
  PhysicalCountScheduleLocation,
} from "utils/general";
import { sxModalIcon } from "utils/generalUI";
import { TypeOfRole } from "utils/role";

const PhysicalCountSchedule: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.session.user.data);
  const [selectedCountingType, setSelectedCountingType] =
    useState<CountingType | null>(null);
  const [dates, setDates] = useState<string[]>([]);
  const [locations, setLocations] = useState<PhysicalCountScheduleLocation[]>(
    []
  );
  const [selectedSupplies, setSelectedSupplies] = useState<Supply[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [errorOnFinish, setErrorOnFinish] = useState<boolean>(false);
  const [massiveData, setMassiveData] = useState<MassiveData>({});
  const [name, setName] = useState<string>("");
  const [openArchiveDialog, setOpenArchiveDialog] = useState<boolean>(false);

  const getCountingType = (code: string): CountingTypeCode => {
    if (
      [
        CountingTypeCode.daily_individual,
        CountingTypeCode.daily_massive,
      ].includes(code as CountingTypeCode)
    ) {
      return CountingTypeCode.daily;
    }
    if (
      [
        CountingTypeCode.other_individual,
        CountingTypeCode.other_massive,
      ].includes(code as CountingTypeCode)
    ) {
      return CountingTypeCode.other;
    }
    return code as CountingTypeCode;
  };

  const handleComplete = async () => {
    setErrorOnFinish(false);
    const countingTypeCode = getCountingType(selectedCountingType?.code ?? "");
    const kitchenIds: string[] = [];
    locations.forEach((location) => {
      location.kitchens.forEach((kitchen) => {
        if (!kitchenIds.includes(kitchen.kitchenId)) {
          kitchenIds.push(kitchen.kitchenId);
        }
      });
    });
    const supplies = selectedSupplies.map((el) => el.sku);
    const massiveDataToSent: MassiveDataToSent[] = [];
    Object.keys(massiveData).forEach((massiveKey) => {
      massiveDataToSent.push({
        kitchenId: massiveKey,
        skus: massiveData[massiveKey],
      });
    });
    const response = await dispatch(
      createSchedulePhysicalCounting({
        countingTypeCode,
        name,
        kitchenIds,
        dates,
        supplies,
        massiveDataToSent,
      })
    );
    const status = response.payload as boolean;
    setErrorOnFinish(!status);
    setOpen(status);
  };

  const _handleReset = () => {
    setLocations([]);
    setDates([]);
    setSelectedSupplies([]);
    setErrorOnFinish(false);
    setMassiveData({});
  };

  const handleReset = () => {
    setSelectedCountingType(null);
    _handleReset();
  };

  const isReCountOrDaily = getIsRecountOrMassive(selectedCountingType);
  const showArchive = user?.role !== TypeOfRole.OPS;

  React.useEffect(() => {
    _handleReset();
  }, [isReCountOrDaily]);

  return (
    <PageBox>
      <PageHeader
        title={t(physicalCountSchedule.HEADER_PAGE_TITLE)}
        subtitle={t(physicalCountSchedule.HEADER_PAGE_SUBTITLE)}
        rightArea={[
          {
            children: t(commons.ARCHIVE),
            startIcon: <DeleteOutlineOutlinedIcon />,
            variant: "outlined",
            color: "error",
            onClick: () => setOpenArchiveDialog(true),
            show: showArchive,
          },
        ]}
      />

      {openArchiveDialog && showArchive && (
        <ArchiveCountsDialog onClose={() => setOpenArchiveDialog(false)} />
      )}

      <Box sx={{ mt: 2, p: 2, backgroundColor: "white" }}>
        <PhysicalCountScheduleStepper
          selectedCountingType={selectedCountingType}
          setSelectedCountingType={setSelectedCountingType}
          dates={dates}
          setDates={setDates}
          locations={locations}
          setLocations={setLocations}
          selectedSupplies={selectedSupplies}
          setSelectedSupplies={setSelectedSupplies}
          massiveData={massiveData}
          setMassiveData={setMassiveData}
          handleComplete={handleComplete}
          handleReset={handleReset}
          errorOnFinish={errorOnFinish}
          name={name}
          setName={setName}
        />
      </Box>

      <CommonDialog
        open={open}
        handleClose={() => setOpen(false)}
        handleConfirm={() => setOpen(false)}
        title={t(
          errorOnFinish
            ? physicalCountSchedule.ERROR_TITLE
            : physicalCountSchedule.SUCCESS_TITLE
        )}
        message={t(
          errorOnFinish
            ? physicalCountSchedule.ERROR_SUBTITLE
            : physicalCountSchedule.SUCCESS_SUBTITLE
        )}
        icon={
          errorOnFinish ? (
            <ErrorOutlineOutlinedIcon
              sx={{
                ...sxModalIcon,
                color: "orange",
              }}
            />
          ) : (
            <CheckCircleOutlinedIcon
              sx={{
                ...sxModalIcon,
                color: "green",
              }}
            />
          )
        }
        showCancelButton={false}
      />
    </PageBox>
  );
};

export default memo(PhysicalCountSchedule);
