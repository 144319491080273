import InfoOutlined from "@mui/icons-material/InfoOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import SummaryAdjustmentsTable from "app/components/Adjustments/SummaryAdjustmentsTable";
import PageBox from "app/components/PageBox";
import PageHeader from "app/components/PageHeader/PageHeader";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import {
  adjustmentsModule,
  commons,
  kitchenInventoryManagement,
} from "app/i18n/types";
import { sendAdjustments } from "app/store/slices/adjustments/thunks";
import { AdjustmentPayload } from "core/adjustments/entities/Adjusment";
import { FunctionComponent, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import xlsxFile from "read-excel-file";

interface AdjustmentsProps {}

const Adjustments: FunctionComponent<AdjustmentsProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const statusLoad = useAppSelector((state) => state.adjustments.statusLoad);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleMakeAdjustment = async (
    fileName: string,
    adjustments: Array<AdjustmentPayload>
  ) => {
    if (selectedFile !== null) {
      await dispatch(sendAdjustments({ fileName, adjustments }));
      setSelectedFile(null);
    }
  };

  const { acceptedFiles, getRootProps } = useDropzone({
    maxFiles: 1,
    accept: {
      "file/xls": [".xls", ".xlsx"],
    },
  });

  useEffect(() => {
    if (acceptedFiles !== null) {
      acceptedFiles.forEach((file) => {
        setSelectedFile(file);
      });
    }
  }, [acceptedFiles]);

  return (
    <PageBox>
      <PageHeader
        title={t(kitchenInventoryManagement.MASSIVEADJUSTS_MODULE)}
        subtitle={t(
          kitchenInventoryManagement.MASSIVEADJUSTS_MODULE_DESCRIPTION
        )}
        rightArea={[
          {
            children: t(commons.MAKE_ADJUSTMENT),
            onClick: () => {
              if (selectedFile !== null) {
                xlsxFile(selectedFile).then((rows) => {
                  const headers = rows[0];
                  let list: Array<AdjustmentPayload> = [];
                  rows.forEach((row, index) => {
                    if (index !== 0) {
                      let element: any = {};
                      row.forEach((column, i) => {
                        if (headers[i]) {
                          element[headers[i].toString()] = column;
                        }
                      });
                      list.push(element);
                    }
                  });
                  handleMakeAdjustment(selectedFile.name, list);
                });
              }
            },
            variant: "contained",
            size: "large",
            disabled: selectedFile === null || statusLoad === "loading",
            startIcon:
              statusLoad === "loading" ? (
                <CircularProgress sx={{ paddingRight: "5px" }} size={20} />
              ) : (
                <TrendingUpOutlinedIcon
                  sx={{ height: "18", paddingRight: "5px" }}
                />
              ),
          },
        ]}
      />

      <Box
        flexGrow={1}
        display="flex"
        gap={2}
        flexDirection={{
          xs: "column",
          md: "column",
        }}
        flexBasis="100%"
        height={{ md: "calc(100% - 110px)" }}
      >
        <Box
          sx={{
            height: 88,
            width: "100%",
            backgroundColor: "#FFFFFF",
            border: "1px solid #D7D3D3",
            borderRadius: "4px",
            padding: "16px 32px 16px 32px",
          }}
        >
          <Typography variant="caption">
            {t(adjustmentsModule.INSTRUCTIONS)}
          </Typography>
          <Typography variant="body1">
            {t(adjustmentsModule.INSTRUCTIONS_DETAILS)}
          </Typography>
        </Box>

        <Box width="100%" px={2}>
          <Box
            flexGrow={1}
            py={2}
            display="flex"
            gap={2}
            px={2}
            flexDirection={{
              xs: "column",
              md: "row",
            }}
          >
            <Box
              width="50%"
              sx={{ textAlign: "-webkit-center", alignItems: "center" }}
            >
              <Box
                sx={{
                  width: 445,
                  height: 244,
                  backgroundColor: "#F1EFEF",
                  paddingTop: "32px",
                  textAlign: "center",
                  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23011A5BFF' stroke-width='4' stroke-dasharray='31%2c 31' stroke-dashoffset='10' stroke-linecap='square'/%3e%3c/svg%3e")`,
                  borderRadius: "4px",
                }}
              >
                <Box sx={{ paddingTop: 2 }}>
                  <InfoOutlined />
                  <br />
                  <section className="container">
                    <div {...getRootProps({ className: "dropzone" })}>
                      <Box sx={{ paddingTop: 2 }}>
                        <Typography variant="caption" sx={{ fontSize: 16 }}>
                          {t(adjustmentsModule.BOX_DETAILS)}
                        </Typography>
                      </Box>
                      <Box sx={{ paddingTop: 4 }}>
                        <Button variant="contained" sx={{ height: "54px" }}>
                          <UploadFileOutlinedIcon
                            sx={{ height: "18", paddingRight: "5px" }}
                          />
                          {t(commons.SELECT_FILE)}
                        </Button>
                      </Box>
                    </div>
                    <Box sx={{ paddingTop: 1 }}>
                      <Typography variant="caption">
                        {selectedFile?.name}
                      </Typography>
                    </Box>
                  </section>
                </Box>
              </Box>
            </Box>
            <Box
              width="50%"
              order={{
                xs: 2,
                md: 3,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  textAlign: "center",
                  paddingTop: "5px",
                }}
              >
                <SummaryAdjustmentsTable />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </PageBox>
  );
};
export default Adjustments;
