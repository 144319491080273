import initI18n from "app/i18n";
import { initAnalytics } from "core/common/utils/analytics";
import { firebaseApp } from "core/common/utils/firebase-config";
import messaging from "core/common/utils/messaging";
import { onStartFetchRemoteConfig } from "core/common/utils/remoteConfig";
import React from "react";
import ReactDOM from "react-dom/client";
import initWidget from "utils/widget";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

firebaseApp();
onStartFetchRemoteConfig();
messaging.initMessaging();
initWidget();
if (process.env.NODE_ENV === "production") {
  /* initSentry(); */
}

initI18n();
initAnalytics();

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
