import { KOSEmptyState } from "@foodology-co/alejandria";
import DownloadIcon from "@mui/icons-material/Download";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Autocomplete,
  Button,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { unwrapResult } from "@reduxjs/toolkit";
import KitchenSelector from "app/components/Kitchen/Selector";
import PageBox from "app/components/PageBox";
import PageHeader from "app/components/PageHeader";
import CountingTypeStep from "app/components/PhysicalCount/Schedule/Step/CountingType";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { commons, kitchenInventoryManagement } from "app/i18n/types";
import {
  cleanSelected,
  selectAll,
  selectCounting,
} from "app/store/slices/consolidatedReport";
import {
  getCountingDetailsByIds,
  getFinishedCountingsBetweenDates,
} from "app/store/slices/consolidatedReport/thunks";
import { convertDate, convertDateUtc } from "app/utils/dateUtils";
import { downloadTextAsCsv, json2Csv } from "core/common/utils/fileUtils";
import {
  CountingStatusFilter,
  CountingType,
  RawCounting,
} from "core/physicalCount/entities/Counting";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface ConsolitadedExportProps {}

const ConsolitadedExport: FunctionComponent<ConsolitadedExportProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectedCountingType, setSelectedCountingType] =
    useState<CountingType | null>(null);

  const countings = useAppSelector(
    (state) => state.consolidatedExport.countings
  );
  const searchStatus = useAppSelector(
    (state) => state.consolidatedExport.searchCountingStatus
  );

  const selectedCountings = useAppSelector(
    (state) => state.consolidatedExport.selectedCountings
  );
  const [filteredCountings, setFilteredCountings] = useState<RawCounting[]>([]);

  const kitchens = useAppSelector((state) => state.global.kitchens.data);
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);

  const [initDate, setInitDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const handleInitDate = (newValue: Date | null) => {
    setInitDate(newValue);
  };

  const handleEndDate = (newValue: Date | null) => {
    setEndDate(newValue);
  };

  const searchCountings = async () => {
    if (initDate && endDate) {
      dispatch(cleanSelected({}));
      dispatch(getFinishedCountingsBetweenDates({ initDate, endDate }));
    }
  };

  const exportCountings = async () => {
    if (!selectedCountings.length) return;
    const ids = selectedCountings.map((counting: RawCounting) =>
      Number(counting.id)
    );
    const response = await dispatch(getCountingDetailsByIds({ ids }));
    const exportD = unwrapResult(response);
    downloadTextAsCsv(json2Csv(exportD.details), "conteo.csv");
  };

  const countryFilter = useCallback(
    (counting: RawCounting) => {
      if (selectedCountry && kitchens.length) {
        const selectedCountryKitchens = kitchens.filter(
          (kitchen) => kitchen.country === selectedCountry
        );

        return selectedCountryKitchens.some(
          (kitchen) => kitchen.kitchenId === counting.kitchenId
        );
      }

      return true;
    },
    [selectedCountry, kitchens]
  );

  const cityFilter = useCallback(
    (counting: RawCounting) => {
      if (selectedCity && selectedCity !== "ALL") {
        const selectedCityKitchens = kitchens.filter(
          (kitchen) => kitchen.city === selectedCity
        );

        return selectedCityKitchens.some(
          (kitchen) => kitchen.kitchenId === counting.kitchenId
        );
      }
      return true;
    },
    [selectedCity, kitchens]
  );

  const locationFilter = useCallback(
    (counting: RawCounting) => {
      if (selectedLocation && selectedLocation !== "ALL") {
        return counting.kitchenId === selectedLocation;
      }

      return true;
    },
    [selectedLocation]
  );

  const typeFilter = useCallback(
    (counting: RawCounting) => {
      if (selectedCountingType !== null) {
        return counting.countingTypeCode === selectedCountingType.code;
      }
      return true;
    },
    [selectedCountingType]
  );

  const statusFilter = useCallback(
    (counting: RawCounting) => {
      if (selectedStatus !== null) {
        return counting.status === selectedStatus;
      }
      return true;
    },
    [selectedStatus]
  );

  useEffect(() => {
    if (countings)
      setFilteredCountings(
        countings
          .filter(countryFilter)
          .filter(cityFilter)
          .filter(locationFilter)
          .filter(typeFilter)
          .filter(statusFilter)
      );
  }, [
    countings,
    typeFilter,
    countryFilter,
    cityFilter,
    locationFilter,
    statusFilter,
  ]);

  return (
    <PageBox>
      <PageHeader
        title={t(kitchenInventoryManagement.COUNTDOWNLOAD_MODULE)}
        subtitle={t(
          kitchenInventoryManagement.COUNTDOWNLOAD_MODULE_DESCRIPTION
        )}
        rightArea={[
          {
            children: "Exportar",
            onClick: exportCountings,
            variant: "contained",
            size: "large",
            disabled: !selectedCountings.length,
            startIcon: <DownloadIcon />,
          },
        ]}
      />

      <Grid container spacing={2} pb={3} alignItems="center">
        <Grid item sm={4} xs={12}>
          <KitchenSelector
            selected={{
              country: selectedCountry ?? undefined,
              city: selectedCity ?? undefined,
              kitchen: selectedLocation ?? undefined,
            }}
            onChange={{
              country: (value) => setSelectedCountry(value?.code ?? ""),
              city: (value) => setSelectedCity(value?.code ?? ""),
              kitchen: (value) => setSelectedLocation(value?.kitchenId ?? ""),
            }}
            extra={{ showHash: true, allCityOrKitchens: true }}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <Box display="flex" gap={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label={t(commons.INIT_DATE)}
                inputFormat="dd/MM/yyyy"
                value={initDate}
                onChange={handleInitDate}
                renderInput={(params: any) => (
                  <TextField fullWidth={true} {...params} />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label={t(commons.FINAL_DATE)}
                inputFormat="dd/MM/yyyy"
                value={endDate}
                onChange={handleEndDate}
                renderInput={(params: any) => (
                  <TextField fullWidth={true} {...params} />
                )}
              />
            </LocalizationProvider>
          </Box>
        </Grid>
        <Grid item sm={2} xs={12}>
          <CountingTypeStep
            selectedCountingType={selectedCountingType}
            setSelectedCountingType={setSelectedCountingType}
          />
        </Grid>
        <Grid item sm={2} xs={12}>
          <Autocomplete
            options={Object.values(CountingStatusFilter)}
            value={selectedStatus}
            onChange={(_, newValue) => {
              setSelectedStatus(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label={t(commons.STATUS)} />
            )}
            getOptionLabel={(option) =>
              t(`module.physicalCount.${option}`).toUpperCase()
            }
          />
        </Grid>
        <Grid item sm={1} xs={12}>
          <Button
            variant="contained"
            onClick={searchCountings}
            fullWidth
            disabled={
              initDate === null || endDate === null || selectedCountry === null
            }
            autoFocus
          >
            {t(commons.SEARCH)}
          </Button>
        </Grid>
      </Grid>

      <Box>
        {filteredCountings !== null && filteredCountings.length === 0 && (
          <Box
            sx={{
              width: "100%",
              mx: "auto",
              my: 5,
              textAlign: "-webkit-center",
            }}
          >
            <KOSEmptyState
              icon={InfoOutlinedIcon}
              instruction={t(commons.EMPTY_RESULT)}
            />
          </Box>
        )}

        {searchStatus !== "loading" &&
          filteredCountings !== null &&
          filteredCountings.length > 0 && (
            <Box flexGrow={1} overflow="scroll" position="relative">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="counting-history">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Cocina</TableCell>
                      <TableCell align="left">{t(commons.TYPE)}</TableCell>
                      <TableCell align="left">{t(commons.STATUS)}</TableCell>
                      <TableCell align="center">
                        {t(commons.SCHEDULE_DATE)}
                      </TableCell>
                      <TableCell align="center">
                        {t(commons.INIT_DATE)}
                      </TableCell>
                      <TableCell align="center">
                        {t(commons.FINAL_DATE)}
                      </TableCell>
                      <TableCell align="center">
                        <Checkbox
                          onChange={() =>
                            dispatch(selectAll(filteredCountings))
                          }
                          checked={
                            selectedCountings.length ===
                            filteredCountings.filter(
                              (counting) => counting.status !== "IN_PROCESS"
                            ).length
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredCountings.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell align="center">
                          <Box display="flex" alignItems="center" gap={2}>
                            <Typography>{row.kitchenId}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box display="flex" alignItems="center" gap={2}>
                            <Typography>{t(row.countingTypeCode)}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box display="flex" alignItems="center" gap={2}>
                            <Typography>{t(`status.${row.status}`)}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {row.scheduledDate}
                        </TableCell>
                        <TableCell align="center">
                          {convertDateUtc(row.createdAt, "MM/dd/yyyy hh:mm a")}
                        </TableCell>
                        <TableCell align="center">
                          {row.endAt &&
                            convertDate(row.endAt, "MM/dd/yyyy hh:mm a")}
                        </TableCell>

                        <TableCell align="center">
                          {row.status === "FINISHED" && (
                            <Checkbox
                              onChange={(e) => dispatch(selectCounting(row))}
                              checked={
                                selectedCountings.findIndex(
                                  (counting) => counting.id === row.id
                                ) !== -1
                              }
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
      </Box>
    </PageBox>
  );
};

export default ConsolitadedExport;
