import {
  CookieItem as CookieItemAlejandria,
  getCookieConfig,
} from "@foodology-co/alejandria";
import appConfig from "config/app";
import Cookies from "js-cookie";

export enum CookieItemExtra {}

export type CookieItem = `${CookieItemAlejandria | CookieItemExtra}`;

export const setCookie = (item: CookieItem, value: string): void => {
  const cookieConfig = getCookieConfig(appConfig.env);
  Cookies.set(`${cookieConfig.env}.${item}`, value, cookieConfig.options);
};

export const getCookie = (item: CookieItem): string | undefined => {
  const cookieConfig = getCookieConfig(appConfig.env);
  return Cookies.get(`${cookieConfig.env}.${item}`);
};

export const removeCookie = (item: CookieItem): void => {
  const cookieConfig = getCookieConfig(appConfig.env);
  Cookies.remove(`${cookieConfig.env}.${item}`, cookieConfig.options);
};
