import { KOSMenu } from "@foodology-co/alejandria";
import { Alert, AlertTitle, Snackbar } from "@mui/material";
import Box from "@mui/material/Box";
import TopBar from "app/components/TopBar";
import { useAllCities } from "app/hooks/useAllCities";
import { useAllCountries } from "app/hooks/useAllCountries";
import { useAllKitchens } from "app/hooks/useAllKitchens";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import { hideAlert } from "app/store/slices/global";
import { getUserMenu } from "app/store/slices/menu/thunks";
import appConfig from "config/app";
import { rolesWithAllAccess } from "config/route";
import { FunctionComponent, useEffect, useMemo } from "react";
import { Outlet } from "react-router-dom";
import { useMount } from "react-use";
import { getCookie } from "utils/cookie";
import { slugsForKitchenSelector } from "utils/general";
import { TypeOfRole as Role } from "utils/role";
import { refineLink } from "utils/route";
import textToSpeech from "utils/textToSpeech";
import KitchenSelectorDialog from "../Kitchen/Dialog";

interface AppLayoutProps {}

const AppLayout: FunctionComponent<AppLayoutProps> = () => {
  const navigator = useNavigator();
  const dispatch = useAppDispatch();

  const { kitchenId } = navigator.params();

  const menuOpen = useAppSelector((state) => state.menu.open);
  const menu = useAppSelector((state) => state.menu.items);
  const user = useAppSelector((state) => state.session.user.data);

  const allKitchens = useAllKitchens();
  const allCities = useAllCities();
  const allCountries = useAllCountries();

  const alert = useAppSelector((state) => state.global.alert);

  const hideAppAlert = () => dispatch(hideAlert());

  useEffect(() => {
    const { message, audioOn } = alert;
    if (message && audioOn) {
      textToSpeech(message, 600);
    }
  }, [alert]);

  const handleCloseAlert = () => {
    if (hideAppAlert) {
      hideAppAlert();
    }
  };

  const onClickMenuItem = (to: string | undefined) => {
    const kitchenId = getCookie("kitchenId");
    const params =
      kitchenId && !rolesWithAllAccess.includes(user?.role as Role)
        ? { kitchenId }
        : undefined;
    const path = to ? refineLink(to, params) : "";
    if (path.includes("http")) {
      window.location.href = path;
    } else if (path) {
      navigator.to(path);
    }
  };

  const openKitchenSelectorDialog: boolean = useMemo(() => {
    return slugsForKitchenSelector.includes(kitchenId ?? "");
  }, [kitchenId]);

  useMount(() => {
    dispatch(getUserMenu());
    allCountries.get();
    allCities.get();
    allKitchens.get();
  });

  return (
    <Box display="flex" pt={7}>
      <Snackbar
        open={alert.show}
        onClose={handleCloseAlert}
        autoHideDuration={alert.duration}
        anchorOrigin={alert.position}
      >
        <Alert
          variant="filled"
          severity={alert.severity}
          sx={{ minWidth: 360 }}
          onClose={handleCloseAlert}
          icon={alert.icon}
        >
          <AlertTitle>{alert.title}</AlertTitle>
          {alert.message}
          <Box>{alert.action}</Box>
        </Alert>
      </Snackbar>

      <TopBar />

      <KOSMenu
        user={user ? { ...user, rolName: user.roleName } : undefined}
        drawerOpen={menuOpen}
        items={menu}
        onClickMenuItem={onClickMenuItem}
        homeUrl={`${appConfig.kitchenDisplay.url}/home`}
        sx={{ zIndex: 14 }}
      />

      {!openKitchenSelectorDialog && (
        <Box flexGrow={1} minHeight="calc(100vh - 56px)" overflow="auto">
          <Outlet />
        </Box>
      )}
      {openKitchenSelectorDialog && <KitchenSelectorDialog />}
    </Box>
  );
};

export default AppLayout;
